import React, { useState, useEffect } from "react";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Command from "./command";
import PaperWrap from "../components/paper-wrap";
import Footer from "../components/footer";
import Autocomplete from "@mui/material/Autocomplete";
import PageContainer from "../components/PageContainer";
import * as api from "../api/api";
import Modal from "react-modal";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import CloseIcon from "@mui/icons-material/Close";
import { FaTimes } from "react-icons/fa";
import "../device/dgstyles.css";
import Button from "@mui/material/Button";
import {
  Box,
  Grid,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem,
  Stack,
  Paper,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import * as constants from "../constants";
import { InputAdornment, IconButton } from "@mui/material";
import { Check, Visibility, VisibilityOff } from "@mui/icons-material";
import RefreshIcon from "@mui/icons-material/Refresh";
import PageLoading from "../components/pageloading";
import { blueGrey } from "@mui/material/colors";

const DR_GROUP_NAME = "drGroupName";
const DR_GROUP_LIST = "drGroupList";
const DR_UPDATED_GROUPS = "drUpdatedGroups";
const DR_APPLY_GROUPS = "drApplyGroups";
const DR_SELECT_ALL = "drSelectAll";

const SuccessModal = ({ isOpen, onClose, Status, ManualTuneMsg }) => {
  let h4Msg = "";
  let pMsg = "";
  switch (Status) {
    case "ok":
      h4Msg = "Success Message";
      pMsg = "Successfully submitted the configuration";
      break;
    case "demandok":
      h4Msg = "Success Message";
      pMsg = ManualTuneMsg;
      break;
    case "invalidrange":
      h4Msg = "Failure Message";
      pMsg = "Please fill up with valid range values";
      break;
    case "invalidDRconfig":
      h4Msg = "Failure Message";
      pMsg = "Please provide the demand response config name";
      break;
    case "invalidDRconfigdetails":
      h4Msg = "Failure Message";
      pMsg = "Please fill up with the valid values";
      break;
    case "invalidrampupdown":
      h4Msg = "Failure Message";
      pMsg =
        "Please make sure Ramp up/down time is between 0 and 900 seconds";
      break;
    case "unknown":
      h4Msg = "Failure Message";
      pMsg = "Failed to perform the operation";
      break;
    case "error":
      h4Msg = "Failure Message";
      if (
        ManualTuneMsg === "" ||
        ManualTuneMsg === undefined ||
        ManualTuneMsg === null
      ) {
        pMsg = "Response from the server is not as expected.";
      } else {
        pMsg = ManualTuneMsg;
      }
      break;
    case "minergroups":
      h4Msg = "Failure Message";
      pMsg = "Empty Group List";
      break;
    case "fetchfailed":
      h4Msg = "Fetch Failed!";
      pMsg =
        "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine";
      break;
    case "nouser":
      h4Msg = "Authentication Failed!";
      pMsg =
        "User is not part of the Organization. Please contact your Administrator";
      break;
    case "noauth":
      h4Msg = "Authorization Failed!";
      pMsg = "User doesn't have permission to view this page";
      break;
    default:
      h4Msg = "Unknown Error";
      pMsg =
        "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine";
      break;
  }
  if (Status !== "ok") {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose}>
        <h4 className="popup-title">{h4Msg}</h4>
        <div className="gap"></div>
        {typeof pMsg === "object" ? (
          <div className="success-message-container">
            {Object.keys(pMsg).map((group, index) => (
              <div key={index} className="group-message">
                <span className="group-name">{group}:</span>
                <span
                  className="group-content"
                  dangerouslySetInnerHTML={{
                    __html: pMsg[group].replace(
                      /([a-f\d]{8}(-[a-f\d]{4}){3}-[a-f\d]{12})/gi,
                      '<span class="job-id">$1</span>'
                    ),
                  }}
                ></span>
              </div>
            ))}
          </div>
        ) : (
          <p className="success-message">{pMsg}</p>
        )}
        <div className="button-container">
          <button onClick={onClose}>Close</button>
        </div>
      </Popup>
    );
  }
};

let validUser = -1;

export const DemandConfig = () => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [submitMsg, setSubmitMsg] = useState("");
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const [manualTuneMsg, setmanualTuneMsg] = useState("");
  const [minerGroups, setMinerGroups] = useState([]);
  const [updatedGroups, setUpdatedGroups] = useState([]);
  const [applyGroups, setApplyGroups] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const [demandConfigName, setDemandConfigName] = useState("");
  const [demandConfigNames, setDemandConfigNames] = useState([]);
  const [qseConfig, setQseConfig] = useState("");
  const qseConfigOptions = ["Voltus", "CPower"];
  const [inputVoltusOptIn, setinputVoltusOptIn] = useState(true);
  // const [inputVoltusCLR, setinputVoltusCLR] = useState(false);
  const [inputSiteID, setinputSiteID] = useState("");
  const [inputUsername, setinputUsername] = useState("");
  const [inputPassword, setinputPassword] = useState("");
  const [isApplyDRLoading, setApplyDRLoading] = useState(false);
  const [isDetachDRLoading, setDetachDRLoading] = useState(false);
  const [changeRampUpDownValueError, setchangeRampUpDownTimeValueError] =
    React.useState({
      error: false,
      helperText: "",
    });
  const [changeRampUpDownTime, setChangeRampUpDownTime] = useState("");

  const handleChangeRampUpDownTimeValue = (event) => {
    const value = event.target.value;
    const intRegex = /^\d+$/;
    if (value === "" || intRegex.test(value)) {
      setChangeRampUpDownTime(value);
      setchangeRampUpDownTimeValueError({ error: false, helperText: "" });
    } else {
      setchangeRampUpDownTimeValueError({ error: true, helperText: "" });
    }
  };

  const handleDemandConfigName = async (value) => {
    setDemandConfigName(value);
    if (value !== "" && value !== undefined && value !== null) {
      await handleDemandConfigGet(value);
    }
  };

  const handleQseConfig = (value) => {
    setQseConfig(value);
  };

  const handleUsernameChange = (event) => {
    const target = event.target;
    setinputUsername(target.value);
  };

  const handlePasswordChange = (event) => {
    const target = event.target;
    setinputPassword(target.value);
  };

  const handleinputVoltusOptIn = () => {
    setinputVoltusOptIn(!inputVoltusOptIn);
  };

  // Commented out CLR option for now as per Marshall
  // const handleinputVoltusCLR = () => {
  //   setinputVoltusCLR(!inputVoltusCLR);
  // };

  const handleSelectAll = (event) => {
    const allOptions = minerGroups;
    const selectedOptions = event.target.checked ? allOptions : [];
    console.log("handleSelectAll", selectedOptions);
    setApplyGroups(selectedOptions);
    localStorage.setItem(DR_APPLY_GROUPS, JSON.stringify(selectedOptions));
    setSelectAll(event.target.checked);
    localStorage.setItem(DR_SELECT_ALL, event.target.checked.toString());
  };

  const applyVal = (value) => {
    console.log("applyVal", value, value.length);
    setApplyGroups(value);
    localStorage.setItem(DR_APPLY_GROUPS, JSON.stringify(value));
    setSelectAll(value.length === updatedGroups.length);
    localStorage.setItem(
      DR_SELECT_ALL,
      (Array.isArray(value)
        ? value.length === updatedGroups.length
        : false
      ).toString()
    );
  };

  const { user: user0, getAccessTokenSilently } = useAuth0();

  const [showPopup, setShowPopup] = useState(false);
  const [showPopup2, setShowPopup2] = useState(false);

  const handleGetDemandConfigNames = async () => {
    console.log("handleGetDemandConfigNames");
    const token = await getAccessTokenSilently();
    const demandConfigInfo = {
      config_type: "demandResponseConfig",
    };
    try {
      const demandConfigNames = await api.PostAutoTuneV2Config(
        token,
        demandConfigInfo,
        "GETALL"
      );
      if (demandConfigNames.status === "error") {
        console.error(
          "Failed to get the price config names:",
          demandConfigNames.msg
        );
        setDemandConfigNames([]);
      } else {
        const dcNames = demandConfigNames.result.reduce((DCList, item) => {
          Object.keys(item.demandResponseConfig).forEach((name) => {
            DCList.push(name);
          });
          return DCList;
        }, []);
        console.log("demandConfigNames Handler ", dcNames);
        setDemandConfigNames(dcNames);
      }
    } catch (error) {
      console.error("Error while fetching price config names:", error);
      setDemandConfigNames([]);
    }
  };

  const applyDemandConfigHandler = async (dglist, b_attach) => {
    if (b_attach) {
      setApplyDRLoading(true);
    } else {
      setDetachDRLoading(true);
    }
    const token = await getAccessTokenSilently();
    if (dglist.length === 0) {
      setSubmitMsg("minergroups");
      setSuccessModalOpen(true);
      if (b_attach) {
        setApplyDRLoading(false);
      } else {
        setDetachDRLoading(false);
      }
      return;
    }
    const demandInfo = {
      config_type: "demandResponseConfig",
      config_name: demandConfigName,
      attach: b_attach,
    };
    const postConfig = await api.PostAttachAutoTuneV2Config(
      token,
      demandInfo,
      dglist
    );
    if (postConfig === undefined || !postConfig.hasOwnProperty("status")) {
      setSubmitMsg("unknown");
      setSuccessModalOpen(true);
    } else if (postConfig.status === "ok") {
      setSubmitMsg("demandok");
      if (b_attach) {
        setmanualTuneMsg("Successfully attached the configuration");
      } else {
        setmanualTuneMsg("Successfully detached the configuration");
      }
      setSuccessModalOpen(true);
    } else {
      setSubmitMsg("error");
      setmanualTuneMsg(postConfig.msg);
      setSuccessModalOpen(true);
    }
    if (b_attach) {
      setApplyDRLoading(false);
    } else {
      setDetachDRLoading(false);
    }
    setShowPopup(false);
  };

  const handleDemandConfigGet = async (demandConfigName) => {
    const token = await getAccessTokenSilently();
    console.log("handleDemandConfigGet", demandConfigName);
    if (demandConfigName === "") {
      setSubmitMsg("invalidDRconfig");
      setSuccessModalOpen(true);
      return;
    }
    setDRLoading1(true);
    const demandInfo = {
      config_type: "demandResponseConfig",
      config_name: demandConfigName,
    };
    setChangeRampUpDownTime("");
    const getConfig = await api.PostAutoTuneV2Config(token, demandInfo, "GET");
    if (getConfig === undefined || !getConfig.hasOwnProperty("status")) {
      setSubmitMsg("unknown");
      setSuccessModalOpen(true);
    } else if (getConfig.status === "ok") {
      const cfg = getConfig.result.demandResponseConfig[demandConfigName];
      setSubmitMsg("demandok");
      setmanualTuneMsg("Successfully fetched the configuration");
      setSuccessModalOpen(true);
      setQseConfig(cfg.QSE);
      if (cfg.QSE === "Voltus") {
        if (cfg.VoltusOptIn === undefined) {
          setinputVoltusOptIn(true);
        } else {
          setinputVoltusOptIn(cfg.VoltusOptIn);
        }
        // Commented out CLR option for now as per Marshall
        // if (cfg.VoltusCLR === undefined) {
        //   setinputVoltusCLR(true);
        // } else {
        //   setinputVoltusCLR(cfg.VoltusCLR);
        // }
        if (cfg.site_id === undefined) {
          setinputSiteID("");
        } else {
          setinputSiteID(cfg.site_id);
        }
      } else if (cfg.QSE === "CPower") {
        setinputUsername(cfg.Username);
        setinputPassword(cfg.Password);
      }
      if (cfg.hasOwnProperty("rampupdown_time")) {
        setChangeRampUpDownTime(cfg.rampupdown_time);
      }
    } else {
      setChangeRampUpDownTime("");
      setQseConfig("");
      setinputSiteID("");
      setinputVoltusOptIn(true);
      // setinputVoltusCLR(true);
      setinputUsername("");
      setinputPassword("");
      setSubmitMsg("error");
      setmanualTuneMsg(getConfig.msg);
      setSuccessModalOpen(true);
    }
    setDRLoading1(false);
  };

  const handleDemandConfigConfirmation = async () => {
    const token = await getAccessTokenSilently();
    setDRLoading(true);
    setApplyDRLoading(true);
    if (
      demandConfigName === "" ||
      qseConfig === "" ||
      (qseConfig === "CPower" && (inputUsername === "" || inputPassword === ""))
    ) {
      setSubmitMsg("invalidDRconfigdetails");
      setSuccessModalOpen(true);
      setShowPopup(false);
      setDRLoading(false);
      setApplyDRLoading(false);
      return;
    }
    let rampup_time = 600;
    if (changeRampUpDownTime !== "") {
      rampup_time = parseInt(changeRampUpDownTime);
      if (rampup_time < 0 || rampup_time > 900) {
        setSubmitMsg("invalidrampupdown");
        setSuccessModalOpen(true);
        setShowPopup(false);
        setDRLoading(false);
        setApplyDRLoading(false);
        return;
      }
    } else {
      setSubmitMsg("invalidrampupdown");
      setSuccessModalOpen(true);
      setShowPopup(false);
      setDRLoading(false);
      setApplyDRLoading(false);
      return;
    }
    let config = {};
    if (qseConfig === "Voltus") {
      config = {
        QSE: qseConfig,
        VoltusOptIn: inputVoltusOptIn,
        // VoltusCLR: inputVoltusCLR,
        rampupdown_time: rampup_time,
      };
    } else if (qseConfig === "CPower") {
      config = {
        QSE: qseConfig,
        Username: inputUsername,
        Password: inputPassword,
        rampupdown_time: rampup_time,
      };
    }
    const demandInfo = {
      config_type: "demandResponseConfig",
      config_name: demandConfigName,
      config: config,
    };
    const postConfig = await api.PostAutoTuneV2Config(
      token,
      demandInfo,
      "POST"
    );
    if (postConfig === undefined || !postConfig.hasOwnProperty("status")) {
      setSubmitMsg("unknown");
      setSuccessModalOpen(true);
    } else if (postConfig.status === "ok") {
      let dglist = [];
      dglist.push(demandConfigName);
      await applyDemandConfigHandler(dglist, true);
      setApplyDRLoading(false);
      setSubmitMsg("demandok");
      setmanualTuneMsg("Successfully created the configuration");
      setSuccessModalOpen(true);
    } else {
      setSubmitMsg("error");
      setmanualTuneMsg(postConfig.msg);
      setSuccessModalOpen(true);
    }
    setDRLoading(false);
    setApplyDRLoading(false);
    setShowPopup(false);
  };

  const handleDemandConfigDeletion = async () => {
    const token = await getAccessTokenSilently();
    setDeleteDRLoading(true);
    setDetachDRLoading(true);
    console.log("handleDemandConfigDeletion", demandConfigName);

    if (demandConfigName === "") {
      setSubmitMsg("invalidDRconfig");
      setSuccessModalOpen(true);
      setShowPopup2(false);
      setDeleteDRLoading(false);
      setDetachDRLoading(false);
      return;
    }

    const demandInfo = {
      config_type: "demandResponseConfig",
      config_name: demandConfigName,
    };
    const postConfig = await api.PostAutoTuneV2Config(
      token,
      demandInfo,
      "DELETE"
    );
    if (postConfig === undefined || !postConfig.hasOwnProperty("status")) {
      setSubmitMsg("unknown");
      setSuccessModalOpen(true);
    } else if (postConfig.status === "ok") {
      let dglist = [];
      dglist.push(demandConfigName);
      await applyDemandConfigHandler(dglist, false);
      setDetachDRLoading(false);
      setSubmitMsg("demandok");
      setmanualTuneMsg("Successfully deleted the configuration");
      setSuccessModalOpen(true);
      setDemandConfigName("");
      setQseConfig("");
      setinputVoltusOptIn(true);
      // setinputVoltusCLR(true);
      setinputUsername("");
      setinputPassword("");
      demandConfigNames.splice(demandConfigNames.indexOf(demandConfigName), 1);
      setDemandConfigNames(demandConfigNames);
    } else {
      setSubmitMsg("error");
      if (
        postConfig.msg === "" ||
        postConfig.msg === undefined ||
        postConfig.msg === null
      ) {
        setmanualTuneMsg("Failed to delete the configuration");
      } else {
        setmanualTuneMsg(postConfig.msg);
      }
      setSuccessModalOpen(true);
    }
    setDeleteDRLoading(false);
    setDetachDRLoading(false);
    setShowPopup2(false);
  };

  const handleCancelConfirmation = () => {
    setShowPopup(false);
  };

  const handleCancelConfirmation2 = () => {
    setShowPopup2(false);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  const [isDRLoading, setDRLoading] = useState(false);
  const [isDRLoading1, setDRLoading1] = useState(false);
  const [isDeleteDRLoading, setDeleteDRLoading] = useState(false);

  const [showPassword, setshowPassword] = useState(false);

  const handleshowPassword = () => {
    setshowPassword(!showPassword);
  };

  const clearHandler = async () => {
    setDemandConfigName("");
    setQseConfig("");
    setinputVoltusOptIn(false);
    // setinputVoltusCLR(false);
    setinputUsername("");
    setinputPassword("");
    setSubmitMsg("");
    setSuccessModalOpen(false);
    setChangeRampUpDownTime("");
  };

  useEffect(() => {
    const runFn = async () => {
      const token = await getAccessTokenSilently();
      const isAllowed = await api.IsOperationAllowed(token);
      if (isAllowed === undefined) {
        validUser = 1;
        setSubmitMsg("something");
        setSuccessModalOpen(true);
        return;
      } else if (isAllowed.status === "error") {
        if (isAllowed.errorCode === 1007) {
          validUser = 1;
          setSubmitMsg("nouser");
          setSuccessModalOpen(true);
        } else if (isAllowed.errorCode === 1009) {
          validUser = 1;
          setSubmitMsg("noauth");
          setSuccessModalOpen(true);
        } else {
          validUser = 1;
          setSubmitMsg("fetchfailed");
          setSuccessModalOpen(true);
        }
        console.log("Operation Allowed", isAllowed);
        return;
      } else {
        validUser = 0;
        setSubmitMsg("ok");
        setSuccessModalOpen(true);
      }
      const deviceGroups = await api.GetDGNames(token);
      if (deviceGroups.status === "error") {
        if (deviceGroups.errorCode === 1007) {
          validUser = 1;
          setSubmitMsg("nouser");
          setSuccessModalOpen(true);
        } else {
          validUser = 1;
          setSubmitMsg("fetchfailed");
          setSuccessModalOpen(true);
        }
      } else {
        const groupList = Object.keys(deviceGroups);
        var minerGroupList = [];
        for (let i = 0; i < groupList.length; i++) {
          minerGroupList.push(groupList[i]);
        }
        minerGroupList.sort();
        setMinerGroups(minerGroupList);
        localStorage.setItem(DR_GROUP_LIST, JSON.stringify(minerGroupList));
        validUser = 0;
      }
      const demandConfigInfo = {
        config_type: "demandResponseConfig",
      };
      const demandConfigNames = await api.PostAutoTuneV2Config(
        token,
        demandConfigInfo,
        "GETALL"
      );
      if (demandConfigNames.status === "error") {
        if (demandConfigNames.errorCode === 1007) {
          validUser = 1;
          setSubmitMsg("nouser");
          setSuccessModalOpen(true);
        } else {
          validUser = 1;
          setSubmitMsg("fetchfailed");
          setSuccessModalOpen(true);
        }
      } else {
        const dcNames = demandConfigNames.result.reduce((DCList, item) => {
          if (item.demandResponseConfig) {  // Check if demandResponseConfig is defined
            Object.keys(item.demandResponseConfig).forEach((name) => {
              DCList.push(name);
            });
          }
          return DCList;
        }, []);
        // console.log('demandConfigNames', dcNames)
        setDemandConfigNames(dcNames);
        validUser = 0;
      }
    };
    runFn();
  }, [getAccessTokenSilently]);

  const attachDemandConfig = async () => {
    console.log("attachDemandConfig", applyGroups);
    await applyDemandConfigHandler(applyGroups, true);
    setApplyDRLoading(false);
    setShowPopup(false);
    return;
  };

  const detachDemandConfig = async () => {
    console.log("detachDemandConfig", applyGroups);
    await applyDemandConfigHandler(applyGroups, false);
    setDetachDRLoading(false);
    setShowPopup(false);
    return;
  };

  if (validUser === 0) {
    return (
      <PageContainer
        title="Demand"
        description="this is the demand configuration page"
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            variant="h4"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center", color: "#007bff", mb: 4 }}
          >
            Demand Response Configuration
          </Typography>
          <br />
          <br />
          <br />
          <Paper
            elevation={2}
            sx={{ width: "60%", padding: "24px 24px", borderRadius: "1rem" }}
          >
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Stack direction="column">
                    <Typography variant="p" component="div" fontWeight={"bold"}>
                      Demand Response Config Names
                    </Typography>
                    <Typography
                      Typography
                      variant="p"
                      sx={{ fontSize: "12px", mt: 1 }}
                      component="div"
                    >
                      (Group Names)
                    </Typography>
                  </Stack>
                  <Box display={"flex"} flexDirection={"row"} gap={2}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => handleDemandConfigName(value)}
                      fullWidth
                      disableClearable
                      id="mode"
                      value={demandConfigName}
                      options={minerGroups}
                      getOptionLabel={(option) => option}
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            whiteSpace: "normal",
                            fontSize: "16px",
                          }}
                        >
                          {option}
                        </li>
                      )}
                      getOptionSelected={(option, value) => option === value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              fontSize: "14px",
                            },
                          }}
                          size="small"
                          placeholder="Select demand response config"
                          aria-label="Select mode"
                        />
                      )}
                    />
                  </Box>
                  <Box fullWidth sx={{ mt: 1 }}>
                    <p
                      style={{
                        fontStyle: "italic",
                        color: "gray",
                        fontSize: "14px",
                        lineHeight: 1.35,
                        letterSpacing: 0.3,
                      }}
                    >
                      <strong>Note: </strong>The configuration is stored under
                      the group name (site name). It is a requirement for
                      customers to designate the group with the site name
                      registered during enrollment in the demand response
                      program with Voltus and/or CPower.
                    </p>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Typography variant="p" component="div" fontWeight={"bold"}>
                    Qualified Scheduling Entities
                  </Typography>
                  <Box display={"flex"} flexDirection={"row"} gap={2}>
                    <Autocomplete
                      disablePortal
                      onChange={(event, value) => handleQseConfig(value)}
                      fullWidth
                      disableClearable
                      id="mode"
                      value={qseConfig}
                      options={qseConfigOptions}
                      renderOption={(props, option, { selected }) => (
                        <li
                          {...props}
                          style={{
                            whiteSpace: "normal",
                            fontSize: "16px",
                          }}
                        >
                          {option}
                        </li>
                      )}
                      getOptionSelected={(option, value) => option === value}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            style: {
                              fontSize: "14px",
                            },
                          }}
                          size="small"
                          placeholder="Select entities"
                          aria-label="Select mode"
                        />
                      )}
                    />
                  </Box>
                </Box>
              </Grid>
              {qseConfig === "Voltus" && (
                <>
                  <Grid item xs={12}>
                    <Box display={"flex"} flexDirection={"column"} gap={3}>
                      {(inputSiteID !== "" && inputSiteID !== null && inputSiteID !== undefined) && (
                      <Typography variant="body1" component="div" fontWeight="bold">
                        Voltus Dispatch Events for Site ID  <span style={{ fontWeight: 'normal' }}>{inputSiteID}</span>
                      </Typography>
                      )}
                      {(inputSiteID === ""  || inputSiteID === null || inputSiteID === undefined) && (
                      <Typography variant="body1" component="div" fontWeight="bold">
                        Voltus Dispatch Events
                      </Typography>
                      )}
                      <Box display={"flex"} flexDirection={"row"} gap={3}>
                        <Checkbox
                          sx={{ mt: -2.8, mr: 0.75 }}
                          checked={inputVoltusOptIn}
                          onChange={handleinputVoltusOptIn}
                        />
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "15px", mt: -0.5 }}
                        >
                          Opt in
                        </Typography>
                      </Box>
                      {/* Commented out CLR option for now as per Marshall */}
                      {/* <Box display={"flex"} flexDirection={"row"} gap={3}>
                        <Checkbox
                          sx={{ mt: -2.8, mr: 0.75 }}
                          checked={inputVoltusCLR}
                          onChange={handleinputVoltusCLR}
                        />
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "15px", mt: -0.5 }}
                        >
                          CLR (Controllable Load)
                        </Typography>
                      </Box> */}
                    </Box>
                  </Grid>
                </>
              )}
              {qseConfig === "CPower" && (
                <>
                  <Grid item xs={12}>
                    <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                      <Typography
                        variant={"p"}
                        component={"div"}
                        fontWeight={"bold"}
                      >
                        Username
                      </Typography>
                      <Box display={"flex"} flexDirection={"row"} gap={2}>
                        <TextField
                          type="text"
                          size="small"
                          fullWidth
                          value={inputUsername}
                          onChange={handleUsernameChange}
                          margin="dense"
                          variant="outlined"
                          inputProps={{
                            sx: {
                              color: "black",
                              fontSize: "15px",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                      <Typography
                        variant={"p"}
                        component={"div"}
                        fontWeight={"bold"}
                      >
                        Password
                      </Typography>

                      <Box display={"flex"} flexDirection={"row"} gap={2}>
                        <TextField
                          type={showPassword ? "text" : "password"}
                          value={inputPassword}
                          onChange={handlePasswordChange}
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          size="small"
                          inputProps={{
                            sx: {
                              color: "black",
                              fontSize: "15px",
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleshowPassword}
                                  edge="end"
                                >
                                  {showPassword ? (
                                    <VisibilityOff />
                                  ) : (
                                    <Visibility />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </>
              )}


              <Grid item xs={12}>
                <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                  <Typography
                    variant={"p"}
                    component={"div"}
                    fontWeight={"bold"}
                  >
                    Ramp Up/Down Time
                  </Typography>
                  <Box display={"flex"} flexDirection={"row"} gap={2}>
                    <TextField
                      type="text"
                      size="small"
                      fullWidth
                      placeholder="In seconds between 0 and 900"
                      value={changeRampUpDownTime}
                      onChange={(event) =>
                        handleChangeRampUpDownTimeValue(event)
                      }
                      margin="dense"
                      variant="outlined"
                      error={changeRampUpDownValueError.error}
                      helperText={changeRampUpDownValueError.helperText}
                      inputProps={{
                        sx: {
                          color: "black",
                          fontSize: "15px",
                        },
                      }}
                      sx={{
                        "& .MuiInputBase-input::placeholder": {
                          fontSize: "0.85rem",
                          fontStyle: "italic",
                          color: "charcoal",
                        },
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  gap={1}
                  justifyContent={"flex-end"}
                >
                  <Button
                    onClick={() => {
                      setChangeRampUpDownTime('')
                      setShowPopup2(true);
                    }}
                    sx={{ width: 100 }}
                    type="submit"
                    variant="contained"
                    disabled={isDeleteDRLoading}
                  >
                    {isDeleteDRLoading ? "In Progress..." : "Delete"}
                  </Button>

                  <Button
                    onClick={clearHandler}
                    type="submit"
                    variant="contained"
                    sx={{ width: 100 }}
                  >
                    Clear
                  </Button>
                  {/* <Button onClick={handleDemandConfigGet} type="submit" variant="contained" disabled={isDRLoading1} sx={{ mr: 2 }}>
        {isDRLoading1 ? 'In Progress...' : 'Get'}
        </Button> */}
                  <Button
                    sx={{ width: 100 }}
                    onClick={() => {
                      setShowPopup(true);
                    }}
                    type="submit"
                    variant="contained"
                    disabled={isDRLoading}
                  >
                    {isDRLoading ? "In Progress..." : "Save"}
                  </Button>
                  {/* Confirmation dialog in case of Manual Tune */}
                  {showPopup && (
                    <div className="popup">
                      <div className="popup-content">
                        <div
                          className="close-icon"
                          onClick={() => setShowPopup(false)}
                        >
                          <FaTimes />
                        </div>
                        <p>Are you sure you want to proceed with Save?</p>
                        <div className="button-container">
                          <Button onClick={handleCancelConfirmation}>No</Button>
                          <Button
                            onClick={handleDemandConfigConfirmation}
                            variant="contained"
                            disabled={isApplyDRLoading}
                            sx={{ mr: 2 }}
                          >
                            {isApplyDRLoading ? "In Progress..." : "Yes"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  {showPopup2 && (
                    <div className="popup">
                      <div className="popup-content">
                        <div
                          className="close-icon"
                          onClick={() => setShowPopup2(false)}
                        >
                          <FaTimes />
                        </div>
                        <p>
                          Are you sure you want to proceed with deleting this
                          demand response config?
                        </p>
                        <div className="button-container">
                          <Button onClick={handleCancelConfirmation2}>
                            No
                          </Button>
                          <Button
                            onClick={handleDemandConfigDeletion}
                            variant="contained"
                            disabled={isDetachDRLoading}
                            sx={{ mr: 2 }}
                          >
                            {isDetachDRLoading ? "In Progress..." : "Yes"}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                  <div>
                    <SuccessModal
                      isOpen={successModalOpen}
                      onClose={handleCloseSuccessModal}
                      Status={submitMsg}
                      ManualTuneMsg={manualTuneMsg}
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Paper>
          <br />
          <Footer sub={true} />
        </Box>
      </PageContainer>
    );
  } else if (validUser !== -1 && validUser !== 0) {
    return (
      <PageContainer
        title="Demand"
        description="this is the demand configuration page"
      >
        <div>
          <SuccessModal
            isOpen={successModalOpen}
            onClose={handleCloseSuccessModal}
            Status={submitMsg}
          />
        </div>
      </PageContainer>
    );
  } else {
    return (
      <PageContainer
        title="Demand"
        description="this is the demand configuration page"
      >
        <PageLoading />
      </PageContainer>
    );
  }
};

export default DemandConfig;
