import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import PageContainer from "../components/PageContainer";
import { useAuth0 } from "@auth0/auth0-react";
import {
  Stack,
  Box,
  Typography,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContentText,
  DialogContent,
  DialogTitle,
  Tab,
  Tabs,
  Checkbox,
  Switch,
  Select,
  FormControlLabel,
  FormHelperText,
  Autocomplete,
  Tooltip,
  Paper,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import { env } from "../env";
import { RefreshInterval } from "../components/refresh-interval";
import { displayTS, DeviceDialog } from "./device-dialog";
import { darken, lighten } from "@mui/material/styles";
import Footer from "../components/footer";
import MultiSelectWithCheckboxes from "../components/MultiSelectWithCheckboxes";
import CloseIcon from "@mui/icons-material/Close";
import SettingsIcon from "@mui/icons-material/Settings";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import * as api from "../api/api";
import MenuItem from "@mui/material/MenuItem";
import PageLoading from "../components/pageloading";
import * as constants from "../constants";
import { AT1_chipLayout, AI25_chipLayout, AT28_chipLayout, AI36_chipLayout, AD_chipLayout, AI3680S_chipLayout } from "../constants";
import { useHistory, useLocation } from "react-router-dom";
import { List, arrayMove } from "react-movable";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { set } from "date-fns";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';


const DEFAULT_INTERVAL = 60;
/*
 * Constants specific to this page
 */
const CHIP_HEATMAP_CRITICAL = 106;
const FREQ_HEATMAP_CRITICAL = 2001;
const VOL_HEATMAP_CRITICAL = 0.45;
let globalSelectionModel = [];
let globalNotSelectionModel = [];

let validUser = -1;
const SuccessModal = ({ isOpen, onClose, Status }) => {
  //console.log('status is', Status)
  let h4Msg = "";
  let pMsg = "";
  switch (Status) {
    case "ok":
      break;
    case "nouser":
      h4Msg = "Authentication Failed!";
      pMsg =
        "User is not part of the Organization. Please contact your Administrator";
      break;
    case "fetchfailed":
      h4Msg = "Fetch Failed!";
      pMsg =
        "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine";
      break;
    default:
      h4Msg = "Unknown Error";
      pMsg =
        "Unable to fetch the data. Try refreshing the page. If issue still persists, please contact Technical Support at Auradine";
      break;
  }
  if (Status !== "ok" && Status !== "something") {
    return (
      <Popup className="popup1-content" open={isOpen} onClose={onClose}>
        <h4 className="popup-title">{h4Msg}</h4>
        <p className="success-message">{pMsg}</p>
        <div className="button-container">
          <button onClick={onClose}>Close</button>
        </div>
      </Popup>
    );
  }
};

const initialColVisibilityCS = {
  lastJobOp: true,
  lastJobStatus: true,
  recentJobs: true,
  csTag: true,
  org_name: true,
  chassis: true,
  state: true,
  status: true,
  mode: true,
  model: true,
  firmware: true,
  ip: true,
  uptime: true,
  totalTHs: true,
  joules: true,
  power: true,
  temp: false,
  summary: true,
  FanStats: true,
  TempStats: true,
  FreqStats: true,
  VoltageStats: true,
  remoteTuning: true,
  alertConfig: false,
  dgname: false,
  customName: false,
  mac: false,
  hostname: false,
  serial: true,
  accepted: false,
  rejected: false,
  elapsed: false,
  fan: false,
  psu: false,
  ModeStats: false,
  PSUStats: false,
  LEDStats: false,
  pools: false,
  devs: false,
  config: false,
  coin: false,
  devdetails: false,
  stats: false,
  lcd: false,
  ipreport: false,
  pool1: false,
  worker1: false,
  pool2: false,
  worker2: false,
  pool3: false,
  worker3: false,
  wend: false
};

const initialColVisibility = {
  lastJobOp: true,
  lastJobStatus: true,
  recentJobs: true,
  dgname: true,
  chassis: true,
  customName: true,
  serial: true,
  state: true,
  ip: true,
  status: true,
  mode: true,
  model: true,
  firmware: true,
  uptime: true,
  totalTHs: true,
  joules: true,
  power: true,
  remoteTuning: true,
  TempStats: true,
  FreqStats: true,
  VoltageStats: true,
  temp: false,
  alertConfig: true,
  accepted: false,
  rejected: false,
  elapsed: false,
  fan: false,
  psu: false,
  ipreport: false,
  summary: false,
  PSUStats: false,
  LEDStats: false,
  FanStats: false,
  lcd: false,
  ModeStats: false,
  mac: false,
  hostname: false,
  pool1: false,
  worker1: false,
  pool2: false,
  worker2: false,
  pool3: false,
  worker3: false,
  pools: false,
  devs: false,
  config: false,
  coin: false,
  devdetails: false,
  stats: false,
  wend: false
};

let b_techsupport = false;

const getChipLayout = (model) => {
  let chipLayout = []
  if (model === null || model === undefined) {
    return chipLayout;
  }
  chipLayout = AT1_chipLayout
  if (model.startsWith("AI25") || model.startsWith("AD25")) {
    chipLayout = AI25_chipLayout;
  } else if (model.startsWith("AT28")) {
    chipLayout = AT28_chipLayout;
  } else if (model.startsWith("AI36")) {
    if (model === "AI3680S") {
      chipLayout = AI3680S_chipLayout;
    } else {
      chipLayout = AI36_chipLayout;
    }
  } else if (model.startsWith("AD")) {
    chipLayout = AD_chipLayout;
  }
  return chipLayout;
}

const handleTempStats = (params) => {
  const newHBLayout = [];
  let model = null;
  let chassis = null;
  let critical = false;
  const el = params.value;
  model = el.model;
  chassis = el.chassis;
  let chipLayout = getChipLayout(model);
  if (el.Temperature) {
    const newChipLayout = [];
    // Initialize newChipLayout with zeros
    for (let i = 0; i < chipLayout.length; i++) {
      const hashboardLayout = [];
      for (let j = 0; j < chipLayout[i].length; j++) {
        hashboardLayout.push({ value: 0, id: chipLayout[i][j] });
      }
      newChipLayout.push(hashboardLayout);
    }
    // Push deep copies of newChipLayout to newHBLayout
    for (let i = 0; i < 3; i++) {
      const hashboardCopy = newChipLayout.map((row) =>
        row.map((cell) => ({ ...cell }))
      );
      newHBLayout.push(hashboardCopy);
    }
    let idx = 0;
    // Loop through temperature data and update newChipLayout accordingly
    el.Temperature.forEach((board) => {
      if (!(board.ChipTemp === undefined || board.ChipTemp === null)) {
        board.ChipTemp.forEach((el2) => {
          for (let i = 0; i < chipLayout.length; i++) {
            for (let j = 0; j < chipLayout[i].length; j++) {
              if (chipLayout[i][j] === el2.ID && el2.Temperature !== null && el2.Temperature !== undefined) {
                let t = el2.Temperature.toFixed(2);
                newHBLayout[idx][i][j].value = t;
                newHBLayout[idx][i][j].id = el2.ID;
                if (t >= CHIP_HEATMAP_CRITICAL) {
                  critical = true;
                }
              }
            }
          }
        });
        idx = idx + 1;
      }
    });
  }
  return { newHBLayout, model, chassis, critical };
};

const handleFreqStats = (params) => {
  const newHBLayout = [];
  let model = null;
  let chassis = null;
  let critical = false;
  const el = params.value;
  model = el.model;
  chassis = el.chassis;
  let chipLayout = getChipLayout(model);
  if (el.Frequency) {
    const newChipLayout = [];
    // Initialize newChipLayout with zeros
    for (let i = 0; i < chipLayout.length; i++) {
      const hashboardLayout = [];
      for (let j = 0; j < chipLayout[i].length; j++) {
        hashboardLayout.push({ value: 0, id: chipLayout[i][j] });
      }
      newChipLayout.push(hashboardLayout);
    }
    // Push deep copies of newChipLayout to newHBLayout
    for (let i = 0; i < 3; i++) {
      const hashboardCopy = newChipLayout.map((row) =>
        row.map((cell) => ({ ...cell }))
      );
      newHBLayout.push(hashboardCopy);
    }
    let idx = 0;
    // Loop through temperature data and update newChipLayout accordingly
    el.Frequency.forEach((board) => {
      if (
        !(board.ChipFrequency === undefined || board.ChipFrequency === null)
      ) {
        board.ChipFrequency.forEach((el2) => {
          for (let i = 0; i < chipLayout.length; i++) {
            for (let j = 0; j < chipLayout[i].length; j++) {
              if (chipLayout[i][j] === el2.ID && el2.Frequency !== null && el2.Frequency !== undefined) {
                let t = el2.Frequency.toFixed(2);
                newHBLayout[idx][i][j].value = t;
                newHBLayout[idx][i][j].id = el2.ID;
                if (t >= FREQ_HEATMAP_CRITICAL) {
                  critical = true;
                }
              }
            }
          }
        });
        idx = idx + 1;
      }
    });
  }
  return { newHBLayout, model, chassis, critical };
};

const handleVoltageStats = (params) => {
  const newHBLayout = [];
  let model = null;
  let chassis = null;
  let critical = false;
  const el = params.value;
  model = el.model;
  chassis = el.chassis;
  let chipLayout = getChipLayout(model);
  if (el.Voltage) {
    const newChipLayout = [];
    // Initialize newChipLayout with zeros
    for (let i = 0; i < chipLayout.length; i++) {
      const hashboardLayout = [];
      for (let j = 0; j < chipLayout[i].length; j++) {
        hashboardLayout.push({ value: 0, id: chipLayout[i][j] });
      }
      newChipLayout.push(hashboardLayout);
    }
    // Push deep copies of newChipLayout to newHBLayout
    for (let i = 0; i < 3; i++) {
      const hashboardCopy = newChipLayout.map((row) =>
        row.map((cell) => ({ ...cell }))
      );
      newHBLayout.push(hashboardCopy);
    }
    let idx = 0;
    // Loop through temperature data and update newChipLayout accordingly
    el.Voltage.forEach((board) => {
      if (!(board.ChipVoltage === undefined || board.ChipVoltage === null)) {
        board.ChipVoltage.forEach((el2) => {
          for (let i = 0; i < chipLayout.length; i++) {
            for (let j = 0; j < chipLayout[i].length; j++) {
              if (chipLayout[i][j] === el2.ID && el2.Voltage !== null && el2.Voltage !== undefined) {
                let t = el2.Voltage.toFixed(2);
                newHBLayout[idx][i][j].value = t;
                newHBLayout[idx][i][j].id = el2.ID;
                if (t >= VOL_HEATMAP_CRITICAL) {
                  critical = true;
                }
              }
            }
          }
        });
        idx = idx + 1;
      }
    });
  }
  return { newHBLayout, model, chassis, critical };
};

const renderJSONPretty = (params) => {
  if (params.value) {
    if (params.value.updatedAt) {
    } else {
      if (params.field === "recentJobs") {
        if (params.value.length <= 0) {
          return "";
        }
      } else {
        return "";
      }
    }
    const text = JSON.stringify(params.value, null, 4);
    if (
      params.field !== "TempStats" &&
      params.field !== "FreqStats" &&
      params.field !== "VoltageStats"
    ) {
        if (params.field === "recentJobs") {
            const { diffTime, timeStr } = displayTS(params.value[0].updatedAt);
            params.value.forEach((el) => {
              const { diffTime, timeStr } = displayTS(el.updatedAt);
              el.updatedAt = timeStr;
            });
            return (
              <DeviceDialog
                name={params.field}
                updatedAt={timeStr}
                text={text}
                code={params.value}
              />
            );
        } else {
          return (
            <DeviceDialog
              name={params.field}
              updatedAt={params.value.updatedAt}
              text={text}
              code={params.value}
            />
          );
      }
    } else if (params.field === "TempStats") {
      const { newHBLayout, model, chassis, critical } = handleTempStats(params);
      return (
        <DeviceDialog
          name={params.field}
          updatedAt={params.value.updatedAt}
          text={text}
          code={params.value}
          heatmaps={newHBLayout}
          serial={params.id}
          model={model}
          critical={critical}
          chassis={chassis}
        />
      );
    } else if (params.field === "FreqStats") {
      const { newHBLayout, model, chassis, critical } = handleFreqStats(params);
      return (
        <DeviceDialog
          name={params.field}
          updatedAt={params.value.updatedAt}
          text={text}
          code={params.value}
          heatmaps={newHBLayout}
          serial={params.id}
          model={model}
          critical={critical}
          chassis={chassis}
        />
      );
    } else if (params.field === "VoltageStats") {
      const { newHBLayout, model, chassis, critical } =
        handleVoltageStats(params);
      return (
        <DeviceDialog
          name={params.field}
          updatedAt={params.value.updatedAt}
          text={text}
          code={params.value}
          heatmaps={newHBLayout}
          serial={params.id}
          model={model}
          critical={critical}
          chassis={chassis}
        />
      );
    }
  }
  return "";
};

const initialColWidthsCS = {
  lastJobOp: 110,
  lastJobStatus: 140,
  recentJobs: 110,
  csTag: 200,
  org_name: 150,
  chassis: 140,
  state: 110,
  status: 120,
  mode: 140,
  model: 80,
  firmware: 100,
  ip: 120,
  uptime: 140,
  totalTHs: 70,
  joules: 60,
  power: 80,
  temp: 160,
  summary: 110,
  FanStats: 110,
  TempStats: 110,
  FreqStats: 110,
  VoltageStats: 110,
  remoteTuning: 140,
  alertConfig: 200,
  dgname: 120,
  customName: 120,
  mac: 120,
  hostname: 100,
  serial: 170,
  accepted: 100,
  rejected: 100,
  elapsed: 100,
  fan: 160,
  psu: 120,
  ModeStats: 110,
  PSUStats: 110,
  LEDStats: 110,
  pools: 110,
  devs: 110,
  config: 110,
  coin: 110,
  devdetails: 110,
  stats: 110,
  lcd: 110,
  ipreport: 110,
  pool1: 110,
  worker1: 110,
  pool2: 110,
  worker2: 110,
  pool3: 110,
  worker3: 110,
  wend: 110
};

const initialColWidths = {
  lastJobOp: 110,
  lastJobStatus: 140,
  recentJobs: 110,
  chassis: 140,
  state: 110,
  status: 120,
  mode: 140,
  model: 80,
  firmware: 100,
  ip: 120,
  uptime: 140,
  totalTHs: 70,
  joules: 60,
  power: 80,
  temp: 160,
  summary: 110,
  FanStats: 110,
  TempStats: 110,
  FreqStats: 110,
  VoltageStats: 110,
  remoteTuning: 140,
  alertConfig: 200,
  dgname: 120,
  customName: 120,
  mac: 120,
  hostname: 100,
  serial: 170,
  accepted: 100,
  rejected: 100,
  elapsed: 100,
  fan: 160,
  psu: 120,
  ModeStats: 110,
  PSUStats: 110,
  LEDStats: 110,
  pools: 110,
  devs: 110,
  config: 110,
  coin: 110,
  devdetails: 110,
  stats: 110,
  lcd: 110,
  ipreport: 110,
  pool1: 110,
  worker1: 110,
  pool2: 110,
  worker2: 110,
  pool3: 110,
  worker3: 110,
  wend: 110
};

export default function MinerStatus() {
  const [maxths, setMaxTHs] = React.useState(constants.MAXTHS);
  const [maxpower, setMaxPower] = React.useState(constants.MAXPOWER);
  const [count, setCount] = React.useState(100);
  const [rows, setRows] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [selectAllMiners, setSelectAllMiners] = React.useState(false);

  const [isFanOn, setIsFanOn] = React.useState(false);
  const [isCoolantOn, setIsCoolantOn] = React.useState(false);
  const [isMinerInternetOn, setIsMinerInternetOn] = React.useState(false);
  const [isEcoOn, setIsEcoOn] = React.useState(false);

  //col ordering
  const [colOrder, setColOrder] = React.useState([]);
  const [colOrderCS, setColOrderCS] = React.useState([]);
  const [openColPopup, setOpenColPopup] = React.useState(false);
  const [visibleCols, setVisibleCols] = React.useState([]);
  const [visibleColsCS, setVisibleColsCS] = React.useState([]);
  const [timegap, setTimegap] = React.useState(DEFAULT_INTERVAL);
  const [stopRefresh, setStopRefresh] = React.useState(false);
  const [lastRefresh, setLastRefresh] = React.useState("");


  const [minerTransfer, setMinerTransfer] = React.useState(false);
  const [minerRemoveOperation, setMinerRemoveOperation] = React.useState(false);

  const [csvOpen, setCsvOpen] = React.useState(false);
  const [csvDGs, setCsvDGs] = React.useState([]);
  const multiSelectRef = React.useRef(null);

  const [successModalOpen, setSuccessModalOpen] = React.useState(false);
  const [submitMsg, setSubmitMsg] = React.useState("");
  const [techSupport, setTechSupport] = React.useState(false);
  const [displaytable, setDisplayTable] = React.useState(null);
  const [openSaveConfirmation, setSaveConfirmation] = React.useState(false);
  const [confMessage, setConfMessage] = React.useState("");
  const [colWidthsCS, setColWidthsCS] = React.useState(initialColWidthsCS);
  const [colWidths, setColWidths] = React.useState(initialColWidths);
  const [desiredAlertInterval, setDesiredAlertInterval] = React.useState("");
  const [initialDesiredAlertInterval, setInitialDesiredAlertInterval] =
    React.useState("");
  const [transferMiners, setTransferMiners] = React.useState([]);
  const [showTransferJobs, setShowTransferJobs] = React.useState(false)
  const [members, setMembers] = React.useState([]);
  const [orgID, setOrgID] = React.useState("");
  const [alertMembers, setAlertMembers] = React.useState([]);
  const [initialAlertMembers, setInitialAlertMembers] = React.useState([]);
  const [selectAllAlertMembers, setSelectAllAlertMembers] =
    React.useState(false);
  const [deleteGroups, setDeleteGroups] = React.useState([]);
  const [numberOfChipsOverThreshold, setNumberOfChipsOverThreshold] =
    React.useState("");
  const [isminerGroupSelected, setIsMinerGroupSelected] = React.useState(false);
  const [minerGroups, setMinerGroups] = React.useState([]);
  const [minerGroup, setMinerGroup] = React.useState("");
  const [role, setRole] = React.useState("");
  const [openOperationsPopup, setOpenOperationsPopup] = React.useState(false);

  const [colVisibilityModelCS, setColVisibilityModelCS] = React.useState(() => {
    const storedColVisibilityCS = localStorage.getItem(
      "MinerStatusColVisibilityCS"
    );
    return storedColVisibilityCS
      ? JSON.parse(storedColVisibilityCS)
      : initialColVisibilityCS;
  });

  const [colVisibilityModel, setColVisibilityModel] = React.useState(() => {
    const storedColVisibility = localStorage.getItem(
      "MinerStatusColVisibility"
    );
    return storedColVisibility
      ? JSON.parse(storedColVisibility)
      : initialColVisibility;
  });

  const [sortModelCS, setSortModelCS] = React.useState([]);
  const [sortModel, setSortModel] = React.useState([]);

  const [filterModel, setFilterModel] = React.useState(() => {
    const savedFilterModel = localStorage.getItem("MinerStatusfilterModel");
    return savedFilterModel ? JSON.parse(savedFilterModel) : { items: [] };
  });
  const [startTimeValue, setStartTimeValue] = React.useState(dayjs().add(5, 'minute'));
  const [startTimeString, setStartTimeString] = React.useState('');
  const [startTimeError, setStartTimeError] = React.useState('');
  const [startTimeErrorValue, setStartTimeErrorValue] = React.useState(false);
  const [changeUpgradeWindowValueError, setchangeUpgradeWindowTimeValueError] = React.useState({
    error: false,
    helperText: "",
  })
  const [changeUpgradeWindowTime, setChangeUpgradeWindowTime] = React.useState('');

  const [selectedJob, setSelectedJob] = React.useState(null);

  const jobColumns = [
    {
      field: "select",
      headerName: "",
      width: 50,
      renderCell: (params) => (
        <Radio
          checked={selectedJob === params.row.jobid}
          onChange={() => setSelectedJob(params.row.jobid)}
          value={params.row.jobid}
        />
      ),
    },
    { field: "jobid", headerName: "Job ID", width: 300},
    { field: "source_org_name", headerName:"Source Org", width: 250 },
    { field: "target_org_name", headerName: "Target Org", width: 250 },
    { field: "source_email", headerName: "Source Email", width: 250 },
    { field: "target_email", headerName: "Target Email", width: 250 },
  ];

  const handleStartTimeValue = (newValue) => {
    if (newValue !== null && newValue !== undefined) {
      setStartTimeValue(newValue);
      let starttime = newValue['$d'].toString();

      if (starttime && starttime !== 'Invalid Date') {
        const sDate = new Date(starttime);
        const currentTime = new Date();
        // Check if selected time is at least 5 minutes greater than the current time
        if (sDate.getTime() < currentTime.getTime() + 5 * 60 * 1000) {
          setStartTimeError("Start time must be at least 5 minutes in the future");
          setStartTimeErrorValue(true);
        } else {
          setStartTimeError("");
          setStartTimeErrorValue(false);
        }
        setStartTimeString(starttime);
      } else {
        setStartTimeString('');
        setStartTimeError("");
        setStartTimeErrorValue(false);
      }
    }
  };

  const handleChangeUpgradeWindowTimeValue = (event) => {
    const value = event.target.value;
    const intRegex = /^\d+$/
    if (value === '' || intRegex.test(value)) {
      setChangeUpgradeWindowTime(value);
      const window = parseInt(value)
        if (window < 120 || window > 43200) {
          setchangeUpgradeWindowTimeValueError({ error: true, helperText: "Enter value between 120 and 43200" });
          return
        }
      setchangeUpgradeWindowTimeValueError({ error: false, helperText: "" });
    } else {
      setchangeUpgradeWindowTimeValueError({ error: true, helperText: "Enter value between 120 and 43200" });
    }
  };

  const handleFilterModelChange = (newFilterModel) => {
    setFilterModel(newFilterModel);
    localStorage.setItem(
      "MinerStatusfilterModel",
      JSON.stringify(newFilterModel)
    );
  };

  const [filterModelCS, setFilterModelCS] = React.useState(() => {
    const savedFilterModelCS = localStorage.getItem("MinerStatusfilterModelCS");
    return savedFilterModelCS ? JSON.parse(savedFilterModelCS) : { items: [] };
  });

  const handleFilterModelChangeCS = (newFilterModel) => {
    setFilterModelCS(newFilterModel);
    localStorage.setItem(
      "MinerStatusfilterModelCS",
      JSON.stringify(newFilterModel)
    );
  };

  React.useEffect(() => {
    let starttimevalue = 5;
    let startValue = dayjs().add(starttimevalue, 'minute')
    setStartTimeValue(startValue)
  }, []);

  React.useEffect(() => {
    const storedColWidthsCS = localStorage.getItem("MinerStatusColWidthsCS");
    if (storedColWidthsCS) {
      setColWidthsCS(JSON.parse(storedColWidthsCS));
    }
  }, []);

  React.useEffect(() => {
    const storedColVisibilityCS = localStorage.getItem(
      "MinerStatusColVisibilityCS"
    );
    if (storedColVisibilityCS) {
      setColVisibilityModelCS(JSON.parse(storedColVisibilityCS));
    }
  }, []);

  React.useEffect(() => {
    const storedSortModelCS = localStorage.getItem("MinerStatusSortModelCS");
    if (storedSortModelCS) {
      setSortModelCS(JSON.parse(storedSortModelCS));
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem(
      "MinerStatusColVisibilityCS",
      JSON.stringify(colVisibilityModelCS)
    );
    let visiblityForColOrder = Object.keys(colVisibilityModelCS).filter(
      (key) => colVisibilityModelCS[key]
    );
    visiblityForColOrder.forEach((item, i, visiblityForColOrder) => {
      if (item === "dgname") {
        visiblityForColOrder[i] = "Group Name";
      } else if (item === "org_name") {
        visiblityForColOrder[i] = "Org Name";
      } else if (item === "chassis") {
        visiblityForColOrder[i] = "Chassis SN";
      } else if (item === "csTag") {
        visiblityForColOrder[i] = "CSTag";
      } else if (item === "serial") {
        visiblityForColOrder[i] = "CB SN";
      } else if (item === "state") {
        visiblityForColOrder[i] = "State";
      } else if (item === "ip") {
        visiblityForColOrder[i] = "IP address";
      } else if (item === "status") {
        visiblityForColOrder[i] = "LED Status";
      } else if (item === "mode") {
        visiblityForColOrder[i] = "Mode";
      } else if (item === "model") {
        visiblityForColOrder[i] = "Model";
      } else if (item === "firmware") {
        visiblityForColOrder[i] = "Version";
      } else if (item === "uptime") {
        visiblityForColOrder[i] = "UpTime";
      } else if (item === "totalTHs") {
        visiblityForColOrder[i] = "TH/S";
      } else if (item === "joules") {
        visiblityForColOrder[i] = "J/THs";
      } else if (item === "power") {
        visiblityForColOrder[i] = "Power(W)";
      } else if (item === "remoteTuning") {
        visiblityForColOrder[i] = "Tune Config Used";
      } else if (item === "TempStats") {
        visiblityForColOrder[i] = "Chip °C";
      } else if (item === "FreqStats") {
        visiblityForColOrder[i] = "FrequencyStats";
      } else if (item === "VoltageStats") {
        visiblityForColOrder[i] = "VoltageStats";
      } else if (item === "temp") {
        visiblityForColOrder[i] = "Board °C";
      } else if (item === "alertConfig") {
        visiblityForColOrder[i] = "AlertConfig";
      } else if (item === "accepted") {
        visiblityForColOrder[i] = "Accepted";
      } else if (item === "rejected") {
        visiblityForColOrder[i] = "Rejected";
      } else if (item === "elapsed") {
        visiblityForColOrder[i] = "Elapsed";
      } else if (item === "fan") {
        visiblityForColOrder[i] = "Fan Speed";
      } else if (item === "psu") {
        visiblityForColOrder[i] = "PSU";
      } else if (item === "ipreport") {
        visiblityForColOrder[i] = "IP Report";
      } else if (item === "summary") {
        visiblityForColOrder[i] = "Summary";
      } else if (item === "PSUStats") {
        visiblityForColOrder[i] = "PSUStats";
      } else if (item === "LEDStats") {
        visiblityForColOrder[i] = "LEDStats";
      } else if (item === "FanStats") {
        visiblityForColOrder[i] = "FanStats";
      } else if (item === "lcd") {
        visiblityForColOrder[i] = "LCD";
      } else if (item === "ModeStats") {
        visiblityForColOrder[i] = "ModeStats";
      } else if (item === "mac") {
        visiblityForColOrder[i] = "MAC address";
      } else if (item === "pool1") {
        visiblityForColOrder[i] = "Pool1";
      } else if (item === "worker1") {
        visiblityForColOrder[i] = "Worker1";
      } else if (item === "pool2") {
        visiblityForColOrder[i] = "Pool2";
      } else if (item === "worker2") {
        visiblityForColOrder[i] = "Worker2";
      } else if (item === "pool3") {
        visiblityForColOrder[i] = "Pool3";
      } else if (item === "worker3") {
        visiblityForColOrder[i] = "Worker3";
      } else if (item === "pools") {
        visiblityForColOrder[i] = "Pools";
      } else if (item === "devs") {
        visiblityForColOrder[i] = "Devs";
      } else if (item === "config") {
        visiblityForColOrder[i] = "Config";
      } else if (item === "coin") {
        visiblityForColOrder[i] = "Coin";
      } else if (item === "devdetails") {
        visiblityForColOrder[i] = "Dev Details";
      } else if (item === "customName") {
        visiblityForColOrder[i] = "Tag";
      } else if (item === "stats") {
        visiblityForColOrder[i] = "Stats";
      } else if (item === "lastJobOp") {
        visiblityForColOrder[i] = "Last Job Op";
      } else if (item === "lastJobStatus") {
        visiblityForColOrder[i] = "Last Job Status";
      } else if (item === "recentJobs") {
        visiblityForColOrder[i] = "Recent Jobs";
      } else if (item === "wend") {
        visiblityForColOrder[i] = "Warranty End";
      }
    });
    setVisibleColsCS(visiblityForColOrder);
  }, [colVisibilityModelCS]);

  React.useEffect(() => {
    localStorage.setItem(
      "MinerStatusColVisibility",
      JSON.stringify(colVisibilityModel)
    );
    let visiblityForColOrder = Object.keys(colVisibilityModel).filter(
      (key) => colVisibilityModel[key]
    );
    visiblityForColOrder.forEach((item, i, visiblityForColOrder) => {
      if (item === "dgname") {
        visiblityForColOrder[i] = "Group Name";
      } else if (item === "org_name") {
        visiblityForColOrder[i] = "Org Name";
      } else if (item === "chassis") {
        visiblityForColOrder[i] = "Chassis SN";
      } else if (item === "customName") {
        visiblityForColOrder[i] = "Tag";
      } else if (item === "serial") {
        visiblityForColOrder[i] = "CB SN";
      } else if (item === "state") {
        visiblityForColOrder[i] = "State";
      } else if (item === "ip") {
        visiblityForColOrder[i] = "IP address";
      } else if (item === "status") {
        visiblityForColOrder[i] = "LED Status";
      } else if (item === "mode") {
        visiblityForColOrder[i] = "Mode";
      } else if (item === "model") {
        visiblityForColOrder[i] = "Model";
      } else if (item === "firmware") {
        visiblityForColOrder[i] = "Version";
      } else if (item === "uptime") {
        visiblityForColOrder[i] = "UpTime";
      } else if (item === "totalTHs") {
        visiblityForColOrder[i] = "TH/S";
      } else if (item === "joules") {
        visiblityForColOrder[i] = "J/THs";
      } else if (item === "power") {
        visiblityForColOrder[i] = "Power(W)";
      } else if (item === "remoteTuning") {
        visiblityForColOrder[i] = "Tune Config Used";
      } else if (item === "TempStats") {
        visiblityForColOrder[i] = "Chip °C";
      } else if (item === "FreqStats") {
        visiblityForColOrder[i] = "FrequencyStats";
      } else if (item === "VoltageStats") {
        visiblityForColOrder[i] = "VoltageStats";
      } else if (item === "temp") {
        visiblityForColOrder[i] = "Board °C";
      } else if (item === "alertConfig") {
        visiblityForColOrder[i] = "AlertConfig";
      } else if (item === "accepted") {
        visiblityForColOrder[i] = "Accepted";
      } else if (item === "rejected") {
        visiblityForColOrder[i] = "Rejected";
      } else if (item === "elapsed") {
        visiblityForColOrder[i] = "Elapsed";
      } else if (item === "fan") {
        visiblityForColOrder[i] = "Fan Speed";
      } else if (item === "psu") {
        visiblityForColOrder[i] = "PSU";
      } else if (item === "ipreport") {
        visiblityForColOrder[i] = "IP Report";
      } else if (item === "summary") {
        visiblityForColOrder[i] = "Summary";
      } else if (item === "PSUStats") {
        visiblityForColOrder[i] = "PSUStats";
      } else if (item === "LEDStats") {
        visiblityForColOrder[i] = "LEDStats";
      } else if (item === "FanStats") {
        visiblityForColOrder[i] = "FanStats";
      } else if (item === "lcd") {
        visiblityForColOrder[i] = "LCD";
      } else if (item === "ModeStats") {
        visiblityForColOrder[i] = "ModeStats";
      } else if (item === "mac") {
        visiblityForColOrder[i] = "MAC address";
      } else if (item === "pool1") {
        visiblityForColOrder[i] = "Pool1";
      } else if (item === "worker1") {
        visiblityForColOrder[i] = "Worker1";
      } else if (item === "pool2") {
        visiblityForColOrder[i] = "Pool2";
      } else if (item === "worker2") {
        visiblityForColOrder[i] = "Worker2";
      } else if (item === "pool3") {
        visiblityForColOrder[i] = "Pool3";
      } else if (item === "worker3") {
        visiblityForColOrder[i] = "Worker3";
      } else if (item === "pools") {
        visiblityForColOrder[i] = "Pools";
      } else if (item === "devs") {
        visiblityForColOrder[i] = "Devs";
      } else if (item === "config") {
        visiblityForColOrder[i] = "Config";
      } else if (item === "coin") {
        visiblityForColOrder[i] = "Coin";
      } else if (item === "devdetails") {
        visiblityForColOrder[i] = "Dev Details";
      } else if (item === "stats") {
        visiblityForColOrder[i] = "Stats";
      } else if (item === "lastJobOp") {
        visiblityForColOrder[i] = "Last Job Op";
      } else if (item === "lastJobStatus") {
        visiblityForColOrder[i] = "Last Job Status";
      } else if (item === "recentJobs") {
        visiblityForColOrder[i] = "Recent Jobs";
      } else if (item === "wend") {
        visiblityForColOrder[i] = "Warranty End";
      }
    });
    setVisibleCols(visiblityForColOrder);
  }, [colVisibilityModel]);

  React.useEffect(() => {
    localStorage.setItem("MinerStatusColWidthsCS", JSON.stringify(colWidthsCS));
  }, [colWidthsCS]);

  const updateHideFields = (columns, model) => {
    return columns.map((column) => {
      if (model.hasOwnProperty(column.field)) {
        return {
          ...column,
          hide: !model[column.field],
        };
      }
      return column;
    });
  };

  const handleColumnVisibilityChangeCS = (newModel) => {
    setColVisibilityModelCS(newModel);
    localStorage.setItem(
      "MinerStatusColVisibilityCS",
      JSON.stringify(newModel)
    );
    const updatedColumns = updateHideFields(TechSupportcolumns, newModel);
    setTechSupportcolumns(updatedColumns);
  };

  const handleSortModelChangeCS = (newSortModel) => {
    setSortModelCS(newSortModel);
    localStorage.setItem(
      "MinerStatusSortModelCS",
      JSON.stringify(newSortModel)
    );
  };

  function handleColumnWidthChangeCS(width) {
    if (width.colDef.field === "csTag") {
      setColWidthsCS({ ...colWidthsCS, csTag: width.colDef.width });
    } else if (width.colDef.field === "org_name") {
      setColWidthsCS({ ...colWidthsCS, org_name: width.colDef.width });
    } else if (width.colDef.field === "chassis") {
      setColWidthsCS({ ...colWidthsCS, chassis: width.colDef.width });
    } else if (width.colDef.field === "state") {
      setColWidthsCS({ ...colWidthsCS, state: width.colDef.width });
    } else if (width.colDef.field === "status") {
      setColWidthsCS({ ...colWidthsCS, status: width.colDef.width });
    } else if (width.colDef.field === "mode") {
      setColWidthsCS({ ...colWidthsCS, mode: width.colDef.width });
    } else if (width.colDef.field === "model") {
      setColWidthsCS({ ...colWidthsCS, model: width.colDef.width });
    } else if (width.colDef.field === "firmware") {
      setColWidthsCS({ ...colWidthsCS, firmware: width.colDef.width });
    } else if (width.colDef.field === "ip") {
      setColWidthsCS({ ...colWidthsCS, ip: width.colDef.width });
    } else if (width.colDef.field === "uptime") {
      setColWidthsCS({ ...colWidthsCS, uptime: width.colDef.width });
    } else if (width.colDef.field === "totalTHs") {
      setColWidthsCS({ ...colWidthsCS, totalTHs: width.colDef.width });
    } else if (width.colDef.field === "joules") {
      setColWidthsCS({ ...colWidthsCS, joules: width.colDef.width });
    } else if (width.colDef.field === "power") {
      setColWidthsCS({ ...colWidthsCS, power: width.colDef.width });
    } else if (width.colDef.field === "temp") {
      setColWidthsCS({ ...colWidthsCS, temp: width.colDef.width });
    } else if (width.colDef.field === "summary") {
      setColWidthsCS({ ...colWidthsCS, summary: width.colDef.width });
    } else if (width.colDef.field === "FanStats") {
      setColWidthsCS({ ...colWidthsCS, FanStats: width.colDef.width });
    } else if (width.colDef.field === "TempStats") {
      setColWidthsCS({ ...colWidthsCS, TempStats: width.colDef.width });
    } else if (width.colDef.field === "FreqStats") {
      setColWidthsCS({ ...colWidthsCS, FreqStats: width.colDef.width });
    } else if (width.colDef.field === "VoltageStats") {
      setColWidthsCS({ ...colWidthsCS, VoltageStats: width.colDef.width });
    } else if (width.colDef.field === "remoteTuning") {
      setColWidthsCS({ ...colWidthsCS, remoteTuning: width.colDef.width });
    } else if (width.colDef.field === "alertConfig") {
      setColWidthsCS({ ...colWidthsCS, alertConfig: width.colDef.width });
    } else if (width.colDef.field === "dgname") {
      setColWidthsCS({ ...colWidthsCS, dgname: width.colDef.width });
    } else if (width.colDef.field === "customName") {
      setColWidthsCS({ ...colWidthsCS, customName: width.colDef.width });
    } else if (width.colDef.field === "mac") {
      setColWidthsCS({ ...colWidthsCS, mac: width.colDef.width });
    } else if (width.colDef.field === "hostname") {
      setColWidthsCS({ ...colWidthsCS, hostname: width.colDef.width });
    } else if (width.colDef.field === "serial") {
      setColWidthsCS({ ...colWidthsCS, serial: width.colDef.width });
    } else if (width.colDef.field === "accepted") {
      setColWidthsCS({ ...colWidthsCS, accepted: width.colDef.width });
    } else if (width.colDef.field === "rejected") {
      setColWidthsCS({ ...colWidthsCS, rejected: width.colDef.width });
    } else if (width.colDef.field === "elapsed") {
      setColWidthsCS({ ...colWidthsCS, elapsed: width.colDef.width });
    } else if (width.colDef.field === "fan") {
      setColWidthsCS({ ...colWidthsCS, fan: width.colDef.width });
    } else if (width.colDef.field === "psu") {
      setColWidthsCS({ ...colWidthsCS, psu: width.colDef.width });
    } else if (width.colDef.field === "ModeStats") {
      setColWidthsCS({ ...colWidthsCS, ModeStats: width.colDef.width });
    } else if (width.colDef.field === "PSUStats") {
      setColWidthsCS({ ...colWidthsCS, PSUStats: width.colDef.width });
    } else if (width.colDef.field === "LEDStats") {
      setColWidthsCS({ ...colWidthsCS, LEDStats: width.colDef.width });
    } else if (width.colDef.field === "pools") {
      setColWidthsCS({ ...colWidthsCS, pools: width.colDef.width });
    } else if (width.colDef.field === "devs") {
      setColWidthsCS({ ...colWidthsCS, devs: width.colDef.width });
    } else if (width.colDef.field === "config") {
      setColWidthsCS({ ...colWidthsCS, config: width.colDef.width });
    } else if (width.colDef.field === "coin") {
      setColWidthsCS({ ...colWidthsCS, coin: width.colDef.width });
    } else if (width.colDef.field === "devdetails") {
      setColWidthsCS({ ...colWidthsCS, devdetails: width.colDef.width });
    } else if (width.colDef.field === "stats") {
      setColWidthsCS({ ...colWidthsCS, stats: width.colDef.width });
    } else if (width.colDef.field === "lcd") {
      setColWidthsCS({ ...colWidthsCS, lcd: width.colDef.width });
    } else if (width.colDef.field === "ipreport") {
      setColWidthsCS({ ...colWidthsCS, ipreport: width.colDef.width });
    } else if (width.colDef.field === "pool1") {
      setColWidthsCS({ ...colWidthsCS, pool1: width.colDef.width });
    } else if (width.colDef.field === "worker1") {
      setColWidthsCS({ ...colWidthsCS, worker1: width.colDef.width });
    } else if (width.colDef.field === "pool2") {
      setColWidthsCS({ ...colWidthsCS, pool2: width.colDef.width });
    } else if (width.colDef.field === "worker2") {
      setColWidthsCS({ ...colWidthsCS, worker2: width.colDef.width });
    } else if (width.colDef.field === "pool3") {
      setColWidthsCS({ ...colWidthsCS, pool3: width.colDef.width });
    } else if (width.colDef.field === "worker3") {
      setColWidthsCS({ ...colWidthsCS, worker3: width.colDef.width });
    } else if (width.colDef.field === "lastJobOp") {
      setColWidthsCS({ ...colWidthsCS, lastJobOp: width.colDef.width });
    } else if (width.colDef.field === "lastJobStatus") {
      setColWidthsCS({ ...colWidthsCS, lastJobStatus: width.colDef.width });
    } else if (width.colDef.field === "recentJobs") {
      setColWidthsCS({ ...colWidthsCS, recentJobs: width.colDef.width });
    } else if (width.colDef.field === "wend") {
      setColWidthsCS({ ...colWidthsCS, wend: width.colDef.width });
    }
    // console.log('change the techsupport columns when width changes')
    setTechSupportcolumns((prevData) => {
      return prevData.map((item) => {
        if (item.field === width.colDef.field) {
          item.width = width.colDef.width;
        }
        return item;
      });
    });
  }

  /* Do it for Customer Columns */
  React.useEffect(() => {
    const storedColWidths = localStorage.getItem("MinerStatusColWidths");
    if (storedColWidths) {
      setColWidths(JSON.parse(storedColWidths));
    }
  }, []);

  React.useEffect(() => {
    const storedColVisibility = localStorage.getItem(
      "MinerStatusColVisibility"
    );
    if (storedColVisibility) {
      setColVisibilityModel(JSON.parse(storedColVisibility));
    }
  }, []);

  React.useEffect(() => {
    const storedSortModel = localStorage.getItem("MinerStatusSortModel");
    if (storedSortModel) {
      setSortModel(JSON.parse(storedSortModel));
    }
  }, []);

  React.useEffect(() => {
    localStorage.setItem("MinerStatusColWidths", JSON.stringify(colWidths));
  }, [colWidths]);

  const handleColumnVisibilityChange = (newModel) => {
    setColVisibilityModel(newModel);
    localStorage.setItem("MinerStatusColVisibility", JSON.stringify(newModel));
    const updatedColumns = updateHideFields(columns, newModel);
    setColumns(updatedColumns);
  };

  const handleSortModelChange = (newSortModel) => {
    setSortModel(newSortModel);
    localStorage.setItem("MinerStatusSortModel", JSON.stringify(newSortModel));
  };

  function handleColumnWidthChange(width) {
    if (width.colDef.field === "chassis") {
      setColWidths({ ...colWidths, chassis: width.colDef.width });
    } else if (width.colDef.field === "state") {
      setColWidths({ ...colWidths, state: width.colDef.width });
    } else if (width.colDef.field === "status") {
      setColWidths({ ...colWidths, status: width.colDef.width });
    } else if (width.colDef.field === "mode") {
      setColWidths({ ...colWidths, mode: width.colDef.width });
    } else if (width.colDef.field === "model") {
      setColWidths({ ...colWidths, model: width.colDef.width });
    } else if (width.colDef.field === "firmware") {
      setColWidths({ ...colWidths, firmware: width.colDef.width });
    } else if (width.colDef.field === "ip") {
      setColWidths({ ...colWidths, ip: width.colDef.width });
    } else if (width.colDef.field === "uptime") {
      setColWidths({ ...colWidths, uptime: width.colDef.width });
    } else if (width.colDef.field === "totalTHs") {
      setColWidths({ ...colWidths, totalTHs: width.colDef.width });
    } else if (width.colDef.field === "joules") {
      setColWidths({ ...colWidths, joules: width.colDef.width });
    } else if (width.colDef.field === "power") {
      setColWidths({ ...colWidths, power: width.colDef.width });
    } else if (width.colDef.field === "temp") {
      setColWidths({ ...colWidths, temp: width.colDef.width });
    } else if (width.colDef.field === "summary") {
      setColWidths({ ...colWidths, summary: width.colDef.width });
    } else if (width.colDef.field === "FanStats") {
      setColWidths({ ...colWidths, FanStats: width.colDef.width });
    } else if (width.colDef.field === "TempStats") {
      setColWidths({ ...colWidths, TempStats: width.colDef.width });
    } else if (width.colDef.field === "FreqStats") {
      setColWidths({ ...colWidths, FreqStats: width.colDef.width });
    } else if (width.colDef.field === "VoltageStats") {
      setColWidths({ ...colWidths, VoltageStats: width.colDef.width });
    } else if (width.colDef.field === "remoteTuning") {
      setColWidths({ ...colWidths, remoteTuning: width.colDef.width });
    } else if (width.colDef.field === "alertConfig") {
      setColWidths({ ...colWidths, alertConfig: width.colDef.width });
    } else if (width.colDef.field === "dgname") {
      setColWidths({ ...colWidths, dgname: width.colDef.width });
    } else if (width.colDef.field === "customName") {
      setColWidths({ ...colWidths, customName: width.colDef.width });
    } else if (width.colDef.field === "mac") {
      setColWidths({ ...colWidths, mac: width.colDef.width });
    } else if (width.colDef.field === "hostname") {
      setColWidths({ ...colWidths, hostname: width.colDef.width });
    } else if (width.colDef.field === "serial") {
      setColWidths({ ...colWidths, serial: width.colDef.width });
    } else if (width.colDef.field === "accepted") {
      setColWidths({ ...colWidths, accepted: width.colDef.width });
    } else if (width.colDef.field === "rejected") {
      setColWidths({ ...colWidths, rejected: width.colDef.width });
    } else if (width.colDef.field === "elapsed") {
      setColWidths({ ...colWidths, elapsed: width.colDef.width });
    } else if (width.colDef.field === "fan") {
      setColWidths({ ...colWidths, fan: width.colDef.width });
    } else if (width.colDef.field === "psu") {
      setColWidths({ ...colWidths, psu: width.colDef.width });
    } else if (width.colDef.field === "ModeStats") {
      setColWidths({ ...colWidths, ModeStats: width.colDef.width });
    } else if (width.colDef.field === "PSUStats") {
      setColWidths({ ...colWidths, PSUStats: width.colDef.width });
    } else if (width.colDef.field === "LEDStats") {
      setColWidths({ ...colWidths, LEDStats: width.colDef.width });
    } else if (width.colDef.field === "pools") {
      setColWidths({ ...colWidths, pools: width.colDef.width });
    } else if (width.colDef.field === "devs") {
      setColWidths({ ...colWidths, devs: width.colDef.width });
    } else if (width.colDef.field === "config") {
      setColWidths({ ...colWidths, config: width.colDef.width });
    } else if (width.colDef.field === "coin") {
      setColWidths({ ...colWidths, coin: width.colDef.width });
    } else if (width.colDef.field === "devdetails") {
      setColWidths({ ...colWidths, devdetails: width.colDef.width });
    } else if (width.colDef.field === "stats") {
      setColWidths({ ...colWidths, stats: width.colDef.width });
    } else if (width.colDef.field === "lcd") {
      setColWidths({ ...colWidths, lcd: width.colDef.width });
    } else if (width.colDef.field === "ipreport") {
      setColWidths({ ...colWidths, ipreport: width.colDef.width });
    } else if (width.colDef.field === "pool1") {
      setColWidths({ ...colWidths, pool1: width.colDef.width });
    } else if (width.colDef.field === "worker1") {
      setColWidths({ ...colWidths, worker1: width.colDef.width });
    } else if (width.colDef.field === "pool2") {
      setColWidths({ ...colWidths, pool2: width.colDef.width });
    } else if (width.colDef.field === "worker2") {
      setColWidths({ ...colWidths, worker2: width.colDef.width });
    } else if (width.colDef.field === "pool3") {
      setColWidths({ ...colWidths, pool3: width.colDef.width });
    } else if (width.colDef.field === "worker3") {
      setColWidths({ ...colWidths, worker3: width.colDef.width });
    } else if (width.colDef.field === "lastJobOp") {
      setColWidths({ ...colWidths, lastJobOp: width.colDef.width });
    } else if (width.colDef.field === "lastJobStatus") {
      setColWidths({ ...colWidths, lastJobStatus: width.colDef.width });
    } else if (width.colDef.field === "recentJobs") {
      setColWidths({ ...colWidths, recentJobs: width.colDef.width });
    } else if (width.colDef.field === "wend") {
      setColWidths({ ...colWidths, wend: width.colDef.width });
    }
    setColumns((prevData) => {
      return prevData.map((item) => {
        if (item.field === width.colDef.field) {
          item.width = width.colDef.width;
        }
        return item;
      });
    });
  }

  const handleCloseSaveConfMessage = () => {
    setSaveConfirmation(false);
  };

  const handleMinerNameChange = (event, rowId) => {
    const pattern = /^[a-zA-Z0-9-.]*$/;
    let value = event.target.value;
    const filteredValue = value
      .split("")
      .filter((char) => pattern.test(char))
      .join("");
    event.target.value = filteredValue;
    value = event.target.value;
    const updatedRows = [...rows];
    const rowIndex = updatedRows.findIndex((row) => row.serial === rowId);
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      customName: value,
    };
    setRows(updatedRows);
  };

  const initialMinerStatusColumns = [
    {
      field: "dgname",
      headerName: "Group Name",
      width: colWidths.dgname,
      maxWidth: 500,
      hide: !colVisibilityModel.dgname,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "chassis",
      headerName: "Chassis SN",
      width: colWidths.chassis,
      maxWidth: 400,
      hide: !colVisibilityModel.chassis,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "customName",
      headerName: "Tag",
      width: colWidths.customName,
      maxWidth: 400,
      hide: !colVisibilityModel.customName,
      renderCell: (params) => (
        <TextField
          id="standard-basic"
          sx={{
            marginTop: -2.15,
            marginLeft: -2.35,
            width: 300,
            "& fieldset": { border: "none" },
          }}
          defaultValue={params.value}
          onChange={(event) => handleMinerNameChange(event, params.id)}
          inputProps={{
            maxLength: 32,
            sx: {
              color: "black",
              fontSize: "13.5px",
              marginTop: "0px",
              "&::placeholder": {
                color: "gray",
                opacity: 0.75,
                fontStyle: "italic",
                fontSize: "13px",
              },
            },
          }}
          placeholder="Tag your miner"
        />
      ),
    },
    {
      field: "serial",
      headerName: "CB SN",
      width: colWidths.serial,
      maxWidth: 400,
      hide: !colVisibilityModel.serial,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "state",
      headerName: "State",
      width: colWidths.state,
      maxWidth: 400,
      hide: !colVisibilityModel.state,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "ip",
      headerName: "IP address",
      width: colWidths.ip,
      maxWidth: 400,
      hide: !colVisibilityModel.ip,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          <a href={"https://" + params.value} target="_blank">
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "status",
      headerName: "LED Status",
      width: colWidths.status,
      maxWidth: 400,
      hide: !colVisibilityModel.status,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "mode",
      headerName: "Mode",
      width: colWidths.mode,
      maxWidth: 400,
      hide: !colVisibilityModel.mode,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "model",
      headerName: "Model",
      width: colWidths.model,
      maxWidth: 400,
      hide: !colVisibilityModel.model,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "firmware",
      headerName: "Version",
      width: colWidths.firmware,
      maxWidth: 400,
      hide: !colVisibilityModel.firmware,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "uptime",
      headerName: "UpTime",
      width: colWidths.uptime,
      maxWidth: 400,
      hide: !colVisibilityModel.uptime,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "lastJobOp",
      headerName: "Last Job Op",
      width: colWidths.lastJobOp,
      maxWidth: 400,
      hide: !colVisibilityModel.lastJobOp,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "lastJobStatus",
      headerName: "Last Job Status",
      width: colWidths.lastJobStatus,
      maxWidth: 400,
      hide: !colVisibilityModel.lastJobStatus,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "recentJobs",
      headerName: "Recent Jobs",
      width: colWidths.recentJobs,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.recentJobs,
      sortable: false,
    },
    {
      field: "totalTHs",
      headerName: "TH/S",
      width: colWidths.totalTHs,
      maxWidth: 400,
      hide: !colVisibilityModel.totalTHs,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "joules",
      headerName: "J/THs",
      width: colWidths.joules,
      maxWidth: 400,
      hide: !colVisibilityModel.joules,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "power",
      headerName: "Power(W)",
      width: colWidths.power,
      maxWidth: 400,
      hide: !colVisibilityModel.power,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "remoteTuning",
      headerName: "Tune Config Used",
      width: colWidths.remoteTuning,
      maxWidth: 400,
      hide: !colVisibilityModel.remoteTuning,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "TempStats",
      headerName: "Chip °C",
      width: colWidths.TempStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.TempStats,
      sortable: false,
    },
    {
      field: "FreqStats",
      headerName: "FrequencyStats",
      width: colWidths.FreqStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.FreqStats,
      sortable: false,
    },
    {
      field: "VoltageStats",
      headerName: "VoltageStats",
      width: colWidths.VoltageStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.VoltageStats,
      sortable: false,
    },
    {
      field: "temp",
      headerName: "Board °C",
      width: colWidths.temp,
      maxWidth: 400,
      hide: !colVisibilityModel.temp,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            color: parseInt(params.value) > 50 ? "red" : "inherit"
          }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "alertConfig",
      headerName: "AlertConfig",
      width: colWidths.alertConfig,
      maxWidth: 400,
      hide: !colVisibilityModel.alertConfig,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value !== undefined &&
            params.value !== null &&
            Object.entries(params.value).map(([key, value]) => (
              <div key={key} style={{ display: "inline-block", margin: "2px" }}>
                {!(key === "chipThreshold" && value === null) && (
                  <div
                    style={{
                      minWidth: key === "chipThreshold" ? "30px" : "24px",
                      height: "24px",
                      borderRadius: "50%",
                      backgroundColor:
                        value === true
                          ? "green"
                          : value === false
                          ? "#FF6347"
                          : "blue",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                    title={
                      key === "chipThreshold"
                        ? key.charAt(0).toUpperCase() +
                          key.slice(1) +
                          ":" +
                          value
                        : key.charAt(0).toUpperCase() + key.slice(1)
                    }
                  >
                    {key === "chipThreshold"
                      ? value
                      : key.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>
            ))}
        </div>
      ),
    },
    {
      field: "accepted",
      headerName: "Accepted",
      width: colWidths.accepted,
      maxWidth: 400,
      hide: !colVisibilityModel.accepted,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "rejected",
      headerName: "Rejected",
      width: colWidths.rejected,
      maxWidth: 400,
      hide: !colVisibilityModel.rejected,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "elapsed",
      headerName: "Elapsed",
      width: colWidths.elapsed,
      maxWidth: 400,
      hide: !colVisibilityModel.elapsed,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "fan",
      headerName: "Fan Speed",
      width: colWidths.fan,
      maxWidth: 400,
      hide: !colVisibilityModel.fan,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "psu",
      headerName: "PSU",
      width: colWidths.psu,
      maxWidth: 400,
      hide: !colVisibilityModel.psu,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "ipreport",
      headerName: "IP Report",
      width: colWidths.ipreport,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.ipreport,
      sortable: false,
    },
    {
      field: "summary",
      headerName: "Summary",
      width: colWidths.summary,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.summary,
      sortable: false,
    },
    {
      field: "PSUStats",
      headerName: "PSUStats",
      width: colWidths.PSUStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.PSUStats,
      sortable: false,
    },
    {
      field: "LEDStats",
      headerName: "LEDStats",
      width: colWidths.LEDStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.LEDStats,
      sortable: false,
    },
    {
      field: "FanStats",
      headerName: "FanStats",
      width: colWidths.FanStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.FanStats,
      sortable: false,
    },
    {
      field: "lcd",
      headerName: "LCD",
      width: colWidths.lcd,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.lcd,
      sortable: false,
    },
    {
      field: "ModeStats",
      headerName: "ModeStats",
      width: colWidths.ModeStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.ModeStats,
      sortable: false,
    },
    {
      field: "mac",
      headerName: "MAC address",
      width: colWidths.mac,
      maxWidth: 400,
      hide: !colVisibilityModel.mac,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "hostname",
      headerName: "Hostname",
      width: colWidths.hostname,
      maxWidth: 400,
      hide: !colVisibilityModel.hostname,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "pool1",
      headerName: "Pool1",
      width: colWidths.pool1,
      maxWidth: 400,
      hide: !colVisibilityModel.pool1,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "worker1",
      headerName: "Worker1",
      width: colWidths.worker1,
      maxWidth: 400,
      hide: !colVisibilityModel.worker1,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "pool2",
      headerName: "Pool2",
      width: colWidths.pool2,
      maxWidth: 400,
      hide: !colVisibilityModel.pool2,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "worker2",
      headerName: "Worker2",
      width: colWidths.worker2,
      maxWidth: 400,
      hide: !colVisibilityModel.worker2,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "pool3",
      headerName: "Pool3",
      width: colWidths.pool3,
      maxWidth: 400,
      hide: !colVisibilityModel.pool3,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "worker3",
      headerName: "Worker3",
      width: colWidths.worker3,
      maxWidth: 400,
      hide: !colVisibilityModel.worker3,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "pools",
      headerName: "Pools",
      width: colWidths.pools,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.pools,
      sortable: false,
    },
    {
      field: "devs",
      headerName: "Devs",
      width: colWidths.devs,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.devs,
      sortable: false,
    },
    {
      field: "config",
      headerName: "Config",
      width: colWidths.config,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.config,
      sortable: false,
    },
    {
      field: "coin",
      headerName: "Coin",
      width: colWidths.coin,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.coin,
      sortable: false,
    },
    {
      field: "devdetails",
      headerName: "Dev Details",
      width: colWidths.devdetails,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.devdetails,
      sortable: false,
    },
    {
      field: "stats",
      headerName: "Stats",
      width: colWidths.stats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModel.stats,
      sortable: false,
    },
    {
      field: "wend",
      headerName: "Warranty End",
      width: colWidthsCS.wend,
      maxWidth: 400,
      hide:!colVisibilityModelCS.wend,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    }
  ];

  let initialTechSupportcolumns = [
    {
      field: "csTag",
      headerName: "CSTag",
      width: colWidthsCS.csTag,
      maxWidth: 650,
      hide: !colVisibilityModelCS.csTag,
      renderCell: (params) => (
        <TextField
          id="standard-basic"
          sx={{
            marginTop: -2.15,
            marginLeft: -2.35,
            width: 300,
            "& fieldset": { border: "none" },
          }}
          defaultValue={params.value}
          onChange={(event) => handleCSTagChange(event, params.id)}
          inputProps={{
            maxLength: 32,
            sx: {
              color: "black",
              fontSize: "13.5px",
              "&::placeholder": {
                color: "gray",
                opacity: 0.75,
                fontStyle: "italic",
                fontSize: "13px",
              },
            },
          }}
          placeholder="Tag your miner"
        />
      ),
    },
    {
      field: "org_name",
      headerName: "Org Name",
      width: colWidthsCS.org_name,
      maxWidth: 400,
      hide: !colVisibilityModelCS.org_name,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "chassis",
      headerName: "Chassis SN",
      width: colWidthsCS.chassis,
      maxWidth: 400,
      hide: !colVisibilityModelCS.chassis,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "state",
      headerName: "State",
      width: colWidthsCS.state,
      maxWidth: 400,
      hide: !colVisibilityModelCS.state,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "LED Status",
      width: colWidthsCS.status,
      maxWidth: 400,
      hide: !colVisibilityModelCS.status,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "mode",
      headerName: "Mode",
      width: colWidthsCS.mode,
      maxWidth: 400,
      hide: !colVisibilityModelCS.mode,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "model",
      headerName: "Model",
      width: colWidthsCS.model,
      maxWidth: 400,
      hide: !colVisibilityModelCS.model,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "firmware",
      headerName: "Version",
      width: colWidthsCS.firmware,
      maxWidth: 400,
      hide: !colVisibilityModelCS.firmware,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "ip",
      headerName: "IP address",
      width: colWidthsCS.ip,
      maxWidth: 400,
      hide: !colVisibilityModelCS.ip,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          <a href={"https://" + params.value} target="_blank">
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "uptime",
      headerName: "UpTime",
      width: colWidthsCS.uptime,
      maxWidth: 400,
      hide: !colVisibilityModelCS.uptime,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "lastJobOp",
      headerName: "Last Job Op",
      width: colWidthsCS.lastJobOp,
      maxWidth: 400,
      hide: !colVisibilityModelCS.lastJobOp,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "lastJobStatus",
      headerName: "Last Job Status",
      width: colWidthsCS.lastJobStatus,
      maxWidth: 400,
      hide: !colVisibilityModelCS.lastJobStatus,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "recentJobs",
      headerName: "Recent Jobs",
      width: colWidthsCS.recentJobs,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.recentJobs,
      sortable: false,
    },
    {
      field: "totalTHs",
      headerName: "TH/S",
      width: colWidthsCS.totalTHs,
      maxWidth: 400,
      hide: !colVisibilityModelCS.totalTHs,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "joules",
      headerName: "J/THs",
      width: colWidthsCS.joules,
      maxWidth: 400,
      hide: !colVisibilityModelCS.joules,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "power",
      headerName: "Power(W)",
      width: colWidthsCS.power,
      maxWidth: 400,
      hide: !colVisibilityModelCS.power,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "temp",
      headerName: "Board °C",
      width: colWidthsCS.temp,
      maxWidth: 400,
      hide: !colVisibilityModelCS.temp,
      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
            color: parseInt(params.value) > 50 ? "red" : "inherit"
          }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "summary",
      headerName: "Summary",
      width: colWidthsCS.summary,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.summary,
      sortable: false,
    },
    {
      field: "FanStats",
      headerName: "FanStats",
      width: colWidthsCS.FanStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.FanStats,
      sortable: false,
    },
    {
      field: "TempStats",
      headerName: "Chip °C",
      width: colWidthsCS.TempStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.TempStats,
      sortable: false,
    },
    {
      field: "FreqStats",
      headerName: "FrequencyStats",
      width: colWidthsCS.FreqStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.FreqStats,
      sortable: false,
    },
    {
      field: "VoltageStats",
      headerName: "VoltageStats",
      width: colWidthsCS.VoltageStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.VoltageStats,
      sortable: false,
    },
    {
      field: "remoteTuning",
      headerName: "Tune Config Used",
      width: colWidthsCS.remoteTuning,
      maxWidth: 400,
      hide: !colVisibilityModelCS.remoteTuning,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "dgname",
      headerName: "Group Name",
      width: colWidthsCS.dgname,
      maxWidth: 400,
      hide: !colVisibilityModelCS.dgname,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "customName",
      headerName: "Tag",
      width: colWidthsCS.customName,
      maxWidth: 400,
      hide: !colVisibilityModelCS.customName,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "mac",
      headerName: "MAC address",
      width: colWidthsCS.mac,
      maxWidth: 400,
      hide: !colVisibilityModelCS.mac,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "hostname",
      headerName: "Hostname",
      width: colWidthsCS.hostname,
      maxWidth: 400,
      hide: !colVisibilityModelCS.hostname,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "serial",
      headerName: "CB SN",
      width: colWidthsCS.serial,
      maxWidth: 400,
      hide: !colVisibilityModelCS.serial,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "alertConfig",
      headerName: "AlertConfig",
      width: colWidthsCS.alertConfig,
      maxWidth: 400,

      hide: !colVisibilityModelCS.alertConfig,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value !== undefined &&
            params.value !== null &&
            Object.entries(params.value).map(([key, value]) => (
              <div key={key} style={{ display: "inline-block", margin: "2px" }}>
                {!(key === "chipThreshold" && value === null) && (
                  <div
                    style={{
                      minWidth: key === "chipThreshold" ? "30px" : "24px",
                      height: "24px",
                      borderRadius: "50%",
                      backgroundColor:
                        value === true
                          ? "green"
                          : value === false
                          ? "#FF6347"
                          : "blue",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "12px",
                    }}
                    title={
                      key === "chipThreshold"
                        ? key.charAt(0).toUpperCase() +
                          key.slice(1) +
                          ":" +
                          value
                        : key.charAt(0).toUpperCase() + key.slice(1)
                    }
                  >
                    {key === "chipThreshold"
                      ? value
                      : key.charAt(0).toUpperCase()}
                  </div>
                )}
              </div>
            ))}
        </div>
      ),
    },
    {
      field: "accepted",
      headerName: "Accepted",
      width: colWidthsCS.accepted,
      maxWidth: 400,
      hide: !colVisibilityModelCS.accepted,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "rejected",
      headerName: "Rejected",
      width: colWidthsCS.rejected,
      maxWidth: 400,
      hide: !colVisibilityModelCS.rejected,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "elapsed",
      headerName: "Elapsed",
      width: colWidthsCS.elapsed,
      maxWidth: 400,
      hide: !colVisibilityModelCS.elapsed,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "fan",
      headerName: "Fan Speed",
      width: colWidthsCS.fan,
      maxWidth: 400,
      hide: !colVisibilityModelCS.fan,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "psu",
      headerName: "PSU",
      width: colWidthsCS.psu,
      maxWidth: 400,
      hide: !colVisibilityModelCS.psu,
      renderCell: (params) => (
        <div style={{ whiteSpace: "normal", wordWrap: "break-word" }}>
          {params.value}
        </div>
      ),
    },
    {
      field: "ModeStats",
      headerName: "ModeStats",
      width: colWidthsCS.ModeStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.ModeStats,
      sortable: false,
    },
    {
      field: "PSUStats",
      headerName: "PSUStats",
      width: colWidthsCS.PSUStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.PSUStats,
      sortable: false,
    },
    {
      field: "LEDStats",
      headerName: "LEDStats",
      width: colWidthsCS.LEDStats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.LEDStats,
      sortable: false,
    },
    {
      field: "pools",
      headerName: "Pools",
      width: colWidthsCS.pools,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.pools,
      sortable: false,
    },
    {
      field: "devs",
      headerName: "Devs",
      width: colWidthsCS.devs,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.devs,
      sortable: false,
    },
    {
      field: "config",
      headerName: "Config",
      width: colWidthsCS.config,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.config,
      sortable: false,
    },
    {
      field: "coin",
      headerName: "Coin",
      width: colWidthsCS.coin,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.coin,
      sortable: false,
    },
    {
      field: "devdetails",
      headerName: "Dev Details",
      width: colWidthsCS.devdetails,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.devdetails,
      sortable: false,
    },
    {
      field: "stats",
      headerName: "Stats",
      width: colWidthsCS.stats,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.stats,
      sortable: false,
    },
    {
      field: "lcd",
      headerName: "LCD",
      width: colWidthsCS.lcd,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.lcd,
      sortable: false,
    },
    {
      field: "ipreport",
      headerName: "IP Report",
      width: colWidthsCS.ipreport,
      maxWidth: 400,
      filterable: false,
      renderCell: renderJSONPretty,
      hide: !colVisibilityModelCS.ipreport,
      sortable: false,
    },
    {
      field: "pool1",
      headerName: "Pool1",
      width: colWidthsCS.pool1,
      maxWidth: 400,
      hide: !colVisibilityModelCS.pool1,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "worker1",
      headerName: "Worker1",
      width: colWidthsCS.worker1,
      maxWidth: 400,
      hide: !colVisibilityModelCS.worker1,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "pool2",
      headerName: "Pool2",
      width: colWidthsCS.pool2,
      maxWidth: 400,
      hide: !colVisibilityModelCS.pool2,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "worker2",
      headerName: "Worker2",
      width: colWidthsCS.worker2,
      maxWidth: 400,
      hide: !colVisibilityModelCS.worker2,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "pool3",
      headerName: "Pool3",
      width: colWidthsCS.pool3,
      maxWidth: 400,
      hide: !colVisibilityModelCS.pool3,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "worker3",
      headerName: "Worker3",
      width: colWidthsCS.worker3,
      maxWidth: 400,
      hide: !colVisibilityModelCS.worker3,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "wend",
      headerName: "Warranty End",
      width: colWidthsCS.wend,
      maxWidth: 400,
      hide:!colVisibilityModelCS.wend,
      renderCell: (params) => (
        <div
          style={{ whiteSpace: "normal", wordWrap: "break-word" }}
          title={params.value}
        >
          {params.value}
        </div>
      ),
    }
    // { field: 'version', headerName: 'Version', minWidth: 180,  filterable: false, renderCell: renderJSONPretty, hide: false },
    // { field: 'bgGreen', headerName: 'green', minWidth: 100,  hide: true },
    // { field: 'bgRed', headerName: 'red', minWidth: 100,  hide: true },
  ];

  const [TechSupportcolumns, setTechSupportcolumns] = React.useState(
    initialTechSupportcolumns
  );
  const [columns, setColumns] = React.useState(initialMinerStatusColumns);

  const handleCSTagChange = (event, rowId) => {
    const pattern = /^[a-zA-Z0-9-.]*$/;
    let value = event.target.value;
    const filteredValue = value
      .split("")
      .filter((char) => pattern.test(char))
      .join("");
    event.target.value = filteredValue;
    value = event.target.value;
    const updatedRows = [...rows];
    const rowIndex = updatedRows.findIndex((row) => row.serial === rowId);
    updatedRows[rowIndex] = {
      ...updatedRows[rowIndex],
      csTag: value,
    };
    setRows(updatedRows);
  };

  //open rearrange columns
  function handleClickOpenSettings() {
    setOpenColPopup(true);
  }

  //close rearrange columns (click anywhere on screen)
  function handleCloseSettings() {
    if (b_techsupport === true) {
      console.log("close cs");
      const reorderColumnsCS = colOrderCS.reduce((object, item, index) => {
        return { ...object, [item]: index };
      }, {});
      let orderedcolumns = TechSupportcolumns.sort(
        (a, b) =>
          reorderColumnsCS[a.headerName] - reorderColumnsCS[b.headerName]
      );
      setColWidthsCS(colWidthsCS);
      setColVisibilityModelCS(colVisibilityModelCS);
      setTechSupportcolumns(orderedcolumns);
      const headerNames = orderedcolumns.map((column) => column.headerName);
      setColOrderCS(headerNames);
      localStorage.setItem(
        "MinerTechSupportColumns",
        JSON.stringify(orderedcolumns)
      );
    } else {
      console.log("close");
      const reorderColumns = colOrder.reduce((object, item, index) => {
        return { ...object, [item]: index };
      }, {});
      let orderedcolumns = columns.sort(
        (a, b) => reorderColumns[a.headerName] - reorderColumns[b.headerName]
      );
      setColWidths(colWidths);
      setColVisibilityModel(colVisibilityModel);
      setColumns(orderedcolumns);
      const headerNames = orderedcolumns.map((column) => column.headerName);
      setColOrder(headerNames);
      localStorage.setItem(
        "MinerStatusColumns",
        JSON.stringify(orderedcolumns)
      );
    }
    setOpenColPopup(false);
  }

  //cancel rearrange columns (set col order to previous col order)
  function handleCancel() {
    if (b_techsupport === true) {
      const headerNames = TechSupportcolumns.map((column) => column.headerName);
      setColOrderCS(headerNames);
    } else {
      const headerNames = columns.map((column) => column.headerName);
      setColOrder(headerNames);
    }
    setOpenColPopup(false);
  }

  //cancel rearrange columns (set col order to previous col order)
  const handleResetDBPreferences = async (event) => {
    const token = await getAccessTokenSilently();
    const userPreference = [];
    const resp = await api.PostUserPreference(token, userPreference);
    if (resp === undefined) {
      console.log("Failed to reset the user preference");
    } else if (resp.status === "ok") {
      console.log("Succeeded in resetting the user preference ");
      if (b_techsupport === true) {
        setColWidthsCS(initialColWidthsCS);
        setColVisibilityModelCS(initialColVisibilityCS);
        setFilterModelCS({ items: [] });
        setSortModelCS([]);
        setTechSupportcolumns(initialTechSupportcolumns);
        const headerNames = initialTechSupportcolumns.map(
          (column) => column.headerName
        );
        setColOrderCS(headerNames);
        localStorage.setItem(
          "MinerTechSupportColumns",
          JSON.stringify(initialTechSupportcolumns)
        );
      } else {
        setColWidths(initialColWidths);
        setColVisibilityModel(initialColVisibility);
        setFilterModel({ items: [] });
        setSortModel([]);
        setColumns(initialMinerStatusColumns);
        const headerNames = initialMinerStatusColumns.map(
          (column) => column.headerName
        );
        setColOrder(headerNames);
        localStorage.setItem(
          "MinerStatusColumns",
          JSON.stringify(initialMinerStatusColumns)
        );
      }
    } else {
      console.log("Failed to reset the user preference");
    }
    setOpenColPopup(false);
  };

  const handleClearSelectedMiners = async (event) => {
    setSelectionModel([]);
    globalSelectionModel = [];
    globalNotSelectionModel = [];
    setStopRefresh(false);
    setSelectAllMiners(false);
  };

  const handleSelectAllMiners = async (event) => {
    setSelectionModel(rows.map((row) => row.serial));
    globalNotSelectionModel = [];
    setStopRefresh(true);
    setSelectAllMiners(true);
  };

  const handleShowTransferJobs = async (event) => {
    const token = await getAccessTokenSilently();
    await api.GetOrg(token, (data) => {
      setTransferMiners([])
      setShowTransferJobs(false)
      if (Object.hasOwnProperty.call(data, 'transfer') && data.transfer.length > 0) {
        const transferMiners = data.transfer;
        const matchingJobs = transferMiners.filter(item => item.target_org_id === data.org_id);
        setTransferMiners(matchingJobs);
        if (matchingJobs.length > 0) {
          setShowTransferJobs(true)
        }
      }
    });
    setMinerTransfer(true);
  };

  const [minerRemoveOperationConfirmationMessage, setMinerRemoveOperationConfirmationMessage] = React.useState("");
  const [openMinerRemoveOperationConfirmation, setOpenMinerRemoveOperationConfirmation] = React.useState(false);

  function handleCloseMinerRemoveOperationConfirmation() {
    setOpenMinerRemoveOperationConfirmation(false);
  }

  const handleRemoveMiners = async (event) => {
    setMinerRemoveOperation(true);
    setOpenMinerRemoveOperationConfirmation(false);
  };

  const exportToCsv = async (event) => {
    setCsvOpen(true);
  };

  const handleCsvClose = async (event) => {
    setCsvOpen(false);
  }

  const handleMinerTransferClose = async (event) => {
    setMinerTransfer(false);
    setTransferMinerConfirmationMessage("")
  }

  const handleCsvChange = async (values) => {
    setCsvDGs(values);
  }

  const handleClearCsv = async (event) => {
    if (multiSelectRef.current) {
      multiSelectRef.current.clearSelections(); // Call clear function in child
    }
  }

  const handleExportCsv = async (event) => {
    const token = await getAccessTokenSilently();
    const exportData = {};
    // console.log(csvDGs);
    exportData.dglist = csvDGs.filter(item => item !== "Select All");
    // console.log(exportData.dglist);
    const resp = await api.PostExport(token, exportData);
    if (resp === undefined) {
      console.log("Failed to export miner operations to csv");
    } else if (resp.status === "ok") {
      console.log("Succeeded in exporting miner operations to csv");
    } else {
      console.log("Failed to export miner operations to csv");
    }
  }

  const handleMinerRemoveOperationYES = async (event) => {
    event.preventDefault();
    let tmpMinerGroup = minerGroup;
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let data = {};
      data.serials = selectionModel;
      data.dgname = tmpMinerGroup
      setStopRefresh(true)
      const resp = await api.PostMinerRemoveOperation(token, data);
      if (resp === undefined || resp === null) {
        setMinerRemoveOperationConfirmationMessage(
          `Error in removing miners from ${minerGroup}`
        );
        setOpenMinerRemoveOperationConfirmation(true);
        console.log(
          `Failed to remove ${selectionModel} miners from ${minerGroup}`
        );
      } else if (resp.status === "ok") {
        let msg =
          `Successfully removed miners from ${minerGroup}. ` +
          resp.msg;
        setMinerRemoveOperationConfirmationMessage(msg);
        setOpenMinerRemoveOperationConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for removal operation ${selectionModel} in ${minerGroup}`
        );
        let msg = `Failed to remove miners from ${minerGroup} ` + resp.msg;
        setMinerRemoveOperationConfirmationMessage(msg);
        setOpenMinerRemoveOperationConfirmation(true);
      }
    }
  }

  const handleMinerRemoveOperationNO = async (event) => {
    event.preventDefault();
    setMinerRemoveOperation(false);
    setMinerRemoveOperationConfirmationMessage("")
    setOpenMinerRemoveOperationConfirmation(false);

    setSelectionModel([]);
    globalSelectionModel = [];
    globalNotSelectionModel = [];
    setStopRefresh(false);
  }

  const handleAcceptMinerTransfer = async (event) => {
    event.preventDefault();
    const token = await getAccessTokenSilently();
    let dgdata = {};
    console.log("selectedJob", selectedJob);
    dgdata.jobid = selectedJob;
    const resp = await api.AcceptTransferMiners(token, dgdata);
    if (resp === undefined || resp === null) {
      setTransferMinerConfirmationMessage(
        `Error in accepting transfer miners`
      );
      setOpenTransferMinersConfirmation(true);
      console.log(
        `Failed to accept transfer miners`
      );
    } else if (resp.status === "ok") {
      let msg = resp.msg
      setTransferMinerConfirmationMessage(msg);
      setOpenTransferMinersConfirmation(true);
      const updatedMiners = transferMiners.filter(item => item.jobid !== selectedJob);
      setTransferMiners(updatedMiners);
    } else if (resp.status === "error") {
      console.log(
        `status: error from accept operation`
      );
      let msg = `Failed to accept miners ` + resp.msg;
      setTransferMinerConfirmationMessage(msg);
      setOpenTransferMinersConfirmation(true);
    }
  }

  const handleRejectMinerTransfer = async (event) => {
    event.preventDefault();
    const token = await getAccessTokenSilently();
    let dgdata = {};
    console.log("selectedJob", selectedJob);
    dgdata.jobid = selectedJob;
    const resp = await api.RejectTransferMiners(token, dgdata);
    if (resp === undefined || resp === null) {
      setTransferMinerConfirmationMessage(
        `Error in rejecting transfer miners`
      );
      setOpenTransferMinersConfirmation(true);
      console.log(
        `Failed to reject transfer miners`
      );
    } else if (resp.status === "ok") {
      let msg = resp.msg
      setTransferMinerConfirmationMessage(msg);
      setOpenTransferMinersConfirmation(true);
      const updatedMiners = transferMiners.filter(item => item.jobid !== selectedJob);
      setTransferMiners(updatedMiners);
    } else if (resp.status === "error") {
      console.log(
        `status: error from reject operation`
      );
      let msg = `Failed to reject miners ` + resp.msg;
      setTransferMinerConfirmationMessage(msg);
      setOpenTransferMinersConfirmation(true);
    }
  }

  //set newcols as the tempnewcols to render new grid with new col order
  const handleSaveColumnSettingsCS = async (event) => {
    const reorderColumnsCS = colOrderCS.reduce((object, item, index) => {
      return { ...object, [item]: index };
    }, {});
    let orderedcolumns = TechSupportcolumns.sort(
      (a, b) => reorderColumnsCS[a.headerName] - reorderColumnsCS[b.headerName]
    );
    setColWidthsCS(colWidthsCS);
    setColVisibilityModelCS(colVisibilityModelCS);
    setTechSupportcolumns(orderedcolumns);
    const headerNames = orderedcolumns.map((column) => column.headerName);
    setColOrderCS(headerNames);
    localStorage.setItem(
      "MinerTechSupportColumns",
      JSON.stringify(orderedcolumns)
    );

    // event.preventDefault();
    const token = await getAccessTokenSilently();
    const userPreference = orderedcolumns.map(
      ({ renderCell, ...rest }) => rest
    );
    const resp = await api.PostUserPreference(token, userPreference);
    if (resp === undefined) {
      console.log("Failed to set the user preference");
    } else if (resp.status === "ok") {
      console.log("Succeeded in setting the user preference ");
      if (b_techsupport === true) {
        localStorage.setItem(
          "MinerTechSupportColumns",
          JSON.stringify(userPreference)
        );
      }
    } else {
      console.log("Failed to set the user preference");
    }
    setOpenColPopup(false);
  };

  const handleSaveColumnSettings = async (event) => {
    const reorderColumns = colOrder.reduce((object, item, index) => {
      return { ...object, [item]: index };
    }, {});
    let orderedcolumns = columns.sort(
      (a, b) => reorderColumns[a.headerName] - reorderColumns[b.headerName]
    );
    setColWidths(colWidths);
    setColVisibilityModel(colVisibilityModel);
    setColumns(orderedcolumns);
    const headerNames = orderedcolumns.map((column) => column.headerName);
    setColOrder(headerNames);
    localStorage.setItem("MinerStatusColumns", JSON.stringify(orderedcolumns));

    const token = await getAccessTokenSilently();
    const userPreference = orderedcolumns.map(
      ({ renderCell, ...rest }) => rest
    );
    const resp = await api.PostUserPreference(token, userPreference);
    if (resp === undefined) {
      console.log("Failed to set the user preference");
    } else if (resp.status === "ok") {
      console.log("Succeeded in setting the user preference ");
      if (b_techsupport === false) {
        localStorage.setItem(
          "MinerStatusColumns",
          JSON.stringify(userPreference)
        );
      }
    } else {
      console.log("Failed to set the user preference");
    }
    setOpenColPopup(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let minernames = [];
    for (let i = 0; i < rows.length; i++) {
      minernames.push({
        SerialNo: rows[i].serial,
        CustomName: rows[i].customName,
      });
    }
    try {
      const token = await getAccessTokenSilently();
      //console.log(minernames)

      const response = await fetch(env.APIPath.addCustomNames, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          minernames: minernames,
        }),
      });
      let data = await response.json();
      setSaveConfirmation(true);
      if (data.status === "ok") {
        setConfMessage("Successfully updated the miner names");
      } else {
        setConfMessage("Failed to update the miner names");
      }
    } catch (error) {
      setSaveConfirmation(true);
      setConfMessage("Failed to updated the miner names");
      console.log(error.message);
    }
  };

  const handleCSTagSubmit = async (event) => {
    event.preventDefault();
    let minernames = [];
    for (let i = 0; i < rows.length; i++) {
      minernames.push({ SerialNo: rows[i].serial, CSTag: rows[i].csTag });
    }
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(env.APIPath.addCSTags, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          minernames: minernames,
        }),
      });
      let data = await response.json();
      setSaveConfirmation(true);
      if (data.status === "ok") {
        setConfMessage("Successfully updated the CS Tag Names");
      } else {
        setConfMessage("Failed to update the CS Tag Names");
      }
    } catch (error) {
      setSaveConfirmation(true);
      setConfMessage("Failed to updated the CS Tag Names");
    }
  };

  const history = useHistory();
  const location = useLocation();

  const [searchStrings, setSearchStrings] = React.useState({
    led: "",
    state: "",
    power: "",
    ipAddress: "",
    ths: "",
    jths: "",
    dgname: "",
    temperature: "",
    org_name: "",
    version: "",
    model: "",
    name: "",
    csTag: "",
    chassis: "",
    CBSN: "",
  });

  const [previoussearchStrings, setPreviousSearchStrings] = React.useState({
    led: "",
    state: "",
    power: "",
    ipAddress: "",
    ths: "",
    jths: "",
    dgname: "",
    temperature: "",
    org_name: "",
    version: "",
    model: "",
    name: "",
    csTag: "",
    chassis: "",
    CBSN: "",
  });

  const setpreviousSearchStrings = async (searchStrings) => {
    setPreviousSearchStrings(searchStrings);
  };

  // Usage:
  // Call setPreviousSearchStrings whenever you want to update previousSearchStrings

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  // Function to handle interval changes
  const handleInterval = (val) => {
    //console.log('set interval', val)
    localStorage.setItem("dl-refreshInterval", val);
    setTimegap(val);
    setStopRefresh(false);
    setSelectionModel([]);
    globalSelectionModel = [];
    globalNotSelectionModel = [];
    console.log("stopped refresh upon refresh is set to false")
  };

  const { getAccessTokenSilently } = useAuth0();

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 50,
  });

  const PostDevice = async (page) => {
    let r = {
      count: 0,
      docs: [],
    };

    // console.log('PostDevice', page, paginationModel.pageSize, searchStrings)
    try {
      const token = await getAccessTokenSilently();
      let response = await fetch(env.APIPath.device, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          device: {
            limit: paginationModel.pageSize,
            skip: paginationModel.pageSize * page,
          },
          filter: searchStrings, // AND filter for now
          pass: 2, // Get the pass 2 data
        }),
      });
      let data = await response.json();
      if (data.status === "ok") {
        validUser = 0;
        setSubmitMsg("ok");
        setSuccessModalOpen(true);
        if (data.count > 0) {
          data.docs.forEach((el) => {
            let dgname = "";
            if (el.DGName) {
              dgname = el.DGName;
            }
            const doc = {
              serial: el.SerialNo ?? "",
              org_name: el.org_name ?? "",
              dgname: dgname,
              customName: el.CustomName ?? "",
              csTag: el.CSTag ?? "",
              uptime: "",
              firmware: "",
              lastJobOp: "",
              lastJobStatus: "",
              recentJobs: "",
              state: el.State ?? "Onboarded",
            };
            if (
              el.ipreport &&
              (el.ipreport.IPReport === undefined ||
                el.ipreport.IPReport === null)
            ) {
              doc.mac = el.ipreport.mac ?? "";
              doc.ip = el.ipreport.ip ?? "";
              doc.model = el.ipreport.model ?? "";
              doc.hostname = el.ipreport.hostname ?? "";
              doc.firmware = el.ipreport.version ?? "";
              doc.ipreport = el.ipreport;
              doc.chassis = el.ipreport.ChassisSerialNo ?? "";
            } else if (el.ipreport) {
              doc.ipreport = el.ipreport;
              if (el.ipreport.IPReport && el.ipreport.IPReport.length > 0) {
                doc.mac = el.ipreport.IPReport[0].mac ?? "";
                doc.ip = el.ipreport.IPReport[0].ip ?? "";
                doc.model = el.ipreport.IPReport[0].model ?? "";
                doc.hostname = el.ipreport.IPReport[0].hostname ?? "";
                doc.firmware = el.ipreport.IPReport[0].version ?? "";
                doc.chassis = el.ipreport.IPReport[0].ChassisSerialNo ?? "";
                if (el.ipreport.IPReport[0].pubKey) {
                  delete el.ipreport.IPReport[0].pubKey;
                }
                if (el.ipreport.IPReport[0].tpmPubKey) {
                  delete el.ipreport.IPReport[0].tpmPubKey;
                }
                if (el.ipreport.IPReport[0].InternalType) {
                  delete el.ipreport.IPReport[0].InternalType;
                }
              } else {
                // console.log('IPReport info is not available for', el.SerialNo, ' ignore it...')
              }
            }
            if (el.Warranty_Start) {
              const { diffTime, timeStr, dateStr } = displayTS(el.Warranty_Start);
              doc.wstart = dateStr;
            }
            if (el.Warranty_End) {
              const { diffTime, timeStr, dateStr } = displayTS(el.Warranty_End);
              doc.wend = dateStr;
            }
            if (el.summary === undefined || el.summary === null) {
              // doc.state = "Onboarded";
              doc.profit = 0.0;
              doc.revenue = 0.0;
              doc.cost = 0.0;
              doc.thsjths = 0.0;
              doc.totalTHs = 0;
              doc.joules = 0;
              doc.power = 0;
              r.docs.push(doc);
              return;
            }
            if (el.summary) {
              doc.summary = el.summary;
              if (el.summary.SUMMARY && el.summary.SUMMARY.length > 0) {
                doc.accepted = el.summary.SUMMARY[0].Accepted;
                doc.rejected = el.summary.SUMMARY[0].Rejected;
                //console.log(el.summary.SUMMARY[0]['MHS 5s'],el.summary.SUMMARY[0].Wattage )
                doc.totalTHs = (
                  el.summary.SUMMARY[0]["MHS 5s"] / 1000000
                ).toFixed(2);
                doc.joules = 0;
                if (
                  el.summary.SUMMARY[0].Wattage &&
                  parseInt(doc.totalTHs) > 0
                ) {
                  doc.joules = el.summary.SUMMARY[0].Wattage / doc.totalTHs;
                  if (doc.joules <= 0) doc.joules = 0;
                  doc.joules = doc.joules.toFixed(2);
                } else {
                  doc.totalTHs = 0;
                }
                doc.power = 0;
                if (el.summary.SUMMARY[0].Wattage) {
                  doc.power = el.summary.SUMMARY[0].Wattage.toFixed(2);
                  if (doc.power <= 0) {
                    doc.power = 0;
                  }
                }
                doc.elapsed = el.summary.SUMMARY[0].Elapsed;
              } else {
                // console.log('Summary info is not available for', el.SerialNo, ' ignore it...')
              }
            }
            if (el.jobResults && el.jobResults.length > 0) {
              doc.lastJobOp = el.jobResults[0].command ?? "";
              if (doc.lastJobOp !== "") {
                doc.lastJobOp = doc.lastJobOp.charAt(0).toUpperCase() + doc.lastJobOp.slice(1);
              }
              doc.lastJobStatus = el.jobResults[0].response ?? "";
              if (doc.lastJobStatus !== "") {
                doc.lastJobStatus = doc.lastJobStatus.charAt(0).toUpperCase() + doc.lastJobStatus.slice(1);
              }
              doc.recentJobs = el.jobResults[0].updatedAt ?? "";
              if (doc.recentJobs !== "") {
                const { diffTime, timeStr } = displayTS(el.jobResults[0].updatedAt);
                doc.recentJobs = el.jobResults;
                for (let i = 0; i < doc.recentJobs.length; i++) {
                  if (doc.recentJobs[i].hasOwnProperty('org_id') &&
                      doc.recentJobs[i].org_id !== null &&
                      doc.recentJobs[i].org_id !== undefined) {
                      delete doc.recentJobs[i].org_id
                  }
                }
              }
            }
            if (el.fan) {
              doc.FanStats = el.fan;
              if (el.fan.Fan) {
                const fanSpeeds = [];
                el.fan.Fan.forEach((el2) => {
                  fanSpeeds.push(el2.Speed.toString());
                });
                doc.fan = fanSpeeds.toString();
              } else {
                // console.log('Fan info is not available for', el.SerialNo, ' ignore it...')
              }
            }
            doc.remoteTuning = "";
            if (
              el["accept-remote-tuning"] &&
              el["accept-remote-tuning"]["accept-remote-tuning"] &&
              el["accept-remote-tuning"]["accept-remote-tuning"][0] &&
              el["accept-remote-tuning"]["accept-remote-tuning"][0].parameter
            ) {
              let rt =
                el["accept-remote-tuning"][
                  "accept-remote-tuning"
                ][0].parameter.toUpperCase();
              if (rt === "OFF") {
                doc.remoteTuning = "Miner";
              } else {
                doc.remoteTuning = "FluxVision";
              }
            } else {
              // console.log('Remote tuning info is not available for', el.SerialNo, ' ignore it...')
            }
            if (el.temperature) {
              doc.TempStats = el.temperature;
              el.temperature.model = doc.model;
              el.temperature.chassis = doc.chassis;
              if (el.temperature.Temperature) {
                const temp = [];
                el.temperature.Temperature.forEach((board) => {
                  const boardTemp = [];
                  if (
                    !(board.BoardTemp === undefined || board.BoardTemp === null)
                  ) {
                    board.BoardTemp.forEach((el2) => {
                      let t = el2.Temperature.toFixed(1);
                      boardTemp.push(t.toString());
                    });
                    if (board.Name === "Control Board") {
                      temp.push(boardTemp.toString());
                    }
                  }
                });
                doc.temp = temp.toString();
              } else {
                // console.log('Temperature info is not available for', el.SerialNo, ' ignore it...')
              }
            }
            doc.alertConfig = {};
            if (el.alertNotificationConfig) {
              const sortedKeys = Object.keys(el.alertNotificationConfig).sort();
              const sortedAlertConfig = {};
              sortedKeys.forEach((key) => {
                sortedAlertConfig[key] = el.alertNotificationConfig[key];
              });
              doc.alertConfig = sortedAlertConfig;
            }
            if (
              el.timedate &&
              el.timedate.TimeDate &&
              el.timedate.TimeDate.length > 0
            ) {
              let msg = el.timedate.TimeDate[0].msg;
              const uptimeIndex = msg.indexOf("Uptime: ");
              if (uptimeIndex !== -1) {
                const uptimeSubstring = msg.substring(uptimeIndex);
                const parts = uptimeSubstring.split(":");
                if (parts.length >= 2) {
                  const uptimeValue = parts[1].trim();
                  const uptimeValue2 = uptimeValue.split(".");
                  if (uptimeValue2.length >= 2) {
                    const uptimeValue3 = uptimeValue2[0].trim() + "s";
                    doc.uptime = uptimeValue3;
                  } else {
                    doc.uptime = uptimeValue2;
                  }
                  doc.uptime = doc.uptime.replace(/m.*/, "m");
                  try {
                    doc.uptime = doc.uptime.replace(/m.*/, "m");
                  } catch (e) {
                    doc.uptime = "";
                  }
                  let hrs = 0;
                  const regex = /(\d+)h/;
                  const match = doc.uptime.match(regex);
                  if (match && match[1]) {
                    doc.uptime = doc.uptime.replace(match[0], "");
                    hrs = parseInt(match[1]);
                    const years = Math.floor(hrs / (24 * 365));
                    const months = Math.floor((hrs % (24 * 365)) / (24 * 30));
                    const days = Math.floor(
                      ((hrs % (24 * 365)) % (24 * 30)) / 24
                    );
                    const remainingHours =
                      ((hrs % (24 * 365)) % (24 * 30)) % 24;
                    let result = "";
                    if (years > 0) {
                      result += `${years}y`;
                    }
                    if (months > 0) {
                      result += `${months}mo`;
                    }
                    if (days > 0) {
                      result += `${days}d`;
                    }
                    if (remainingHours > 0) {
                      result += `${remainingHours}h`;
                    }
                    doc.uptime = result + doc.uptime;
                  }
                }
              }
              if (doc.uptime !== "") {
                const regex = /^(\d+y)?(\d+mo)?(\d+d)?(\d+h)?(\d+m)?(\d+s)?$/;
                if (!regex.test(doc.uptime)) {
                  doc.uptime = "";
                }
              }
            } else {
              // console.log('TimeDate info is not available for', el.SerialNo, ' ignore it...')
            }
            if (el.frequency) {
              el.frequency.model = doc.model;
              el.frequency.chassis = doc.chassis;
              doc.FreqStats = el.frequency;
            } else {
              // console.log('Frequency info is not available for', el.SerialNo, ' ignore it...')
            }
            if (el.voltage) {
              el.voltage.model = doc.model;
              el.voltage.chassis = doc.chassis;
              doc.VoltageStats = el.voltage;
            } else {
              // console.log('Voltage info is not available for', el.SerialNo, ' ignore it...')
            }
            let standby = "";
            if (el.mode) {
              doc.ModeStats = el.mode;
              if (el.mode.Mode && el.mode.Mode.length > 0) {
                const sleepStr =
                  el.mode.Mode[0].Sleep === "on" ? "Sleeping" : "Running";
                standby = sleepStr;
                let tmpmode = `${el.mode.Mode[0].Mode}`;
                let modeTgtThs = `${el.mode.Mode[0].Ths}`;
                let modeTgtPower = `${el.mode.Mode[0].Power}`;
                const lowerCaseString = tmpmode.toLowerCase();
                const capitalizedString =
                  lowerCaseString.charAt(0).toUpperCase() +
                  lowerCaseString.slice(1);
                if (capitalizedString !== "") {
                  doc.mode = capitalizedString + ", " + sleepStr;
                } else {
                  doc.mode = sleepStr;
                }
                if (
                  modeTgtThs !== undefined &&
                  modeTgtThs !== null &&
                  modeTgtThs !== "undefined"
                ) {
                  if (doc.mode !== "") {
                    doc.mode = doc.mode + ",Ths " + parseInt(modeTgtThs);
                  } else {
                    doc.mode = "Ths " + parseInt(modeTgtThs);
                  }
                } else if (
                  modeTgtPower !== undefined &&
                  modeTgtPower !== null &&
                  modeTgtPower !== "undefined"
                ) {
                  if (doc.mode !== "") {
                    doc.mode = doc.mode + ",Power " + parseInt(modeTgtPower);
                  } else {
                    doc.mode = "Power " + parseInt(modeTgtPower);
                  }
                }
              } else {
                // console.log('Mode info is not available for', el.SerialNo, ' ignore it...')
              }
            }
            if (el.psu) {
              doc.PSUStats = el.psu;
              if (el.psu.PSU && el.psu.PSU.length > 0) {
                if (el.psu.PSU[0].Voltage && el.psu.PSU[0].Current) {
                  doc.psu = `${el.psu.PSU[0].Voltage}, ${el.psu.PSU[0].Current}`;
                } else if (
                  el.psu.PSU[0].PowerIn !== null &&
                  el.psu.PSU[0].PowerIn !== undefined &&
                  el.psu.PSU[0].PowerIn2 !== null &&
                  el.psu.PSU[0].PowerIn2 !== undefined
                ) {
                  doc.psu = `${el.psu.PSU[0].PowerIn}, ${el.psu.PSU[0].PowerIn2}`;
                } else if (
                  el.psu.PSU[0].PowerIn !== null &&
                  el.psu.PSU[0].PowerIn !== undefined
                ) {
                  doc.psu = `${el.psu.PSU[0].PowerIn}`;
                } else {
                  doc.psu = "";
                }
              } else {
                // console.log('PSU info is not available for', el.SerialNo, ' ignore it...')
              }
            }
            if (el.led) {
              doc.LEDStats = el.led;
              if (el.led.LED && el.led.LED.length > 0) {
                let ledstatus = `${el.led.LED[0].Msg}`;
                const lowerCaseString = ledstatus.toLowerCase();
                const capitalizedString =
                  lowerCaseString.charAt(0).toUpperCase() +
                  lowerCaseString.slice(1);
                doc.status = capitalizedString;
              } else {
                // console.log('LED info is not available for', el.SerialNo, ' ignore it...')
              }
            }
            if (el.summary && el.summary.updatedAt) {
              const { diffTime, timeStr } = displayTS(el.summary.updatedAt);
              //console.log(doc.serial,diffTime)
              // if (diffTime <= 1000 * 60 * 15) {
              //     doc.state = "Connected";
              // } else {
              //   doc.state = "Disconnected";
              // }
            } else {
              // console.log('Summary updated info is not available for', el.SerialNo, ' ignore it...')
            }

            if (el.version) {
              doc.version = el.version;
            }
            if (el.config) {
              doc.config = el.config;
            }
            if (el.devs) {
              doc.devs = el.devs;
            }
            if (el.pools) {
              doc.pools = el.pools;
              if (el.pools.POOLS[0]) {
                doc.pool1 = el.pools.POOLS[0]["URL"] ?? "";
                doc.worker1 = el.pools.POOLS[0]["User"] ?? "";
              }
              if (el.pools.POOLS[1]) {
                doc.pool2 = el.pools.POOLS[1]["URL"] ?? "";
                doc.worker2 = el.pools.POOLS[1]["User"] ?? "";
              }
              if (el.pools.POOLS[2]) {
                doc.pool3 = el.pools.POOLS[2]["URL"] ?? "";
                doc.worker3 = el.pools.POOLS[2]["User"] ?? "";
              }
            }
            if (el.coin) {
              doc.coin = el.coin;
            }
            if (el.devdetails) {
              doc.devdetails = el.devdetails;
            }
            if (el.stats) {
              doc.stats = el.stats;
            }
            if (el.lcd) {
              doc.lcd = el.lcd;
            }
            if (el.updatedAt) {
              const { diffTime, timeStr } = displayTS(el.updatedAt);
              doc.updatedAt = timeStr;
            }
            r.docs.push(doc);
          });
        }
        r.count = data.count;
      } else {
        if (data.errorCode === 1007) {
          validUser = 1;
          setSubmitMsg("nouser");
          setSuccessModalOpen(true);
        } else {
          validUser = 1;
          setSubmitMsg("fetchfailed");
          setSuccessModalOpen(true);
        }
        //console.log(data);
      }
    } catch (error) {
      validUser = 1;
      setSubmitMsg("something");
      setSuccessModalOpen(true);
      console.log(error.message);
    }

    // return docs.slice(0, pageSize)
    return r;
  };

  const isFilterProvided = async (obj) => {
    for (let key in obj) {
      if (obj.hasOwnProperty(key) && obj[key] !== "") {
        return true; // Return true if any value is not empty
      }
    }
    return false; // Return false if all values are empty
  };

  // Effect to retrieve the interval value from localStorage when the component mounts
  React.useEffect(() => {
    // Retrieve the interval value from localStorage
    const savedInterval = localStorage.getItem("dl-refreshInterval");
    // Use the savedInterval value if it exists, otherwise, use the default interval
    const initialInterval = savedInterval
      ? parseInt(savedInterval)
      : DEFAULT_INTERVAL;
    // Set the initial interval in state
    //console.log('retrieved value ', initialInterval)
    setTimegap(initialInterval);
    const storedUrlParams = localStorage.getItem("dl-urlParams");
    const parsedUrlParams = JSON.parse(storedUrlParams);
    const urlParams = new URLSearchParams(location.search);
    if (urlParams && urlParams.toString()) {
      urlParams.forEach((value, key) => {
        searchStrings[key] = value;
      });
    } else if (parsedUrlParams && typeof parsedUrlParams === "object") {
      Object.entries(parsedUrlParams).forEach(([key, value]) => {
        urlParams.set(key, value);
        searchStrings[key] = value;
      });
      localStorage.setItem(
        "dl-urlParams",
        JSON.stringify(Object.fromEntries(urlParams.entries()))
      );
    }
    history.replace({ search: urlParams.toString() });
  }, []);

  // const setUrlParams = React.useCallback(() => {
  //console.log('setUrlParams', searchStrings)
  //   const urlParams = new URLSearchParams(location.search);
  //   Object.entries(searchStrings).forEach(([key, value]) => {
  //     urlParams.set(key, value);
  //   });
  //   localStorage.setItem('dl-urlParams', JSON.stringify(Object.fromEntries(urlParams.entries())));
  //   history.replace({ search: urlParams.toString() });
  //   setSearchStrings(searchStrings)
  // },[history, location.search, searchStrings]);

  React.useEffect(() => {
    let active = true;

    const fn = async () => {
      setLoading(true);
      let localPage = paginationModel.page;
      const areSearchStringsEqual =
        JSON.stringify(searchStrings) === JSON.stringify(previoussearchStrings);
      if ((await isFilterProvided(searchStrings)) && !areSearchStringsEqual) {
        //console.log('filter changed', areSearchStringsEqual, searchStrings, previoussearchStrings)
        localPage = 0;
        setPreviousSearchStrings(searchStrings);
        setPaginationModel({ ...paginationModel, page: 0 });
      }
      //console.log('useEffect', page, timegap, pageSize, searchStrings, previoussearchStrings)

      const { docs: newRows, count: count1 } = await PostDevice(
        localPage
      );

      if (!active) {
        setLoading(false);
        return;
      }

      setRows(newRows);
      if (selectAllMiners) {
        setStopRefresh(true);
        // console.log("selected rows upon page is true", newRows, stopRefresh);
        let localRows = newRows.map((row) => row.serial);
        // console.log("localrows", localRows);
        // console.log("not global selected rows", globalNotSelectionModel);

        const matchingItems = localRows.filter(item => globalNotSelectionModel.includes(item));
        // console.log("Matching items to uncheck the rows:", matchingItems);

        localRows = localRows.filter(item => !globalNotSelectionModel.includes(item));

        globalSelectionModel = [...new Set([...globalSelectionModel, ...localRows])];
        // console.log("select all miners is true updated one", globalSelectionModel, stopRefresh);

        setSelectionModel(localRows);
      }
      setLoading(false);
      setCount(count1);

      const nowDate = new Date();
      setLastRefresh(nowDate.toLocaleString());
      setTimeout(() => {
        if (!stopRefresh) {
          fn();
        } else {
          console.debug("Stopped the refresh")
        }
      }, timegap * 1000);
      localStorage.setItem("dl-refreshInterval", timegap);
    };
    // setUrlParams()
    fn();
    return () => {
      active = false;
    };
  }, [paginationModel, timegap, searchStrings, previoussearchStrings, stopRefresh, selectAllMiners]);

  React.useEffect(() => {
    const fn = async () => {
      let pref = [];
      const token = await getAccessTokenSilently();
      await api.GetUserTechsupportFilter(token, (data) => {
        if (data.status === "error") {
          setTechSupport(false);
          setDisplayTable("yes");
        } else {
          setTechSupport(true);
          b_techsupport = true;
          setDisplayTable("yes");
        }
      });
      await api.GetUser(token, (data) => {
        if (
          data.hasOwnProperty("preference") &&
          data.preference !== null &&
          data.preference !== undefined
        ) {
          pref = data.preference;
        }
      });
      if (b_techsupport) {
        const storedTechsupportcolumns = localStorage.getItem(
          "MinerTechSupportColumns"
        );
        if (storedTechsupportcolumns) {
          //console.log("Fixed the columns with storage methods");
          const parsedColumns = JSON.parse(storedTechsupportcolumns);
          /*
           * Set the Methods for each column
           */
          let columnsWithMethods = parsedColumns.map((column) => {
            const originalColumn = initialTechSupportcolumns.find(
              (col) => col.field === column.field
            );
            return originalColumn
              ? { ...column, renderCell: originalColumn.renderCell }
              : column;
          });
          /*
           * Set the width from the local storage into the columns
           */
          const storedColWidthsCS = localStorage.getItem(
            "MinerStatusColWidthsCS"
          );
          if (storedColWidthsCS) {
            const parsedColWidthsCS = JSON.parse(storedColWidthsCS);
            columnsWithMethods.forEach((column) => {
              if (parsedColWidthsCS.hasOwnProperty(column.field)) {
                column.width = parsedColWidthsCS[column.field];
              }
            });
            // //console.log("Fixed the column witdh with storage methods11")
            setColWidthsCS(parsedColWidthsCS);
          }
          /*
           * Set the visibility from the local storage into the columns
           */
          const storedColVisibilityCS = localStorage.getItem(
            "MinerStatusColVisibilityCS"
          );
          if (storedColVisibilityCS) {
            const parsedColVisibilityCS = JSON.parse(storedColVisibilityCS);
            columnsWithMethods.forEach((column) => {
              if (storedColVisibilityCS.hasOwnProperty(column.field)) {
                column.hide = !storedColVisibilityCS[column.field];
              }
            });
            // //console.log("Fixed the columns with storage methods11", columnsWithMethods)
            setColVisibilityModelCS(parsedColVisibilityCS);
          }
          // //console.log("Fixed the columns with storage methods")
          setTechSupportcolumns(columnsWithMethods);
          const headerNames = parsedColumns.map((column) => column.headerName);
          /*
           * Set the order from the local storage into the columns
           */
          setColOrderCS(headerNames);
          localStorage.setItem(
            "MinerTechSupportColumns",
            JSON.stringify(columnsWithMethods)
          );
        } else if (pref.length > 0) {
          //console.log("Fixed the columns with db methods");
          /*
           * Fill the methods from the initial variable as we will not store the methods in the DB
           */
          const columnsWithMethods = pref.map((column) => {
            const originalColumn = initialTechSupportcolumns.find(
              (col) => col.field === column.field
            );
            return originalColumn
              ? { ...column, renderCell: originalColumn.renderCell }
              : column;
          });
          /*
           * Set the width into the storage from the DB
           */
          const widthLookup = {};
          columnsWithMethods.forEach((column) => {
            widthLookup[column.field] = column.width;
          });
          setColWidthsCS(widthLookup);
          /*
           * Set the column order into the storage from the DB
           */
          const headerNames = columnsWithMethods.map(
            (column) => column.headerName
          );
          setColOrderCS(headerNames);
          /*
           * Set the column Visibility into the storage from the DB
           */
          const newVisibilityCS = pref.reduce((acc, column) => {
            acc[column.field] = !column.hide;
            return acc;
          }, {});
          setColVisibilityModelCS(newVisibilityCS);
          setTechSupportcolumns(columnsWithMethods);
          localStorage.setItem(
            "MinerTechSupportColumns",
            JSON.stringify(columnsWithMethods)
          );
          // //console.log("Fixed the columns with db methods order, width and columns", columnsWithMethods)
        } else {
          //console.log("Fixed the columns with initial methods");
          const headerNames = initialTechSupportcolumns.map(
            (column) => column.headerName
          );
          setColOrderCS(headerNames);
          localStorage.setItem(
            "MinerTechSupportColumns",
            JSON.stringify(initialTechSupportcolumns)
          );
        }
      } else {
        // MinerStatus Customer Columns
        const storedMinerStatusColumns =
          localStorage.getItem("MinerStatusColumns");
        if (storedMinerStatusColumns) {
          //console.log("Fixed the columns with storage methods");
          const parsedColumns = JSON.parse(storedMinerStatusColumns);
          /*
           * Set the Methods for each column
           */
          let columnsWithMethods = parsedColumns.map((column) => {
            const originalColumn = initialMinerStatusColumns.find(
              (col) => col.field === column.field
            );
            return originalColumn
              ? { ...column, renderCell: originalColumn.renderCell }
              : column;
          });
          /*
           * Set the width from the local storage into the columns
           */
          const storedColWidths = localStorage.getItem("MinerStatusColWidths");
          if (storedColWidths) {
            const parsedColWidths = JSON.parse(storedColWidths);
            columnsWithMethods.forEach((column) => {
              if (parsedColWidths.hasOwnProperty(column.field)) {
                column.width = parsedColWidths[column.field];
              }
            });
            // //console.log("Fixed the column witdh with storage methods11")
            setColWidths(parsedColWidths);
          }
          /*
           * Set the visibility from the local storage into the columns
           */
          const storedColVisibility = localStorage.getItem(
            "MinerStatusColVisibility"
          );
          if (storedColVisibility) {
            const parsedColVisibility = JSON.parse(storedColVisibility);
            columnsWithMethods.forEach((column) => {
              if (storedColVisibility.hasOwnProperty(column.field)) {
                column.hide = !storedColVisibility[column.field];
              }
            });
            // //console.log("Fixed the columns with storage methods11", columnsWithMethods)
            setColVisibilityModel(parsedColVisibility);
          }
          //console.log("Fixed the columns with storage methods");
          setColumns(columnsWithMethods);
          const headerNames = parsedColumns.map((column) => column.headerName);
          /*
           * Set the order from the local storage into the columns
           */
          setColOrder(headerNames);
          localStorage.setItem(
            "MinerStatusColumns",
            JSON.stringify(columnsWithMethods)
          );
        } else if (pref.length > 0) {
          //console.log("Fixed the columns with db methods");
          /*
           * Fill the methods from the initial variable as we will not store the methods in the DB
           */
          const columnsWithMethods = pref.map((column) => {
            const originalColumn = initialMinerStatusColumns.find(
              (col) => col.field === column.field
            );
            return originalColumn
              ? { ...column, renderCell: originalColumn.renderCell }
              : column;
          });
          /*
           * Set the width into the storage from the DB
           */
          const widthLookup = {};
          columnsWithMethods.forEach((column) => {
            widthLookup[column.field] = column.width;
          });
          setColWidths(widthLookup);
          /*
           * Set the column order into the storage from the DB
           */
          const headerNames = columnsWithMethods.map(
            (column) => column.headerName
          );
          setColOrder(headerNames);
          /*
           * Set the column Visibility into the storage from the DB
           */
          const newVisibility = pref.reduce((acc, column) => {
            acc[column.field] = !column.hide;
            return acc;
          }, {});
          setColVisibilityModel(newVisibility);
          setColumns(columnsWithMethods);
          localStorage.setItem(
            "MinerStatusColumns",
            JSON.stringify(columnsWithMethods)
          );
          // //console.log("Fixed the columns with db methods order, width and columns", columnsWithMethods)
        } else {
          //console.log("Fixed the columns with initial methods");
          const headerNames = initialMinerStatusColumns.map(
            (column) => column.headerName
          );
          setColOrder(headerNames);
          localStorage.setItem(
            "MinerStatusColumns",
            JSON.stringify(initialMinerStatusColumns)
          );
        }
      }
    };
    fn();
  }, [rows, getAccessTokenSilently]);

  React.useEffect(() => {
    const runFn = async () => {
      const token = await getAccessTokenSilently();
      const isAllowed = await api.IsOperationAllowed(token);
      if (isAllowed === undefined) {
        validUser = 1;
        setSubmitMsg("something");
        setSuccessModalOpen(true);
        return;
      } else if (isAllowed.status === "error") {
        if (isAllowed.errorCode === 1007) {
          validUser = 1;
          setSubmitMsg("nouser");
          setSuccessModalOpen(true);
        } else if (isAllowed.errorCode === 1009) {
          validUser = 0;
          setRole(isAllowed.role);
          console.log('Readonly user')
        } else {
          validUser = 1;
          setSubmitMsg("fetchfailed");
          setSuccessModalOpen(true);
        }
        // console.log("Operation Allowed", isAllowed);
        if (validUser !== 0) {
          return;
        }
      } else {
        // console.log("Operation Allowed", isAllowed)
        validUser = 0;
        setRole(isAllowed.role);
        setSubmitMsg("ok");
        setSuccessModalOpen(true);
      }
      const deviceGroups = await api.GetAllDeviceGroups(token);
      if (deviceGroups === undefined) {
        setMinerGroups([]);
      } else {
        var minerGroupList = [];
        for (let i = 0; i < deviceGroups.length; i++) {
          minerGroupList.push(deviceGroups[i].DGName);
        }
        minerGroupList.sort();
        // console.log("Miner Groups", minerGroupList)
        setMinerGroups(minerGroupList);
      }
      await api.GetOrg(token, (data) => {
        setOrgID(data.org_id);
        setMembers(data.member);
        setAlertMembers(data.alertmember);
        setInitialAlertMembers(data.alertmember);
        setNumberOfChipsOverThreshold(data.numberOfChipsOverThreshold);
        setDesiredAlertInterval(data.desiredAlertInterval);
        setInitialDesiredAlertInterval(data.desiredAlertInterval);
        // console.log(' alert interval', data.desiredAlertInterval);
        setTransferMiners([])
        setShowTransferJobs(false)
        if (Object.hasOwnProperty.call(data, 'transfer') && data.transfer.length > 0) {
          const transferMiners = data.transfer;
          const matchingJobs = transferMiners.filter(item => item.target_org_id === data.org_id);
          setTransferMiners(matchingJobs);
          if (matchingJobs.length > 0) {
            setShowTransferJobs(true)
          }
        }
      });
      await api.GetMaxLimits(token, (data) => {
        if (data !== undefined && data !== null && data.status === "ok") {
          setMaxTHs(data.maxths);
          setMaxPower(data.maxpower);
        }
      });
    };
    runFn();
  }, [getAccessTokenSilently]);


  const getHoverBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);

  const getBackgroundColor = (color, mode) =>
    mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);

  const handleSearchInputChange = (field, value) => {
    setSearchStrings((prevSearchStrings) => ({
      ...prevSearchStrings,
      [field]: value || "",
    }));
  };

  // Validate that inputName only contains alphanumeric, hyphen, underscore, and dot
  const handleLedChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[a-zA-Z(), \.]*$/.test(inputName)) {
      handleSearchInputChange("led", inputName);
      urlParams.set("led", inputName);
    } else {
      urlParams.set("led", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };
  const handleStateChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[a-zA-Z0-9 ]*$/.test(inputName)) {
      handleSearchInputChange("state", inputName);
      urlParams.set("state", inputName);
    } else {
      urlParams.set("state", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };
  const handlePowerChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[0-9-]*$/.test(inputName)) {
      handleSearchInputChange("power", inputName);
      urlParams.set("power", inputName);
    } else {
      urlParams.set("power", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };
  const handleIPAddressChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[0-9\.]*$/.test(inputName)) {
      handleSearchInputChange("ipAddress", inputName);
      urlParams.set("ipAddress", inputName);
    } else {
      urlParams.set("ipAddress", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };
  const handleTHsChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[0-9-]*$/.test(inputName)) {
      handleSearchInputChange("ths", inputName);
      urlParams.set("ths", inputName);
    } else {
      urlParams.set("ths", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };

  const handleJTHsChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[0-9-]*$/.test(inputName)) {
      handleSearchInputChange("jths", inputName);
      urlParams.set("jths", inputName);
    } else {
      urlParams.set("jths", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };
  const handleDGNameChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[a-zA-Z0-9- ]*$/.test(inputName)) {
      handleSearchInputChange("dgname", inputName);
      urlParams.set("dgname", inputName);
    } else {
      urlParams.set("dgname", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
    setIsMinerGroupSelected(inputName !== "");
  };



  const handleOrgNameChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[a-zA-Z0-9 ]*$/.test(inputName)) {
      handleSearchInputChange("org_name", inputName);
      urlParams.set("org_name", inputName);
    } else {
      urlParams.set("org_name", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };
  const handleVersionChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[a-zA-Z0-9-.]*$/.test(inputName)) {
      handleSearchInputChange("version", inputName);
      urlParams.set("version", inputName);
    } else {
      urlParams.set("version", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };

  const handleChassisChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[a-zA-Z0-9]*$/.test(inputName)) {
      handleSearchInputChange("chassis", inputName);
      urlParams.set("chassis", inputName);
    } else {
      urlParams.set("chassis", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };

  const handleCBSNChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[a-zA-Z0-9]*$/.test(inputName)) {
      handleSearchInputChange("CBSN", inputName);
      urlParams.set("CBSN", inputName);
    } else {
      urlParams.set("CBSN", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };

  const handleModelChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[a-zA-Z0-9-.]*$/.test(inputName)) {
      handleSearchInputChange("model", inputName);
      urlParams.set("model", inputName);
    } else {
      urlParams.set("model", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };

  const handleCustomNameFilterChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[a-zA-Z0-9-.]*$/.test(inputName)) {
      urlParams.set("name", inputName);
      handleSearchInputChange("name", inputName);
    } else {
      urlParams.set("name", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };

  const handleCSTagChangeFilter = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[a-zA-Z0-9-.]*$/.test(inputName)) {
      urlParams.set("csTag", inputName);
      handleSearchInputChange("csTag", inputName);
    } else {
      urlParams.set("csTag", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };

  const handleTemperatureChange = (event) => {
    const urlParams = new URLSearchParams(location.search);
    const inputName = event.target.value;
    if (/^[0-9-]*$/.test(inputName)) {
      handleSearchInputChange("temperature", inputName);
      urlParams.set("temperature", inputName);
    } else {
      urlParams.set("temperature", "");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };

  const onClear = () => {
    // console.log("clear the search filter changes")
    const params = {
      led: "",
      state: "",
      power: "",
      ipAddress: "",
      ths: "",
      jths: "",
      dgname: "",
      temperature: "",
      org_name: "",
      version: "",
      model: "",
      name: "",
      csTag: "",
      chassis: "",
      CBSN: "",
    };
    setIsMinerGroupSelected(false);
    setSearchStrings(params);
    setPreviousSearchStrings(params);
    const urlParams = new URLSearchParams(location.search);
    handleSearchInputChange("led", "");
    if (urlParams.has("led")) {
      urlParams.delete("led");
    }
    handleSearchInputChange("state", "");
    if (urlParams.has("state")) {
      urlParams.delete("state");
    }
    handleSearchInputChange("power", "");
    if (urlParams.has("power")) {
      urlParams.delete("power");
    }
    handleSearchInputChange("ipAddress", "");
    if (urlParams.has("ipAddress")) {
      urlParams.delete("ipAddress");
    }
    handleSearchInputChange("ths", "");
    if (urlParams.has("ths")) {
      urlParams.delete("ths");
    }
    handleSearchInputChange("jths", "");
    if (urlParams.has("jths")) {
      urlParams.delete("jths");
    }
    handleSearchInputChange("dgname", "");
    if (urlParams.has("dgname")) {
      urlParams.delete("dgname");
    }
    handleSearchInputChange("temperature", "");
    if (urlParams.has("temperature")) {
      urlParams.delete("temperature");
    }
    handleSearchInputChange("org_name", "");
    if (urlParams.has("org_name")) {
      urlParams.delete("org_name");
    }
    handleSearchInputChange("version", "");
    if (urlParams.has("version")) {
      urlParams.delete("version");
    }
    handleSearchInputChange("model", "");
    if (urlParams.has("model")) {
      urlParams.delete("model");
    }
    handleSearchInputChange("name", "");
    if (urlParams.has("name")) {
      urlParams.delete("name");
    }
    handleSearchInputChange("csTag", "");
    if (urlParams.has("csTag")) {
      urlParams.delete("csTag");
    }
    handleSearchInputChange("chassis", "");
    if (urlParams.has("chassis")) {
      urlParams.delete("chassis");
    }
    handleSearchInputChange("CBSN", "");
    if (urlParams.has("CBSN")) {
      urlParams.delete("CBSN");
    }
    history.replace({ search: urlParams.toString() });
    localStorage.setItem(
      "dl-urlParams",
      JSON.stringify(Object.fromEntries(urlParams.entries()))
    );
  };

  //update current miner group and reset selected miners if no miner group is selected
  React.useEffect(() => {
    if (searchStrings.dgname !== "") {
      console.log(role)
      setMinerGroup(searchStrings.dgname);
      if (role === "pooladmin" || role === "superadmin" || role === "admin") {
        setIsMinerGroupSelected(true);
      } else if (role === "user") {
        // For readonly users, enable the row selection only if the miner group is selected
        setIsMinerGroupSelected(true);
      } else {
        setIsMinerGroupSelected(false);
      }
    } else {
      setSelectionModel([]);
      globalSelectionModel = [];
      globalNotSelectionModel = [];
      setPaginationModel({ ...paginationModel, page: 0 });
    }
  }, [searchStrings.dgname, role]);

  //reset selected miners + page and pagesize upon change in miner group
  React.useEffect(() => {
    setSelectionModel([]);
    globalSelectionModel = [];
    globalNotSelectionModel = [];
    setPaginationModel({ ...paginationModel, page: 0 });
  }, [minerGroup]);

  React.useEffect(() => {
    if (selectionModel.length === 0 && globalSelectionModel.length === 0) {
      setOpenOperationsPopup(false);
      setStopRefresh(false);
      setSelectAllMiners(false);
      // console.log("stopRefresh is set to false");
    } else {
      setStopRefresh(true);
    }
  }, [selectionModel]);

  //Operation Dialog functions

  const fetchMsgForKeys = (data) => {
    const msgs = {};
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const msg = data[key]?.result?.msg;
        msgs[key] = msg || "No message found";
      }
    }
    return msgs;
  };

  const [actionvalue, setActionValue] = React.useState("");

  React.useEffect(() => {
    if (role === "pooladmin" || role === "superadmin") {
      setActionValue("0");
    } else {
      setActionValue("1");
    }
  }, [role]);

  const handleValueChange = (event, newValue) => {
    setActionValue(newValue);
  };


  const timeFormat = async (timevalue) => {

    const date = new Date(timevalue);

    // Get the components of the date
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    // Construct the formatted date string
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;

    return formattedDate

  }

  // Miner Transfer Tab
  const [targetOrgName, setTargetOrgName] = React.useState("");
  const [targetEmailID, setTargetEmailID] = React.useState("");
  const [transferMinersConfirmationMessage, setTransferMinerConfirmationMessage] = React.useState("");
  const [openTransferMinersConfirmation, setOpenTransferMinersConfirmation] = React.useState(false);

  function handleCloseTransferMinersConfirmation() {
    setOpenTransferMinersConfirmation(false);
  }

  function handleTargetOrgName(event) {
    setTargetOrgName(event.target.value);
  }

  function handleTargetEmailID(event) {
    setTargetEmailID(event.target.value);
  }

  function handleClearTransferMiners() {
    setTargetOrgName("");
    setTargetEmailID("");
  }

  const handleTransferMiners = async () => {
    let tmpMinerGroup = minerGroup;

    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      if (targetOrgName.trim() === "") {
        setTransferMinerConfirmationMessage(
          `Please provide the target organization name`
        );
        setOpenTransferMinersConfirmation(true);
        return;
      }
      dgdata.target_org_name = targetOrgName.trim();
      if (targetEmailID.trim() === "") {
        setTransferMinerConfirmationMessage(
          `Please provide the target organization email id`
        )
        setOpenTransferMinersConfirmation(true);
        return;
      }
      dgdata.target_email = targetEmailID.trim();
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.TransferMiners(token, dgdata);
      if (resp === undefined || resp === null) {
        setTransferMinerConfirmationMessage(
          `Error in transferring miners for ${minerGroup}`
        );
        setOpenTransferMinersConfirmation(true);
        console.log(
          `Failed to transfer ${selectionModel} miners for ${minerGroup}`
        );
      } else if (resp.status === "ok") {
        let msg =
          `Successfully processed the transfer for ${minerGroup}. ` +
          resp.msg;
        setTransferMinerConfirmationMessage(msg);
        setOpenTransferMinersConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for transfer ${selectionModel} in ${minerGroup}`
        );
        let msg = `Failed to transfer miners for ${minerGroup} ` + resp.msg;
        setTransferMinerConfirmationMessage(msg);
        setOpenTransferMinersConfirmation(true);
      }
    }
  };


  // Upgrade Miner Tab

  const [upgradeChecked, setUpgradeChecked] = React.useState(true);
  const [URLChecked, setURLChecked] = React.useState(false);
  const [URLInput, setURLInput] = React.useState("");

  // Rolling Upgrade Miner Tab
  const [rollingupgradeChecked, setRollingUpgradeChecked] = React.useState(true);
  const [rollingURLChecked, setRollingURLChecked] = React.useState(false);
  const [rollingURLInput, setRollingURLInput] = React.useState("");

  const [ecoChecked, setEcoChecked] = React.useState(false);
  const [turboChecked, setTurboChecked] = React.useState(false);
  const [thsChecked, setTHsChecked] = React.useState(false);
  const [thsInput, setTHsInput] = React.useState("");
  const [thsError, setTHsError] = React.useState(false);
  const [retuneError, setRetuneError] = React.useState(false);
  const [reTuneTime, setRetuneTime] = React.useState("");
  const [powerChecked, setPowerChecked] = React.useState(false);
  const [powerInput, setPowerInput] = React.useState("");
  const [powerError, setPowerError] = React.useState(false);
  const [openUpgradeConfirmation, setOpenUpgradeConfirmation] =
    React.useState(false);
  const [upgradeConfirmationMessage, setUpgradeConfirmationMessage] =
    React.useState("");
  const [statusUpgrade, setStatusUpgrade] = React.useState(false);
  const [upgradeStatusMessage, setUpgradeStatusMessage] = React.useState("");
  const [getFWfail, setGetFWfail] = React.useState(false);
  const [fwjobMessage, setFWJobMessage] = React.useState({});
  const [fwMultipleJobMessage, setFWMultipleJobMessage] = React.useState([]);
  const [openRollingUpgradeConfirmation, setOpenRollingUpgradeConfirmation] =
  React.useState(false);
const [rollingupgradeConfirmationMessage, setRollingUpgradeConfirmationMessage] =
  React.useState("");
const [statusRollingUpgrade, setStatusRollingUpgrade] = React.useState(false);
const [rollingupgradeStatusMessage, setRollingUpgradeStatusMessage] = React.useState("");

  function handleEcoChecked() {
    setEcoChecked(true);

    setTurboChecked(false);
    setTHsChecked(false);
    setPowerChecked(false);
    setPowerInput("");
    setTHsInput("");
  }

  function handleTurboChecked() {
    setTurboChecked(true);
    setEcoChecked(false);
    setTHsChecked(false);
    setPowerChecked(false);
    setPowerInput("");
    setTHsInput("");
  }

  function handleTHsChecked() {
    setTHsChecked(true);
    setEcoChecked(false);
    setTurboChecked(false);
    setPowerChecked(false);
    setPowerInput("");
  }

  function handlePowerChecked() {
    setPowerChecked(true);
    setEcoChecked(false);
    setTurboChecked(false);
    setTHsChecked(false);
    setTHsInput("");
  }

  function handleUpgradeChecked() {
    setUpgradeChecked(true);
    setURLChecked(false);
  }

  function handleURLChecked() {
    setURLChecked(true);
    setUpgradeChecked(false);
  }

  function handleRollingUpgradeChecked() {
    setRollingUpgradeChecked(true);
    setRollingURLChecked(false);
  }

  function handleRollingURLChecked() {
    setRollingUpgradeChecked(false);
    setRollingURLChecked(true);
  }

  const handleTHsInput = (event) => {
    setPowerInput("");
    const value = event.target.value;
    if (value === "" || (value >= constants.MINTHS && value <= maxths)) {
      setTHsInput(value)
      setTHsError(false);
    } else {
      if (value >= 0) {
        setTHsInput(value);
      } else {
        setTHsInput("")
      }
      setTHsError(true);
    }
  };

  const handlePowerInput = (event) => {
    setTHsInput("");
    const value = event.target.value;
    if (value >= constants.MINPOWER && value <= maxpower) {
      setPowerInput(value)
      setPowerError(false);
    } else {
      if (value >= 0) {
        setPowerInput(value);
      } else {
        setPowerInput("")
      }
      setPowerError(true);
    }
  };

  function handleURLInput(event) {
    setURLInput(event.target.value);
  }

  function handleRollingURLInput(event) {
    setRollingURLInput(event.target.value);
  }

  const isValidURL = (url) => {
    const urlRegex = /^(http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  function handleCloseUpgradeConfirmation() {
    setOpenUpgradeConfirmation(false);
  }

  const handleUpgradeUpgrade = async () => {
    // console.log('upgrading devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    if (URLChecked && !isValidURL(URLInput)) {
      setUpgradeConfirmationMessage("Invalid URL: Please enter a valid URL");
      setOpenUpgradeConfirmation(true);
      return;
    }
    let commandList = [];
    if (upgradeChecked) {
      commandList = [{ command: "firmware-upgrade", version: "latest" }];
    } else {
      commandList = [{ command: "firmware-upgrade", url: URLInput }];
    }
    // console.log('commandList:', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setUpgradeConfirmationMessage(
          `Error in upgrading miners for ${minerGroup}`
        );
        setOpenUpgradeConfirmation(true);
        console.log(
          `Failed to upgrade ${selectionModel} miners for ${minerGroup}`
        );
      } else if (resp.status === "ok") {
        // console.log(
        //   `succeeded in upgrading ${selectionModel} miners for ${minerGroup} `,
        //   JSON.stringify(resp)
        // );
        let msg =
          `Successfully processed the command for ${minerGroup}. ` +
          resp.msg;
        setUpgradeConfirmationMessage(msg);
        setOpenUpgradeConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for upgrading ${selectionModel} in ${minerGroup}`
        );
        let msg = `Failed to upgrade miners for ${minerGroup} ` + resp.msg;
        setUpgradeConfirmationMessage(msg);
        setOpenUpgradeConfirmation(true);
      } else {
        let msg = "";
        if (resp.msg) {
          msg = `Failed to upgrade miners for ${minerGroup} ` + resp.msg;
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg =
            `Failed to upgrade miners for ${minerGroup} ` +
            JSON.stringify(msgs);
        }
        setUpgradeConfirmationMessage(msg);
        setOpenUpgradeConfirmation(true);
        console.log(
          `failed to upgrade ${selectionModel} miners for ${minerGroup}`
        );
      }
    }
  };

  function handleCloseStatusUpgrade() {
    setStatusUpgrade(false);
  }

  const handleRestartMinerUpgrade = async () => {
    // console.log('upgrade restart for devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    let commandList = [{ command: "restart" }];
    // console.log('commandList', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setOpenUpgradeConfirmation(true);
        setUpgradeConfirmationMessage(
          `Error in restart miners for ${minerGroup}`
        );
        console.log(
          `failed to restart ${selectionModel} miners for ${minerGroup}`
        );
      } else if (resp.status === "ok") {
        // console.log(
        //   `succeeded in sending restart command ${selectionModel} miners for ${minerGroup} `,
        //   JSON.stringify(resp)
        // );
        let msg =
          `Successfully processed the command for ${minerGroup}. ` +
          resp.msg;
        setUpgradeConfirmationMessage(msg);
        setOpenUpgradeConfirmation(true);
      } else {
        let msg = `Failed to restart miners for ${minerGroup}` + resp.msg;
        setUpgradeConfirmationMessage(msg);
        console.log(
          `failed to restart ${selectionModel} miners for ${minerGroup}`
        );
        setOpenUpgradeConfirmation(true);
      }
    }
  };

  function handleClearUpgrade() {
    setUpgradeChecked(true);
    setURLChecked(false);
    setURLInput("");
  }

  // Rolling Upgrade Miner Tab
  function handleCloseRollingUpgradeConfirmation() {
    setOpenRollingUpgradeConfirmation(false);
  }

  const handleRollingUpgradeAddOperation = async () => {
    let tmpMinerGroup = minerGroup;

    if (rollingURLChecked && !isValidURL(rollingURLInput)) {
      setRollingUpgradeConfirmationMessage("Invalid URL: Please enter a valid URL");
      setOpenRollingUpgradeConfirmation(true);
      return;
    }
    if (startTimeString === '') {
      setRollingUpgradeConfirmationMessage("Start time must be at least 5 minutes in the future");
      setOpenRollingUpgradeConfirmation(true);
      return;
    }
    let timelimit = 4;
    const currentTime = new Date();
    const startTime = new Date(startTimeString);
    if ((startTime <= currentTime) || (startTime.getTime() - currentTime.getTime()) < (timelimit * 60 * 1000)) {
      setRollingUpgradeConfirmationMessage("Start time must be at least 5 minutes in the future");
      setOpenRollingUpgradeConfirmation(true);
      return;
    }
    let upgrade_window_time = 120;
    if (changeUpgradeWindowTime !== '') {
      upgrade_window_time = parseInt(changeUpgradeWindowTime)
      if (upgrade_window_time < 120 || upgrade_window_time > 43200) {
        setRollingUpgradeConfirmationMessage("Invalid upgrade window time. Please enter valid upgrade window time between 120 and 43200 seconds (12 hrs)");
        setOpenRollingUpgradeConfirmation(true);
        return
      }
    } else {
      setRollingUpgradeConfirmationMessage("Invalid upgrade window time. Please enter valid upgrade window time between 120 and 43200 seconds (12 hrs)");
      setOpenRollingUpgradeConfirmation(true);
      return
    }
    let rolling_start_time = await timeFormat(startTimeString)
    let commandList = [];
    if (rollingupgradeChecked) {
      commandList = [{
        "command"       : "rolling-firmware-upgrade",
        "operation"     : "add",
        "start_time"    : rolling_start_time,
        "upgrade_window": upgrade_window_time,
        "version"       : "latest",
        "url"           : "",
      }]
    } else {
      commandList = [{
        "command"       : "rolling-firmware-upgrade",
        "operation"     : "add",
        "start_time"    : rolling_start_time,
        "upgrade_window": upgrade_window_time,
        "url"           : rollingURLInput,
        "version"       : "",
      }]
    }
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setRollingUpgradeConfirmationMessage(
          `Error in rolling upgrade miners for ${minerGroup}`
        );
        setOpenRollingUpgradeConfirmation(true);
        console.log(
          `Failed to upgrade ${selectionModel} miners for ${minerGroup}`
        );
      } else if (resp.status === "ok") {
        console.debug(`succeeded in rolling upgrading miners for ${minerGroup}`);
        let msg =
          `Successfully processed the rolling upgrade command for ${minerGroup}. ` +
          resp.msg;
        setRollingUpgradeConfirmationMessage(msg);
        setOpenRollingUpgradeConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for rolling upgrading ${selectionModel} in ${minerGroup}`
        );
        let msg = `Failed to rolling upgrade miners for ${minerGroup} ` + resp.msg;
        setRollingUpgradeConfirmationMessage(msg);
        setOpenRollingUpgradeConfirmation(true);
      } else {
        let msg = "";
        if (resp.msg) {
          msg = `Failed to rolling upgrade miners for ${minerGroup} ` + resp.msg;
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg =
            `Failed to rolling upgrade miners for ${minerGroup} ` +
            JSON.stringify(msgs);
        }
        setRollingUpgradeConfirmationMessage(msg);
        setOpenRollingUpgradeConfirmation(true);
        console.log(
          `failed to rolling upgrade ${selectionModel} miners for ${minerGroup}`
        );
      }
    }
  };

  const handleRollingUpgradeDeleteOperation = async () => {
    let tmpMinerGroup = minerGroup;
    let commandList = [{
      "command"       : "rolling-firmware-upgrade",
      "operation"     : "cancel"
    }]
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setRollingUpgradeConfirmationMessage(
          `Error in rolling upgrade miners for ${minerGroup}`
        );
        setOpenRollingUpgradeConfirmation(true);
        console.log(
          `Failed to rolling upgrade ${selectionModel} miners for ${minerGroup}`
        );
      } else if (resp.status === "ok") {
        console.debug(`succeeded in rolling upgrading miners for ${minerGroup}`);
        let msg =
          `Successfully processed the delete rolling upgrade command for ${minerGroup}. ` +
          resp.msg;
        setRollingUpgradeConfirmationMessage(msg);
        setOpenRollingUpgradeConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for rolling upgrading ${selectionModel} in ${minerGroup}`
        );
        let msg = `Failed to rolling upgrade miners for ${minerGroup} ` + resp.msg;
        setRollingUpgradeConfirmationMessage(msg);
        setOpenRollingUpgradeConfirmation(true);
      } else {
        let msg = "";
        if (resp.msg) {
          msg = `Failed to rolling upgrade miners for ${minerGroup} ` + resp.msg;
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg =
            `Failed to rolling upgrade miners for ${minerGroup} ` +
            JSON.stringify(msgs);
        }
        setRollingUpgradeConfirmationMessage(msg);
        setOpenRollingUpgradeConfirmation(true);
        console.log(
          `failed to rolling upgrade ${selectionModel} miners for ${minerGroup}`
        );
      }
    }
  };

  function handleCloseStatusRollingUpgrade() {
    setStatusRollingUpgrade(false);
  }

  function handleClearRollingUpgrade() {
    setRollingUpgradeChecked(true);
    setRollingURLChecked(false);
    setRollingURLInput("");
    setStartTimeError("")
    setStartTimeErrorValue(false)
    setChangeUpgradeWindowTime("");
    setchangeUpgradeWindowTimeValueError({ error: false, helperText: "" });
    let starttimevalue = 5;

    let startValue = dayjs().add(starttimevalue, 'minute')

    setStartTimeValue(startValue)

    let starttime = startValue['$d'].toString()
    if (starttime !== '' && starttime !== undefined && starttime !== null & starttime !== 'Invalid Date') {
      setStartTimeString(starttime)
    } else {
      //console.log('wrong place')
      setStartTimeString('')
    }
  }

  // FluxVision Tuning Config Tab

  const [tuningChecked, setTuningChecked] = React.useState(false);
  const [statusTuning, setStatusTuning] = React.useState(false);
  const [openTuningConfigConfirmation, setOpenTuningConfigConfirmation] =
    React.useState(false);
  const [tuningConfigConfirmationMessage, setTuningConfigConfirmationMessage] =
    React.useState("");
  const [tuningConfigStatusMessage, setTuningConfigStatusMessage] =
    React.useState("");
  const [getTunefail, setGetTunefail] = React.useState(false);
  const [tuneJobMessage, setTuneJobMessage] = React.useState({});
  const [tuneMultipleJobMessage, setTuneMultipleJobMessage] = React.useState(
    []
  );

  const handleTuningChecked = (event) => {
    setTuningChecked(event.target.checked);
  };

  function handleCloseTuningConfigConfirmation() {
    setOpenTuningConfigConfirmation(false);
  }

  const handleSendTuningConfig = async () => {
    // console.log('tuning devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    let commandList = [];
    if (tuningChecked) {
      commandList = [{ command: "accept-remote-tuning", parameter: "on" }];
    } else {
      commandList = [{ command: "accept-remote-tuning", parameter: "off" }];
    }
    // console.log('commandList', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setTuningConfigConfirmationMessage(
          `Error in sending the command to ${minerGroup} miners`
        );
        console.log(
          `failed to send the command to ${selectionModel} miners for ${minerGroup}`
        );
        setOpenTuningConfigConfirmation(true);
      } else if (resp.status === "ok") {
        // console.log(
        //   `succeeded in sending the command to ${selectionModel} miners for ${minerGroup} `,
        //   JSON.stringify(resp)
        // );
        let msg =
          `Successfully processed the command for ${minerGroup}. ` +
          resp.msg;
        setTuningConfigConfirmationMessage(msg);
        setOpenTuningConfigConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for tuning ${selectionModel} in ${minerGroup}`
        );
        let msg =
          `Failed to send the command to the miners of ${minerGroup} ` +
          resp.msg;
        setTuningConfigConfirmationMessage(msg);
        setOpenTuningConfigConfirmation(true);
      } else {
        setOpenTuningConfigConfirmation(true);
        let msg = "";
        if (resp.msg) {
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            resp.msg;
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            JSON.stringify(msgs);
        }
        setTuningConfigConfirmationMessage(msg);
        console.log(
          `failed to send the command to ${selectionModel} miners for ${minerGroup} ${msg}`
        );
      }
    }
  };

  function handleCloseStatusTuning() {
    setStatusTuning(false);
  }

  function handleClearTuningConfig() {
    setTuningChecked(false);
  }

  // Standby Mode Tab

  const [standbyChecked, setStandbyChecked] = React.useState(false);
  const [statusStandby, setStatusStandby] = React.useState(false);
  const [openStandbyConfirmation, setOpenStandbyConfirmation] =
    React.useState(false);
  const [standbyConfirmationMessage, setStandbyConfirmationMessage] =
    React.useState("");
  const [standbyStatusMessage, setStandbyStatusMessage] = React.useState("");
  const [getSleepfail, setGetSleepfail] = React.useState(false);
  const [standbyJobMessage, setStandbyJobMessage] = React.useState({});
  const [standbyMultipleJobMessage, setStandbyMultipleJobMessage] =
    React.useState([]);

  const handleStandbyModeChecked = (event) => {
    setStandbyChecked(event.target.checked);
  };

  function handleCloseStandbyConfirmation() {
    setOpenStandbyConfirmation(false);
  }

  const handleSendStandbyMode = async () => {
    // console.log('standby mode for devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    let commandList = [];
    if (standbyChecked) {
      commandList = [{ command: "fvmode", sleep: "on" }];
    } else {
      commandList = [{ command: "fvmode", sleep: "off" }];
    }
    // console.log('commandList', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setStandbyConfirmationMessage(
          `Error in sending the command to ${minerGroup} miners`
        );
        console.log(
          `Failed to send the command to ${selectionModel} miners for ${minerGroup}`
        );
        setOpenStandbyConfirmation(true);
      } else if (resp.status === "ok") {
        // console.log(
        //   `succeeded in sending the command to ${selectionModel} miners for ${minerGroup} `,
        //   JSON.stringify(resp)
        // );
        let msg =
          `Successfully processed the command for ${minerGroup}. ` +
          resp.msg;
        setStandbyConfirmationMessage(msg);
        setOpenStandbyConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for setting standby mode for ${selectionModel} in ${minerGroup}`
        );
        let msg =
          `Failed to send the command to the miners of ${minerGroup} ` +
          resp.msg;
        setStandbyConfirmationMessage(msg);
        setOpenStandbyConfirmation(true);
      } else {
        setOpenStandbyConfirmation(true);
        let msg = "";
        if (resp.msg) {
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            resp.msg;
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            JSON.stringify(msgs);
        }
        setOpenStandbyConfirmation(msg);
        console.log(
          `failed to send the command to ${selectionModel} miners for ${minerGroup} ${msg}`
        );
      }
    }
  };

  function handleCloseStatusStandby() {
    setStatusStandby(false);
  }

  function handleClearStandbyMode() {
    setStandbyChecked(false);
  }

  // Flash tab
  const [flashChecked, setFlashChecked] = React.useState(false);
  const [statusFlash, setStatusFlash] = React.useState(false);
  const [openFlashConfirmation, setOpenFlashConfirmation] =
    React.useState(false);
  const [flashConfirmationMessage, setFlashConfirmationMessage] =
    React.useState("");
  const [flashStatusMessage, setFlashStatusMessage] = React.useState("");
  const [getFlashfail, setGetFlashfail] = React.useState(false);
  const [flashJobMessage, setFlashJobMessage] = React.useState({});
  const [flashMultipleJobMessage, setFlashMultipleJobMessage] =
    React.useState([]);

  const handleFlashModeChecked = (event) => {
    setFlashChecked(event.target.checked);
  };

  function handleCloseFlashConfirmation() {
    setOpenFlashConfirmation(false);
  }

  const handleSendFlashMode = async () => {
    // console.log('flash mode for devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    let commandList = [];
    if (flashChecked) {
      commandList = [{ command: "led", code: 102, led1: 4, led2: 4, msg:"Green flashing on both LEDs" }];
    } else {
      commandList = [{ command: "led", code: 2, msg:"Stop LED flashing" }];
    }
    // console.log('commandList', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setFlashConfirmationMessage(
          `Error in sending the command to ${minerGroup} miners`
        );
        console.log(
          `Failed to send the command to ${selectionModel} miners for ${minerGroup}`
        );
        setOpenFlashConfirmation(true);
      } else if (resp.status === "ok") {
        // console.log(
        //   `succeeded in sending the command to ${selectionModel} miners for ${minerGroup} `,
        //   JSON.stringify(resp)
        // );
        let msg =
          `Successfully processed the command for ${minerGroup}. ` +
          resp.msg;
        setFlashConfirmationMessage(msg);
        setOpenFlashConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for setting flash mode for ${selectionModel} in ${minerGroup}`
        );
        let msg =
          `Failed to send the command to the miners of ${minerGroup} ` +
          resp.msg;
        setFlashConfirmationMessage(msg);
        setOpenFlashConfirmation(true);
      } else {
        setOpenFlashConfirmation(true);
        let msg = "";
        if (resp.msg) {
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            resp.msg;
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            JSON.stringify(msgs);
        }
        setOpenFlashConfirmation(msg);
        console.log(
          `failed to send the command to ${selectionModel} miners for ${minerGroup} ${msg}`
        );
      }
    }
  };

  function handleCloseStatusFlash() {
    setStatusFlash(false);
  }

  function handleClearFlashMode() {
    setFlashChecked(true);
  }

  // Tag tab
  const [statusTag, setStatusTag] = React.useState(false);
  const [openTagConfirmation, setOpenTagConfirmation] =
    React.useState(false);
  const [tagConfirmationMessage, setTagConfirmationMessage] =
    React.useState("");
  const [tagStatusMessage, setTagStatusMessage] = React.useState("");
  const [getTagfail, setGetTagfail] = React.useState(false);
  const [tagJobMessage, setTagJobMessage] = React.useState({});
  const [tagMultipleJobMessage, setTagMultipleJobMessage] =
    React.useState([]);

  function handleCloseTagConfirmation() {
    setOpenTagConfirmation(false);
  }

  const handleSendTags = async () => {
    let tmpMinerGroup = minerGroup;
    let commandList = [{ command: "tag", tag: "" }];
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setTagConfirmationMessage(
          `Error in sending the command to ${minerGroup} miners`
        );
        console.log(
          `Failed to send the command to ${selectionModel} miners for ${minerGroup}`
        );
        setOpenTagConfirmation(true);
      } else if (resp.status === "ok") {
        let msg =
          `Successfully processed the command for ${minerGroup}. ` +
          resp.msg;
        setTagConfirmationMessage(msg);
        setOpenTagConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for setting flash mode for ${selectionModel} in ${minerGroup}`
        );
        let msg =
          `Failed to send the command to the miners of ${minerGroup} ` +
          resp.msg;
        setTagConfirmationMessage(msg);
        setOpenTagConfirmation(true);
      } else {
        setOpenTagConfirmation(true);
        let msg = "";
        if (resp.msg) {
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            resp.msg;
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            JSON.stringify(msgs);
        }
        setOpenTagConfirmation(msg);
        console.log(
          `failed to send the command to ${selectionModel} miners for ${minerGroup} ${msg}`
        );
      }
    }
  };

  function handleCloseStatusTag() {
    setStatusTag(false);
  }

  // Retune tab


  const [statusRetune, setStatusRetune] = React.useState(false);
  const [openRetuneConfirmation, setOpenRetuneConfirmation] =
    React.useState(false);
  const [retuneConfirmationMessage, setRetuneConfirmationMessage] =
    React.useState("");
  const [retuneStatusMessage, setRetuneStatusMessage] =
    React.useState("");
  const [getRetunefail, setGetRetunefail] = React.useState(false);
  const [retuneJobMessage, setRetuneJobMessage] = React.useState({});
  const [retuneMultipleJobMessage, setRetuneMultipleJobMessage] = React.useState(
    []
  );


  function handleCloseRetuneConfirmation() {
    setOpenRetuneConfirmation(false);
  }


  const handleSendRetune = async () => {
    // console.log('tuning devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    let commandList = [];
    commandList = [{ command: "fvmode", reTuneTime: Number(reTuneTime)}];
    // console.log('commandList', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setRetuneConfirmationMessage(
          `Error in sending the command to ${minerGroup} miners`
        );
        console.log(
          `Failed to send the command to ${selectionModel} miners for ${minerGroup}`
        );
        setOpenRetuneConfirmation(true);
      } else if (resp.status === "ok") {
        let msg =
          `Successfully processed the command for ${minerGroup}. ` +
          resp.msg;
        setRetuneConfirmationMessage(msg);
        setOpenRetuneConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for setting flash mode for ${selectionModel} in ${minerGroup}`
        );
        let msg =
          `Failed to send the command to the miners of ${minerGroup} ` +
          resp.msg;
        setRetuneConfirmationMessage(msg);
        setOpenRetuneConfirmation(true);
      } else {
        setOpenRetuneConfirmation(true);
        let msg = "";
        if (resp.msg) {
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            resp.msg;
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            JSON.stringify(msgs);
        }
        setOpenRetuneConfirmation(msg);
        console.log(
          `failed to send the command to ${selectionModel} miners for ${minerGroup} ${msg}`
        );
      }
    }
  }

  function handleCloseStatusRetune() {
    setStatusRetune(false);
  }

  // Fans in standby tab

  const [statusFan, setStatusFan] = React.useState(false);
  const [openFanConfirmation, setOpenFanConfirmation] =
    React.useState(false);
  const [fanConfirmationMessage, setFanConfirmationMessage] =
    React.useState("");
  const [fanStatusMessage, setFanStatusMessage] =
    React.useState("");
  const [getFanfail, setGetFanfail] = React.useState(false);
  const [fanJobMessage, setFanJobMessage] = React.useState({});
  const [fanMultipleJobMessage, setFanMultipleJobMessage] = React.useState(
    []
  );


  function handleCloseFanConfirmation() {
    setOpenFanConfirmation(false);
  }

  const handleSendFanInfo = async () => {
    // console.log('tuning devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    let commandList = [];

    commandList = [{ command: "fvmode", fansInStandby: isFanOn ? "on" : "off"}];
    // console.log('commandList', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setFanConfirmationMessage(
          `Error in sending the command to ${minerGroup} miners`
        );
        console.log(
          `Failed to send the command to ${selectionModel} miners for ${minerGroup}`
        );
        setOpenFanConfirmation(true);
      } else if (resp.status === "ok") {
        let msg =
          `Successfully processed the command for ${minerGroup}. ` +
          resp.msg;
        setFanConfirmationMessage(msg);
        setOpenFanConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for setting flash mode for ${selectionModel} in ${minerGroup}`
        );
        let msg =
          `Failed to send the command to the miners of ${minerGroup} ` +
          resp.msg;
        setFanConfirmationMessage(msg);
        setOpenFanConfirmation(true);
      } else {
        setOpenFanConfirmation(true);
        let msg = "";
        if (resp.msg) {
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            resp.msg;
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            JSON.stringify(msgs);
        }
        setOpenFanConfirmation(msg);
        console.log(
          `failed to send the command to ${selectionModel} miners for ${minerGroup} ${msg}`
        );
      }
    }
  }

  function handleCloseStatusFan() {
    setStatusFan(false);
  }

  // Coolant tab

  const [statusCoolant, setStatusCoolant] = React.useState(false);
  const [openCoolantConfirmation, setOpenCoolantConfirmation] =
    React.useState(false);
  const [coolantConfirmationMessage, setCoolantConfirmationMessage] =
    React.useState("");
  const [coolantStatusMessage, setCoolantStatusMessage] =
    React.useState("");
  const [getCoolantfail, setGetCoolantfail] = React.useState(false);
  const [coolantJobMessage, setCoolantJobMessage] = React.useState({});
  const [coolantMultipleJobMessage, setCoolantMultipleJobMessage] = React.useState(
    []
  );


  function handleCloseCoolantConfirmation() {
    setOpenCoolantConfirmation(false);
  }

  const handleSendCoolantInfo = async () => {
    // console.log('tuning devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    let commandList = [];

    commandList = [{ command: "fvmode", coolantDefaultAction: isCoolantOn ? "on" : "off"}];
    // console.log('commandList', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setCoolantConfirmationMessage(
          `Error in sending the command to ${minerGroup} miners`
        );
        console.log(
          `Failed to send the command to ${selectionModel} miners for ${minerGroup}`
        );
        setOpenCoolantConfirmation(true);
      } else if (resp.status === "ok") {
        let msg =
          `Successfully processed the command for ${minerGroup}. ` +
          resp.msg;
        setCoolantConfirmationMessage(msg);
        setOpenCoolantConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for setting flash mode for ${selectionModel} in ${minerGroup}`
        );
        let msg =
          `Failed to send the command to the miners of ${minerGroup} ` +
          resp.msg;
        setCoolantConfirmationMessage(msg);
        setOpenCoolantConfirmation(true);
      } else {
        setOpenCoolantConfirmation(true);
        let msg = "";
        if (resp.msg) {
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            resp.msg;
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            JSON.stringify(msgs);
        }
        setOpenCoolantConfirmation(msg);
        console.log(
          `failed to send the command to ${selectionModel} miners for ${minerGroup} ${msg}`
        );
      }
    }
  }

  function handleCloseStatusCoolant() {
    setStatusCoolant(false);
  }

  // Miner w/o internet tab

  const [statusMinerInternet, setStatusMinerInternet] = React.useState(false);
  const [openMinerInternetConfirmation, setOpenMinerInternetConfirmation] =
    React.useState(false);
  const [minerInternetConfirmationMessage, setMinerInternetConfirmationMessage] =
    React.useState("");
  const [minerInternetStatusMessage, setMinerInternetStatusMessage] =
    React.useState("");
  const [getMinerInternetfail, setGetMinerInternetfail] = React.useState(false);
  const [minerInternetJobMessage, setMinerInternetJobMessage] = React.useState({});
  const [minerInternetMultipleJobMessage, setMinerInternetMultipleJobMessage] = React.useState(
    []
  );


  function handleCloseMinerInternetConfirmation() {
    setOpenMinerInternetConfirmation(false);
  }

  const handleSendMinerInternetInfo = async () => {
    // console.log('tuning devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    let commandList = [];

    commandList = [{ command: "fvmode", miningIfNetDown: isMinerInternetOn ? "on" : "off"}];
    // console.log('commandList', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setMinerInternetConfirmationMessage(
          `Error in sending the command to ${minerGroup} miners`
        );
        console.log(
          `Failed to send the command to ${selectionModel} miners for ${minerGroup}`
        );
        setOpenMinerInternetConfirmation(true);
      } else if (resp.status === "ok") {
        let msg =
          `Successfully processed the command for ${minerGroup}. ` +
          resp.msg;
        setMinerInternetConfirmationMessage(msg);
        setOpenMinerInternetConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for setting flash mode for ${selectionModel} in ${minerGroup}`
        );
        let msg =
          `Failed to send the command to the miners of ${minerGroup} ` +
          resp.msg;
        setMinerInternetConfirmationMessage(msg);
        setOpenMinerInternetConfirmation(true);
      } else {
        setOpenMinerInternetConfirmation(true);
        let msg = "";
        if (resp.msg) {
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            resp.msg;
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            JSON.stringify(msgs);
        }
        setOpenMinerInternetConfirmation(msg);
        console.log(
          `failed to send the command to ${selectionModel} miners for ${minerGroup} ${msg}`
        );
      }
    }
  }

  function handleCloseStatusMinerInternet() {
    setStatusMinerInternet(false);
  }

  // Optimize internet tab

  const [statusEco, setStatusEco] = React.useState(false);
  const [openEcoConfirmation, setOpenEcoConfirmation] =
    React.useState(false);
  const [ecoConfirmationMessage, setEcoConfirmationMessage] =
    React.useState("");
  const [ecoStatusMessage, setEcoStatusMessage] =
    React.useState("");
  const [getEcofail, setGetEcofail] = React.useState(false);
  const [ecoJobMessage, setEcoJobMessage] = React.useState({});
  const [ecoMultipleJobMessage, setEcoMultipleJobMessage] = React.useState(
    []
  );


  function handleCloseEcoConfirmation() {
    setOpenEcoConfirmation(false);
  }

  const handleSendEcoInfo = async () => {
    // console.log('tuning devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    let commandList = [];

    commandList = [{ command: "fvmode", optimizeEco: isEcoOn ? "on" : "off"}];
    // console.log('commandList', commandList)
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setEcoConfirmationMessage(
          `Error in sending the command to ${minerGroup} miners`
        );
        console.log(
          `Failed to send the command to ${selectionModel} miners for ${minerGroup}`
        );
        setOpenEcoConfirmation(true);
      } else if (resp.status === "ok") {
        let msg =
          `Successfully processed the command for ${minerGroup}. ` +
          resp.msg;
        setEcoConfirmationMessage(msg);
        setOpenEcoConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for setting flash mode for ${selectionModel} in ${minerGroup}`
        );
        let msg =
          `Failed to send the command to the miners of ${minerGroup} ` +
          resp.msg;
        setEcoConfirmationMessage(msg);
        setOpenEcoConfirmation(true);
      } else {
        setOpenEcoConfirmation(true);
        let msg = "";
        if (resp.msg) {
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            resp.msg;
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            JSON.stringify(msgs);
        }
        setOpenEcoConfirmation(msg);
        console.log(
          `failed to send the command to ${selectionModel} miners for ${minerGroup} ${msg}`
        );
      }
    }
  }

  function handleCloseStatusEco() {
    setStatusEco(false);
  }

  // Miner Tuning Info

  const [statusMinerTuning, setStatusMinerTuning] = React.useState(false);
  const [openMinerTuningConfirmation, setOpenMinerTuningConfirmation] =
    React.useState(false);
  const [minertuningConfirmationMessage, setMinerTuningConfirmationMessage] =
    React.useState("");
  const [minertuningStatusMessage, setMinerTuningStatusMessage] =
    React.useState("");
  const [getMinerTuningfail, setGetMinerTuningfail] = React.useState(false);
  const [minertuningJobMessage, setMinerTuningJobMessage] = React.useState({});
  const [minertuningMultipleJobMessage, setMinerTuningMultipleJobMessage] = React.useState(
    []
  );

  function handleCloseMinerTuningConfirmation() {
    setOpenMinerTuningConfirmation(false);
  }

  const handleSendMinerTuningInfo = async () => {
    let tmpMinerGroup = minerGroup;
    let commandList = [];
    if (ecoChecked) {
      commandList = [{ command: "fvmode", mode: "eco"}];
    } else if (turboChecked) {
      commandList = [{ command: "fvmode", mode: "turbo"}];
    } else if (thsChecked) {
      commandList = [{ command: "fvmode", mode: "custom", tune: "ths", ths: Number(thsInput)}];
    } else if (powerChecked) {
      commandList = [{ command: "fvmode", mode: "custom", tune: "power", power: Number(powerInput)}];
    }
    // console.log("commandList", commandList);
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = tmpMinerGroup;
      dgdata.commands = commandList;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.SendGroupCommands(token, dgdata);
      if (resp === undefined || resp === null) {
        setMinerTuningConfirmationMessage(
          `Error in sending the command to ${minerGroup} miners`
        );
        console.log(
          `Failed to send the command to ${selectionModel} miners for ${minerGroup}`
        );
        setOpenMinerTuningConfirmation(true);
      } else if (resp.status === "ok") {
        let msg =
          `Successfully processed the command for ${minerGroup}. ` +
          resp.msg;
        setMinerTuningConfirmationMessage(msg);
        setOpenMinerTuningConfirmation(true);
      } else if (resp.status === "error") {
        console.log(
          `status: error in response for setting flash mode for ${selectionModel} in ${minerGroup}`
        );
        let msg =
          `Failed to send the command to the miners of ${minerGroup} ` +
          resp.msg;
        setMinerTuningConfirmationMessage(msg);
        setOpenMinerTuningConfirmation(true);
      } else {
        setOpenMinerTuningConfirmation(true);
        let msg = "";
        if (resp.msg) {
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            resp.msg;
        } else {
          let msgs = fetchMsgForKeys(resp);
          msg =
            `Failed to send the command to the miners of ${minerGroup} ` +
            JSON.stringify(msgs);
        }
        setOpenMinerTuningConfirmation(msg);
        console.log(
          `failed to send the command to ${selectionModel} miners for ${minerGroup} ${msg}`
        );
      }
    }
  }

  function handleCloseStatusMinerTuning() {
    setStatusMinerTuning(false);
  }

  // Alert Notification Tab

  const [hashboardMalfChecked, setHashboardMalfChecked] = React.useState(true);
  const [fanMalfChecked, setFanMalfChecked] = React.useState(true);
  const [PSUMalfChecked, setPSUMalfChecked] = React.useState(true);
  const [chipTempChecked, setChipTempChecked] = React.useState(true);
  const [thresholdValue, setThresholdValue] = React.useState("");
  const [openAlertConfirmation, setOpenAlertConfirmation] =
    React.useState(false);
  const [alertConfirmationMessage, setAlertConfirmationMessage] =
    React.useState("");

  function handleDesiredAlertInterval(event) {
    const value = event.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(value)) {
      setDesiredAlertInterval(value);
    }
  }

  function handleUpdateAlertMembers(value) {
    setAlertMembers(value);
    setSelectAllAlertMembers(value.length === members.length);
  }

  function handleHashboardMalfChecked(event) {
    setHashboardMalfChecked(event.target.checked);
  }

  function handleFanMalfChecked(event) {
    setFanMalfChecked(event.target.checked);
  }

  function handlePSUMalfChecked(event) {
    setPSUMalfChecked(event.target.checked);
  }

  function handleChipTempChecked(event) {
    setChipTempChecked(event.target.checked);
  }

  function handleThresholdValueInput(event) {
    const value = event.target.value;
    const regex = /^[0-9.]*$/;
    if (regex.test(value)) {
      setThresholdValue(value);
    }
  }

  function handleNumberOfChipsOverThresholdInput(event) {
    const value = event.target.value;
    const regex = /^[0-9]*$/;
    if (regex.test(value)) {
      setNumberOfChipsOverThreshold(value);
    }
  }

  const handleSelectAllAlertMembers = (event) => {
    const allOptions = members;
    const selectedOptions = event.target.checked ? allOptions : [];
    setAlertMembers(selectedOptions);
    setSelectAllAlertMembers(event.target.checked);
  };

  function handleClearSelections() {
    setHashboardMalfChecked(false);
    setFanMalfChecked(false);
    setPSUMalfChecked(false);
    setChipTempChecked(false);
  }

  function handleCloseAlertConfirmation() {
    setOpenAlertConfirmation(false);
  }

  const handleSaveAlertConfig = async () => {
    // console.log('alert notifs for devices:', selectionModel);
    let tmpMinerGroup = minerGroup;
    let alertNotificationConfig = {
      fanMalfunction: hashboardMalfChecked,
      hashboardMalfunction: fanMalfChecked,
      chipTempMalfunction: chipTempChecked,
      psuMalfunction: PSUMalfChecked,
      chipThreshold: parseFloat(thresholdValue),
    };
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      dgdata.dgname = minerGroup;
      dgdata.alertNotificationConfig = alertNotificationConfig;
      dgdata.alertmember = alertMembers;
      dgdata.numberOfChipsOverThreshold = parseInt(numberOfChipsOverThreshold);
      if (
        isNaN(dgdata.numberOfChipsOverThreshold) ||
        dgdata.numberOfChipsOverThreshold <= 0
      ) {
        dgdata.numberOfChipsOverThreshold = 0;
      }
      dgdata.desiredAlertInterval = parseInt(desiredAlertInterval);
      if (isNaN(dgdata.desiredAlertInterval)) {
        setAlertConfirmationMessage(
          `Please enter a valid desired alert interval`
        );
        setOpenAlertConfirmation(true);
        return;
      }
      const resp = await api.SendAlertNotificationConfig(token, dgdata);
      if (resp === undefined || resp === null) {
        console.log(
          `Failed to send the alert notifs request to ${selectionModel} miners for ${tmpMinerGroup}`
        );
        setAlertConfirmationMessage(
          `Error in saving the alert notification config to the selected miners in ${tmpMinerGroup}`
        );
        setOpenAlertConfirmation(true);
      } else if (resp.status === "ok") {
        // console.log(
        //   `succeeded in sending the alert notifs request to ${selectionModel} miners for ${tmpMinerGroup} `,
        //   JSON.stringify(resp)
        // );
        let msg = `Successfully saved the alert notification config for the selected miners in ${tmpMinerGroup} `;
        setAlertConfirmationMessage(msg);
        setOpenAlertConfirmation(true);
      } else {
        let msg =
          `Failed to save the alert notification config for the selected miners in ${tmpMinerGroup} ` +
          resp.msg;
        setAlertConfirmationMessage(msg);
        setOpenAlertConfirmation(true);
      }
    }
  };

  function handleClearAlertConfig() {
    setDesiredAlertInterval("");
    setHashboardMalfChecked(false);
    setFanMalfChecked(false);
    setPSUMalfChecked(false);
    setChipTempChecked(false);
    setAlertMembers([]);
  }

  // Migrate Miners Tab

  const [destinationGroup, setDestinationGroup] = React.useState(true);
  const [openMigrateMinersConfirmation, setOpenMigrateMinersConfirmation] =
    React.useState(false);
  const [
    migrateMinersConfirmationMessage,
    setMinersMigrateConfirmationMessage,
  ] = React.useState("");

  function handleSelectDestinationGroup(event) {
    setDestinationGroup(event.target.value);
  }

  function handleClearMigrateMiners() {
    setDestinationGroup("");
  }

  const handleMigrateMigrateMiners = async () => {
    // console.log('SOURCE MINER GROUP', minerGroup);
    // console.log('DESTINATION MINER GROUP', destinationGroup);
    // console.log('MOVING DEVICES:', selectionModel)
    let tmpMinerGroup = minerGroup;
    let tmpTargetGroup = destinationGroup;
    if (minerGroup !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.sourcegroup = tmpMinerGroup;
      dgdata.destinationgroup = tmpTargetGroup;
      dgdata.filter = searchStrings;
      if (globalNotSelectionModel.length > 0) {
        dgdata.serials = globalSelectionModel;
        if (globalSelectionModel.length === count) {
          dgdata.serials = []
        }
      } else {
        dgdata.serials = [];
      }
      const resp = await api.MoveDGMember(token, dgdata);
      if (resp === undefined || resp === null) {
        setMinersMigrateConfirmationMessage(
          `Error in migrating miners from ${minerGroup} to ${destinationGroup}`
        );
        console.log(
          `failed to move ${selectionModel} miners from ${minerGroup} to ${destinationGroup}`
        );
        setOpenMigrateMinersConfirmation(true);
      } else if (resp.status === "ok") {
        // console.log(
        //   `succeeded in moving ${selectionModel} miners from ${minerGroup} to ${destinationGroup}`,
        //   JSON.stringify(resp)
        // );
        setMinersMigrateConfirmationMessage(
          `Successfully migrated miners from ${minerGroup} to ${destinationGroup}`
        );
        setOpenMigrateMinersConfirmation(true);
        setSearchStrings({ ...searchStrings, dgname: minerGroup });
      } else {
        setMinersMigrateConfirmationMessage(
          `Failed to migrate miners from ${minerGroup} to ${destinationGroup}`
        );
        console.log(
          `failed to move ${selectionModel} miners from ${minerGroup} to ${destinationGroup}`
        );
        setOpenMigrateMinersConfirmation(true);
      }
    }
  };

  function handleCloseMigrateMinersConfirmation() {
    setOpenMigrateMinersConfirmation(false);
  }

  //general actions handle functions

  function handleClickOpenActions() {
    setOpenOperationsPopup(true);
  }

  function handleCloseActions() {
    console.log("Close the actions");
    setOpenOperationsPopup(false);
    if (role === "pooladmin" || role === "superadmin") {
      setActionValue("0");
    } else {
      setActionValue("1");
    }
    setURLInput("");
    handleClearSelections();
    setSelectAllAlertMembers(false);
    setUpgradeChecked(true);
    setURLChecked(false);
    setRollingUpgradeChecked(true);
    setRollingURLChecked(false);
    setTuningChecked(false);
    setStandbyChecked(false);
    setFlashChecked(false);
    setFWJobMessage("");
    setTuneJobMessage("");
    setStandbyJobMessage("");
    setStandbyMultipleJobMessage("");
    setTuneMultipleJobMessage("");
    setUpgradeConfirmationMessage("");
    setTransferMinerConfirmationMessage("");
    setTuningConfigStatusMessage("");
    setStandbyConfirmationMessage("");
    setGetFWfail(false);
    setGetTunefail(false);
    setGetSleepfail(false);
    setNumberOfChipsOverThreshold("");
    setThresholdValue("");
    setDestinationGroup("");
    /* Do it as if unselect all miners is selected */
    setSelectionModel([]);
    globalSelectionModel = [];
    globalNotSelectionModel = [];
    setStopRefresh(false);
    setSelectAllMiners(false);
    // setAlertMembers(initialAlertMembers);
    // setDesiredAlertInterval(initialDesiredAlertInterval);
  }

  React.useEffect(() => {
    setURLInput("");
    handleClearSelections();
    setTuningChecked(false);
    setStandbyChecked(false);
    setFlashChecked(false);
    setFWJobMessage("");
    setTuneJobMessage("");
    setStandbyJobMessage("");
    setTuneMultipleJobMessage("");
    setUpgradeConfirmationMessage("");
    setTuningConfigStatusMessage("");
    setStandbyConfirmationMessage("");
    setStandbyMultipleJobMessage("");
    setGetFWfail(false);
    setGetTunefail(false);
    setGetSleepfail(false);
    setNumberOfChipsOverThreshold("");
    setThresholdValue("");
    setDestinationGroup("");
    // setAlertMembers(initialAlertMembers);
    // setDesiredAlertInterval(initialDesiredAlertInterval);
  }, [actionvalue]);

  /// MANAGE MINERS BUTTON

  const [manage, setManage] = React.useState(false);
  const [managevalue, setManageValue] = React.useState("0");

  function handleClickOpenManage() {
    setManage(true);
  }

  function handleCloseManage() {
    setManage(false);
    setManageValue("0");
    setDeleteGroups([]);
    setNewGroup("");
  }

  React.useEffect(() => {
    setDeleteGroups([]);
    setNewGroup("");
  }, [managevalue]);

  const handleValueChange1 = (event, newValue) => {
    setManageValue(newValue);
  };

  //create group

  const [newGroup, setNewGroup] = React.useState("");
  const [openCreateConfirmation, setOpenCreateConfirmation] =
    React.useState(false);
  const [createConfirmationMessage, setCreateConfirmationMessage] =
    React.useState("");

  function handleNewGroupInput(event) {
    const value = event.target.value;
    const regex = /^[a-zA-Z0-9- ]*$/;
    if (regex.test(value)) {
      setNewGroup(value);
    }
  }

  function handleClearCreate() {
    setNewGroup("");
  }


  const handleRetuneTime = (event) => {
    const value = event.target.value;
    if (value === "" || (value >= 30 && value <= 2880)) {
      setRetuneTime(value)
      setRetuneError(false);
    } else {
      setRetuneError(true);
      if (value >= 0) {
        setRetuneTime(value);
      } else {
        setRetuneTime("")
      }
    }
  };

const setFanAction = async (event) => {
  setIsFanOn(event.target.checked);  // Update fan toggle state
}

const setCoolantAction = async (event) => {
  setIsCoolantOn(event.target.checked);
}

const setMinerInternetAction = async (event) => {
  setIsMinerInternetOn(event.target.checked);
}

const setEcoAction = async (event) => {
  setIsEcoOn(event.target.checked);
}

  const handleCreateCreate = async () => {
    const normalizedinputDGValue = newGroup.replace(/\s+/g, " ");
    // console.log("normalizedinpit", normalizedinputDGValue);
    if (normalizedinputDGValue.trim() !== "") {
      const token = await getAccessTokenSilently();
      let dgdata = {};
      dgdata.dgname = normalizedinputDGValue;
      const resp = await api.AddDG(token, dgdata);
      if (resp === undefined) {
        setOpenCreateConfirmation(true);
        setCreateConfirmationMessage("Failed to create group.");
      } else {
        // console.log("creating group ", JSON.stringify(resp));
        if (resp.status === "error") {
          setOpenCreateConfirmation(true);
          setCreateConfirmationMessage(resp.msg);
        } else {
          setOpenCreateConfirmation(true);
          setCreateConfirmationMessage(
            `Successfully created group: ${dgdata.dgname}`
          );
          // console.log(
          //   `creating group ${normalizedinputDGValue} was successful`
          // );
        }
      }
    }
    setNewGroup("");

    const token = await getAccessTokenSilently();
    const deviceGroups = await api.GetAllDeviceGroups(token);
    if (deviceGroups === undefined) {
      setMinerGroups([]);
    } else {
      var minerGroupList = [];
      for (let i = 0; i < deviceGroups.length; i++) {
        minerGroupList.push(deviceGroups[i].DGName);
      }
      minerGroupList.sort();
      setMinerGroups(minerGroupList);
    }
  };

  function handleCloseCreateConfirmation() {
    setOpenCreateConfirmation(false);
  }

  //delete group

  const [openDeleteConfirmation, setOpenDeleteConfirmation] =
    React.useState(false);
  const [deleteConfirmationMessage, setDeleteConfirmationMessage] =
    React.useState("");

  function handleClearDelete() {
    // console.log("handleClearDelete called");
    setDeleteGroups([]);
  }

  function handleSelectDeleteMiners(value) {
    setDeleteGroups(value);
  }

  const handleDeleteDelete = async () => {
    try {
      const token = await getAccessTokenSilently();
      const dgData = {};
      if (deleteGroups.length === 0) {
        setOpenDeleteConfirmation(true);
        setDeleteConfirmationMessage("Please select a group to delete");
        return;
      }
      dgData.dgname = deleteGroups;
      const response = await api.DeleteDG(token, dgData);
      if (response === undefined || response === null) {
        setOpenDeleteConfirmation(true);
        setDeleteConfirmationMessage(
          `Failed to delete group(s) ${dgData.dgname}`
        );
      } else {
        console.log("DeleteDG response", response.status, response.msg);
        if (response.status === "error" || response.status === "ok") {
          setOpenDeleteConfirmation(true);
          if (response.msg.success.length > 0) {
            if (response.msg.success.length === 1) {
              setDeleteConfirmationMessage(
                "Successfully deleted group: " + response.msg.success
              );
            } else {
              setDeleteConfirmationMessage(
                "Successfully deleted groups: " + response.msg.success
              );
            }
            setMinerGroup("");
            setIsMinerGroupSelected(false);
            const urlParams = new URLSearchParams(location.search);
            urlParams.set("dgname", "");
            history.replace({ search: urlParams.toString() });
            localStorage.setItem(
              "dl-urlParams",
              JSON.stringify(Object.fromEntries(urlParams.entries()))
            );
            setSearchStrings({ ...searchStrings, dgname: "" });
          }
          if (response.msg.moveMiners.length > 0) {
            if (response.msg.moveMiners.length === 1) {
              setDeleteConfirmationMessage(
                "Failed to delete non empty group: " + response.msg.moveMiners
              );
            } else {
              setDeleteConfirmationMessage(
                "Failed to delete non empty groups: " + response.msg.moveMiners
              );
            }
          }
          if (response.msg.failure.length > 0) {
            if (response.msg.failure.length === 1) {
              setDeleteConfirmationMessage(
                "Failed to delete group: " + response.msg.failure
              );
            } else {
              setDeleteConfirmationMessage(
                "Failed to delete groups: " + response.msg.failure
              );
            }
          }
          if (response.msg.notFound.length > 0) {
            if (response.msg.notFound.length === 1) {
              setDeleteConfirmationMessage(
                "Group not found: " + response.msg.notFound
              );
            } else {
              setDeleteConfirmationMessage(
                "Groups not found: " + response.msg.notFound
              );
            }
          }
          if (response.msg.default.length > 0) {
            if (response.msg.default.length === 1) {
              setDeleteConfirmationMessage(response.msg.default);
            } else {
              setDeleteConfirmationMessage(
                "Cannot delete groups " + response.msg.default
              );
            }
          }
          setDeleteGroups([]);
          const token = await getAccessTokenSilently();
          const deviceGroups = await api.GetAllDeviceGroups(token);
          if (deviceGroups === undefined) {
            setMinerGroups([]);
          } else {
            var minerGroupList = [];
            for (let i = 0; i < deviceGroups.length; i++) {
              minerGroupList.push(deviceGroups[i].DGName);
            }
            minerGroupList.sort();
            setMinerGroups(minerGroupList);
          }
        } else {
          console.error(
            `Error in deleting group(s) ${dgData.dgname}`,
            JSON.stringify(response.msg)
          );
          setOpenDeleteConfirmation(true);
          setDeleteGroups([]);
          if (dgData.dgname.length === 1) {
            setDeleteConfirmationMessage(
              `Error in deleting group ${dgData.dgname}`
            );
          } else {
            setDeleteConfirmationMessage(
              `Error in deleting groups ${dgData.dgname}`
            );
          }
        }
      }
    } catch (error) {
      setDeleteConfirmationMessage(`Error in deleting one or more groups`);
      setOpenDeleteConfirmation(true);
      setDeleteGroups([]);
      console.error("Error deleting groups:", error);
    }
  };

  function handleCloseDeleteConfirmation() {
    setOpenDeleteConfirmation(false);
  }
  if (validUser === 0) {
    return (
      <PageContainer title="Miner" description="this is the miner operations/status page">
      <Box
        sx={{
          "& .rowGreen": {
            bgcolor: (theme) =>
              getBackgroundColor("#FFA500", theme.palette.mode),
          },
        }}
      >
        <Typography
          variant="h4"
          component="div"
          sx={{ flexGrow: 1, textAlign: "center", color: "#007bff" }}
        >
          Miner Operations and Status
        </Typography>
        <br />
        <br />
        <Paper elevation={2} sx={{ width: "99%", padding: "24px 24px", borderRadius: "1rem" }}>
          <Grid container spacing={6}>
            <Grid item xs={5.5} sx={{ mb: 3 }}>
              <Box display={"flex"} flexDirection={"column"} gap={0.5}>
                <Stack direction="column">
                  <Typography
                    variant="p"
                    component="div"
                    fontWeight={"bold"}
                  >
                    Miner Group
                  </Typography>
                </Stack>
                <Box display={"flex"} flexDirection={"row"} gap={2}>
                  <TextField
                    helperText="Selection required to perform miner operations"
                    value={searchStrings.dgname}
                    onChange={handleDGNameChange}
                    fullWidth
                    sx={{
                      "& .MuiInputLabel-root": { fontSize: "13.5px" },
                    }}
                    select
                  >
                  <MenuItem value="">Select</MenuItem>
                  {minerGroups.map((group, index) => (
                    <MenuItem
                      key={index}
                      value={group}
                    >
                      {group}
                    </MenuItem>
                  ))}
                  </TextField>
                  { role === "superadmin" || role === "pooladmin" || role === "admin" ? (
                  <Button
                    variant="contained"
                    disabled={selectionModel.length === 0}
                    onClick={handleClickOpenActions}
                    sx={{ ml: 6, mt: 2, fontSize: "11px", lineHeight: "1.4" }}
                  >
                    Miner Operations
                  </Button>
                  ) : null}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={0.5} sx={{ mb: 3 }}></Grid>
            <Grid item xs={3.5} sx={{ mb: 3 }}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                flexWrap={"wrap"}
                gap={0.5}
              >
              <Stack direction="column">
                  <Typography
                    variant="p"
                    component="div"
                    fontWeight={"bold"}
                    sx={{ ml: 4 }}
                  >
                    Refresh Interval
                  </Typography>
                </Stack>
                <RefreshInterval
                  value={timegap}
                  callback={handleInterval}
                  name={`Last Refresh: ${lastRefresh}`}
                />
              </Box>
            </Grid>
            { role === "superadmin" || role === "pooladmin" || role === "admin" ? (
            <Grid item xs={2.5} sx={{ mb: 3 }}>
              <Box
                display={"flex"}
                flexDirection={"row"}
                flexWrap={"wrap"}
                justifyContent={"flex-end"}
                gap={0.5}
              >
                <Button
                  variant="contained"
                  onClick={handleClickOpenManage}
                  sx={{ mt: 7, fontSize: "11px", lineHeight: "1.4" }}
                >
                  Manage Groups
                </Button>
              </Box>
            </Grid>
            ) : null}
          </Grid>
          <Stack direction="row" alignItems="center" sx={{ mb: 3, ml: 2 }}>
            {techSupport === false && displaytable !== null && (
              <Dialog open={openColPopup} onClose={handleCloseSettings}>
                <DialogTitle sx={{ fontSize: "20px" }}>
                  <Box>
                    Column Settings
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "normal",
                        display: "block",
                        marginTop: "-2px",
                        color: "dark gray",
                      }}
                    >
                      Drag and Drop the Columns to Rearrange
                    </Typography>
                  </Box>
                </DialogTitle>
                <DialogContent>
                  <List
                    values={colOrder}
                    onChange={({ oldIndex, newIndex }) =>
                      setColOrder(arrayMove(colOrder, oldIndex, newIndex))
                    }
                    renderList={({ children, props, isDragged }) => (
                      <ul
                        {...props}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          cursor: isDragged ? "grabbing" : undefined,
                        }}
                      >
                        {children}
                      </ul>
                    )}
                    renderItem={({ value, props, isDragged }) => {
                      return (
                        <Typography
                          variant="subtitle1"
                          sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: isDragged ? "bold" : "regular",
                            color: isDragged
                              ? "#1967d2"
                              : visibleCols.includes(value)
                              ? "black"
                              : "silver",
                            zIndex: 9999,
                            position: "relative",
                            cursor: isDragged ? "grabbing" : "grab",
                          }}
                          {...props}
                        >
                          {value}
                        </Typography>
                      );
                    }}
                  />
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                  <Button onClick={handleCloseSettings}>Reorder</Button>
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button onClick={handleResetDBPreferences}>
                    Reset Settings
                  </Button>
                  <Button onClick={handleSaveColumnSettings}>
                    Save settings
                  </Button>
                </DialogActions>
              </Dialog>
            )}
            {techSupport === true && displaytable !== null && (
              <Dialog open={openColPopup} onClose={handleCloseSettings}>
                <DialogTitle sx={{ fontSize: "20px" }}>
                  <Box>
                    Column Settings
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "normal",
                        display: "block",
                        marginTop: "-2px",
                        color: "darkgray",
                      }}
                    >
                      Drag and Drop the Columns to Rearrange
                    </Typography>
                  </Box>
                </DialogTitle>
                <DialogContent>
                  <List
                    values={colOrderCS}
                    onChange={({ oldIndex, newIndex }) =>
                      setColOrderCS(arrayMove(colOrderCS, oldIndex, newIndex))
                    }
                    renderList={({ children, props, isDragged }) => (
                      <ul
                        {...props}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          cursor: isDragged ? "grabbing" : undefined,
                        }}
                      >
                        {children}
                      </ul>
                    )}
                    renderItem={({ value, props, isDragged }) => {
                      return (
                        <Typography
                          variant="subtitle1"
                          sx={{
                            flexGrow: 1,
                            textAlign: "center",
                            fontSize: "14px",
                            fontWeight: isDragged ? "bold" : "regular",
                            color: isDragged
                              ? "#1967d2"
                              : visibleColsCS.includes(value)
                              ? "black"
                              : "silver",
                            zIndex: 9999,
                            position: "relative",
                            cursor: isDragged ? "grabbing" : "grab",
                          }}
                          {...props}
                        >
                          {value}
                        </Typography>
                      );
                    }}
                  />
                </DialogContent>
                <DialogActions style={{ justifyContent: "center" }}>
                  <Button onClick={handleCloseSettings}>Reorder</Button>
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button onClick={handleResetDBPreferences}>
                    Reset Settings
                  </Button>
                  <Button onClick={handleSaveColumnSettingsCS}>
                    Save settings
                  </Button>
                </DialogActions>
              </Dialog>
            )}
          </Stack>
          <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Box display={"flex"} flexDirection={"column"} flexWrap={"wrap"} gap={0.5}>
                    <Stack direction="column">
                      <Typography variant="p" component="div" fontWeight={"bold"}>
                        Global Filters
                      </Typography>
                    </Stack>
                    <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} gap={2}>
            {techSupport === true && displaytable !== null && (
              <Tooltip title="CS Tag">
                <TextField
                  label="CS Tag"
                  value={searchStrings.csTag}
                  onChange={handleCSTagChangeFilter}
                  sx={{
                    flex: 1,
                    maxWidth: "100px",
                    minWidth: "80px",
                    "& .MuiInputLabel-root": { fontSize: "12px" },
                  }}
                />
              </Tooltip>
            )}
            {techSupport === true && displaytable !== null && (
            <Tooltip title="OrgName">
            <TextField
              label="OrgName"
              value={searchStrings.org_name}
              onChange={handleOrgNameChange}
              sx={{
                flex: 1,
                minWidth: "80px",
                maxWidth: "100px",
                "& .MuiInputLabel-root": { fontSize: "12px" },
              }}
            />
          </Tooltip>
            )}
            <Tooltip title="Chassis">
              <TextField
                label="Chassis"
                value={searchStrings.chassis}
                onChange={handleChassisChange}
                sx={{
                  flex: 1,
                  minWidth: "80px",
                  maxWidth: "100px",
                  "& .MuiInputLabel-root": { fontSize: "12px" },
                }}
              />
            </Tooltip>
            <Tooltip title="CB Serial">
              <TextField
                label="CB Serial"
                value={searchStrings.CBSN}
                onChange={handleCBSNChange}
                sx={{
                  flex: 1,
                  minWidth: "80px",
                  maxWidth: "100px",
                  "& .MuiInputLabel-root": { fontSize: "12px" },
                }}
              />
            </Tooltip>
            <Tooltip title="Version">
              <TextField
                label="Version"
                value={searchStrings.version}
                onChange={handleVersionChange}
                sx={{
                  flex: 1,
                  minWidth: "80px",
                  maxWidth: "100px",
                  "& .MuiInputLabel-root": { fontSize: "12px" },
                }}
              />
            </Tooltip>
            <Tooltip title="Model">
              <TextField
                label="Model"
                value={searchStrings.model}
                onChange={handleModelChange}
                sx={{
                  flex: 1,
                  minWidth: "80px",
                  maxWidth: "100px",
                  "& .MuiInputLabel-root": { fontSize: "12px" },
                }}
              />
            </Tooltip>
            <Tooltip>
              <TextField
                label="State"
                value={searchStrings.state}
                onChange={handleStateChange}
                sx={{
                  flex: 1,
                  minWidth: "80px",
                  maxWidth: "100px",
                  "& .MuiInputLabel-root": { fontSize: "12px" },
                }}
                select
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Connected">Connected</MenuItem>
                <MenuItem value="Disconnected">Disconnected</MenuItem>
                <MenuItem value="Onboarded">Onboarded</MenuItem>
              </TextField>
            </Tooltip>
            <Tooltip title="IP Address">
              <TextField
                label="IP Address"
                value={searchStrings.ipAddress}
                onChange={handleIPAddressChange}
                sx={{
                  flex: 1,
                  minWidth: "80px",
                  maxWidth: "100px",
                  "& .MuiInputLabel-root": { fontSize: "12px" },
                }}
              />
            </Tooltip>
            <Tooltip>
              <TextField
                label="LED Status"
                value={searchStrings.led}
                onChange={handleLedChange}
                sx={{
                  flex: 1,
                  minWidth: "80px",
                  maxWidth: "100px",
                  "& .MuiInputLabel-root": { fontSize: "12px" },
                }}
                select
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="Normal">Normal</MenuItem>
                <MenuItem value="Tuning">Tuning</MenuItem>
                <MenuItem value="Pool configuration invalid">
                  Pool configuration invalid
                </MenuItem>
                <MenuItem value="Standby Mode">Standby Mode</MenuItem>
                <MenuItem value="Fan malfunction">Fan malfunction</MenuItem>
                <MenuItem value="PSU malfunction">PSU malfunction</MenuItem>
                <MenuItem value="Hash board malfunction">
                  Hash board malfunction
                </MenuItem>
                <MenuItem value="Hash rate lower than target">
                  Hash rate lower than target
                </MenuItem>
                <MenuItem value="Temperature too high">
                  Temperature too high
                </MenuItem>
                <MenuItem value="Low coolant">
                  Low coolant
                </MenuItem>
                <MenuItem value="Control board faulty">
                  Control board faulty
                </MenuItem>
                <MenuItem value="Locate miner">Locate miner</MenuItem>
                <MenuItem value="Normal, reduced hash rate (high temp)">
                  Normal, reduced hash rate (high temp)
                </MenuItem>
                <MenuItem value="Normal, reduced hash rate (power limit)">
                  Normal, reduced hash rate (power limit)
                </MenuItem>
                <MenuItem value="SD card faulty">
                  SD card faulty
                </MenuItem>
              </TextField>
            </Tooltip>
            <Tooltip title="Power (Ex:1000-4000)">
              <TextField
                label="Power"
                value={searchStrings.power}
                onChange={handlePowerChange}
                sx={{
                  flex: 1,
                  minWidth: "80px",
                  maxWidth: "100px",
                  "& .MuiInputLabel-root": { fontSize: "12px" },
                }}
              />
            </Tooltip>
            <Tooltip title="Chip (Ex:50-90 in °C)">
              <TextField
                label="Chip"
                value={searchStrings.temperature}
                onChange={handleTemperatureChange}
                sx={{
                  flex: 1,
                  minWidth: "80px",
                  maxWidth: "100px",
                  "& .MuiInputLabel-root": { fontSize: "12px" },
                }}
              />
            </Tooltip>
            <Tooltip title="THs (Ex:120-150)">
              <TextField
                label="THs"
                value={searchStrings.ths}
                onChange={handleTHsChange}
                sx={{
                  flex: 1,
                  minWidth: "80px",
                  maxWidth: "100px",
                  "& .MuiInputLabel-root": { fontSize: "12px" },
                }}
              />
            </Tooltip>
            <Tooltip title="JTHs (Ex:10-20)">
              <TextField
                label="JTHs"
                value={searchStrings.jths}
                onChange={handleJTHsChange}
                sx={{
                  flex: 1,
                  minWidth: "80px",
                  maxWidth: "100px",
                  "& .MuiInputLabel-root": { fontSize: "12px" },
                }}
              />
            </Tooltip>
            {techSupport === false && displaytable !== null && (
              <Tooltip title="Tag">
                <TextField
                  label="Tag"
                  value={searchStrings.name}
                  onChange={handleCustomNameFilterChange}
                  sx={{
                    flex: 1,
                    minWidth: "80px",
                    maxWidth: "100px",
                    "& .MuiInputLabel-root": { fontSize: "12px" },
                  }}
                />
              </Tooltip>
            )}
            <IconButton sx={{ ml: 1.2 }} onClick={onClear} color="primary">
              <ClearIcon />
            </IconButton>
            </Box>
                  </Box>
                </Grid>
          </Grid>
          <br />
          <br />
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mr: -4,
                }}
              >
              <Stack
                direction="row"
                sx={{ marginLeft: "auto", alignItems: "center", mr: 4 }}
              >
                {role === "superadmin" && (
                <>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                    gap={0.5}
                  >
                    <Button
                      variant="outlined"
                      onClick={handleShowTransferJobs}
                      sx={{ mr: 2, fontSize: "11px", marginLeft: "auto" }}
                      // disabled={!showTransferJobs}
                    >
                      Show Transfer Jobs
                    </Button>
                  </Box>
                  <Dialog
                    open={minerTransfer}
                    onClose={handleMinerTransferClose}
                    fullWidth
                    maxWidth="none"
                    PaperProps={{
                      sx: {
                        width: "90vw",
                        maxWidth: "90vw",
                        margin: "auto",
                        height: "80vh",
                        maxHeight: "90vh",
                      }
                    }}
                  >
                    <DialogTitle sx={{ fontSize: "20px" }}>
                      <Box>List of Miner Transfer Jobs</Box>
                    </DialogTitle>
                    <DialogContent sx={{ flexGrow: 1 }}>
                      <Box sx={{ height: "65vh", width: "100%", overflow: "auto" }}>
                        <DataGrid
                          rows={transferMiners}
                          columns={jobColumns}
                          getRowId={(row) => row.jobid}
                          pagination
                          paginationMode="client"
                          disableSelectionOnClick
                        />
                      </Box>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                      <Button onClick={handleAcceptMinerTransfer} disabled={transferMiners.length === 0}>
                        Accept
                      </Button>
                      <Button onClick={handleRejectMinerTransfer} disabled={transferMiners.length === 0}>
                        Reject
                      </Button>
                      <Button onClick={handleMinerTransferClose}>
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                    gap={0.5}
                  >
                    <Button
                      variant="outlined"
                      onClick={handleRemoveMiners}
                      sx={{ mr: 2, fontSize: "11px", marginLeft: "auto" }}
                      disabled={!(isminerGroupSelected && selectionModel.length > 0 && selectAllMiners === false)}
                    >
                      Remove Miners from FV
                    </Button>
                  </Box>
                  <Dialog
                    open={minerRemoveOperation}
                    onClose={handleMinerRemoveOperationNO}
                    fullWidth
                    maxWidth="none"
                    PaperProps={{
                      sx: {
                        width: "80vw",
                        maxWidth: "80vw",
                        margin: "auto",
                        height: "30vh",
                        maxHeight: "40vh",
                      }
                    }}
                  >
                    <DialogTitle sx={{ fontSize: "18px" }}>
                      <Box>Do you really want to remove the selected miners from FluxVision?</Box>
                    </DialogTitle>
                    <DialogContent sx={{ flexGrow: 1 }}>
                      <Box sx={{ height: "40vh", width: "100%", overflow: "auto" }}>
                        Please confirm the removal of miners. This operation cannot be undone.
                        If you are not sure, please click on "Close".
                        If you remove and want to get the miners back, please contact Auradine support team.
                      </Box>
                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center" }}>
                      <Button onClick={handleMinerRemoveOperationYES} sx={{fontSize: "14px"}}>
                        Proceed
                      </Button>
                      <Button onClick={handleMinerRemoveOperationNO} sx={{fontSize: "14px"}}>
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                </>
                )}

                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  gap={0.5}
                >
                  <Button
                  variant="outlined"
                  disabled={!isminerGroupSelected}
                  onClick={exportToCsv}
                  sx={{ mr: 2, fontSize: "11px", marginLeft: "auto" }}
                  >
                  Export To CSV
                  </Button>
                </Box>
                <Dialog
                    open={csvOpen}
                    onClose={handleCsvClose}
                    fullWidth
                    maxWidth={false}
                    PaperProps={{
                        sx: {
                            width: "800px",
                            maxWidth: "800px",
                            margin: "auto",
                            height: "70vh",
                            maxHeight: "90vh",
                        }
                    }}
                  >
                  <DialogTitle sx={{ fontSize: "20px" }}>
                    <Box>
                      Export Miner Stats to CSV
                    </Box>
                  </DialogTitle>
                  <DialogContent>
                  <MultiSelectWithCheckboxes ref={multiSelectRef} minerGroups = {minerGroups} defaultGroup = {searchStrings.dgname} onChange = {handleCsvChange}/>
                  </DialogContent>
                  <DialogActions style={{ justifyContent: "center" }}>
                    <Button onClick={handleClearCsv}>
                      Clear
                    </Button>
                    <Button onClick={handleExportCsv}>
                      Export
                    </Button>
                    <Button onClick={handleCsvClose}>
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  gap={0.5}
                >
                  <Button
                  variant="outlined"
                  disabled={!isminerGroupSelected || selectAllMiners}
                  onClick={handleSelectAllMiners}
                  sx={{ mr: 2, fontSize: "11px", marginLeft: "auto" }}
                  >
                  Select All Miners
                  </Button>
                </Box>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  gap={0.5}
                >
                  <Button
                  variant="outlined"
                  disabled={!isminerGroupSelected}
                  onClick={handleClearSelectedMiners}
                  sx={{ fontSize: "11px", marginLeft: "auto" }}
                  >
                  Unselect All Miners
                  </Button>
                </Box>
                {techSupport === false && displaytable !== null && (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                    gap={0.5}
                  >
                    <Button
                      variant="outlined"
                      onClick={handleSubmit}
                      sx={{ ml: 2, mr: 2, fontSize: "11px", lineHeight: "1.4" }}
                    >
                      Save Tags
                    </Button>
                  </Box>
                )}
                {techSupport === true && displaytable !== null && (
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                    gap={0.5}
                  >
                    <Button
                      variant="outlined"
                      onClick={handleCSTagSubmit}
                      sx={{ ml: 2, mr: 2, fontSize: "11px", lineHeight: "1.4" }}
                    >
                      Save Tags
                    </Button>
                  </Box>
                )}
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"flex-end"}
                  gap={0.5}
                >
                <Button
                variant="outlined"
                onClick={handleClickOpenSettings}
                sx={{ fontSize: "11px", marginLeft: "auto" }}
                >
                Column Settings
                </Button>
                </Box>
              </Stack>
              </Box>
            </Grid>
          </Grid>
          {techSupport === false && displaytable !== null && (
            <Dialog
              open={openSaveConfirmation}
              onClose={handleCloseSaveConfMessage}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {confMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseSaveConfMessage}
                  type="submit"
                  variant="contained"
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          )}
          {techSupport === true && displaytable !== null && (
            <Dialog
              open={openSaveConfirmation}
              onClose={handleCloseSaveConfMessage}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {confMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseSaveConfMessage}
                  type="submit"
                  variant="contained"
                >
                  Ok
                </Button>
              </DialogActions>
            </Dialog>
          )}
          <Dialog open={openOperationsPopup} maxWidth={400}>
            <DialogTitle>
              <Typography variant="h5" sx={{ mt: 3 }}>
                Miner Operations for {minerGroup}
              </Typography>
            </DialogTitle>
            <DialogContent sx={{ maxHeight: '70vh', overflowY: 'auto' }}>
              <TabContext value={actionvalue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  {role === "pooladmin" || role === "superadmin" || role === "admin" ? (
                    <Tabs
                    value={actionvalue}
                    onChange={handleValueChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    >
                      <Tab
                        label="Upgrade/Restart Miners"
                        value="0"
                        style={{ fontSize: "13px" }}
                      />
                      <Tab
                        label="Rolling Upgrade Miners"
                        value="13"
                        style={{ fontSize: "13px" }}
                      />
                      <Tab
                        label="Allow FV Config"
                        value="1"
                        style={{ fontSize: "13px" }}
                      />
                      <Tab
                        label="Tune"
                        value="12"
                        style={{ fontSize: "13px" }}
                      />
                      <Tab
                        label="Standby Mode"
                        value="2"
                        style={{ fontSize: "13px" }}
                      />
                      <Tab
                        label="Alert Notifications"
                        value="3"
                        style={{ fontSize: "13px" }}
                      />
                      <Tab
                        label="Migrate Miners"
                        value="4"
                        style={{ fontSize: "13px" }}
                      />
                      <Tab
                        label="Flash LEDs"
                        value="5"
                        style={{ fontSize: "13px" }}
                      />
                      {techSupport === false && (
                      <Tab
                        label="Push Tags"
                        value="6"
                        style={{ fontSize: "13px" }}
                      />)}

                       <Tab
                        label="Retune"
                        value="7"
                        style={{ fontSize: "13px" }}
                        sx = {{minWidth: "70px", width: 70}}
                      />
                       <Tab
                        label="Fan"
                        value="8"
                        style={{ fontSize: "13px" }}
                        sx = {{minWidth: "60px", width: 60}}
                      />
                      {/* <Tab
                        label="Low Coolant"
                        value="9"
                        style={{ fontSize: "13px" }}
                      /> */}
                      <Tab
                        label="Miner w/o Net"
                        value="10"
                        style={{ fontSize: "13px" }}
                      />
                      <Tab
                        label="Enhanced Eco"
                        value="11"
                        style={{ fontSize: "13px" }}
                      />
                      {role === "superadmin" && (
                      <Tab
                        label="Transfer Miners"
                        value="14"
                        style={{ fontSize: "13px" }}
                      />)}
                    </Tabs>
                  ) : (
                    <Tabs
                    value={actionvalue}
                    onChange={handleValueChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                      <Tab
                        label="Allow FV Config"
                        value="1"
                        style={{ fontSize: "13px" }}
                      />
                      <Tab
                        label="Standby Mode"
                        value="2"
                        style={{ fontSize: "13px" }}
                      />
                      <Tab
                        label="Alert Notifications"
                        value="3"
                        style={{ fontSize: "13px" }}
                      />
                      <Tab
                        label="Migrate Miners"
                        value="4"
                        style={{ fontSize: "13px" }}
                      />
                      <Tab
                        label="Flash LEDs"
                        value="5"
                        style={{ fontSize: "13px" }}
                      />
                      {techSupport === false && (
                      <Tab
                        label="Push Tags"
                        value="6"
                        style={{ fontSize: "13px" }}
                      />)}
                       <Tab
                        label="Retune"
                        value="7"
                        style={{ fontSize: "13px" }}
                      />
                       <Tab
                        label="Fan"
                        value="8"
                        style={{ fontSize: "13px" }}
                      />
                      {/* <Tab
                        label="Low Coolant"
                        value="9"
                        style={{ fontSize: "13px" }}
                      /> */}
                      <Tab
                        label="Mining w/o Internet"
                        value="10"
                        style={{ fontSize: "13px" }}
                      />
                      <Tab
                        label="Enhanced Eco"
                        value="11"
                        style={{ fontSize: "13px" }}
                      />
                    </Tabs>
                  )}
                </Box>
                <TabPanel value="14">
                  <Box sx={{ display: 'flex', mt: 6, textAlign: 'left', alignItems: 'center' }}>
                    <Typography variant="body1" component="div" sx={{ width: '250px' }}>
                    Target Organization Name
                    </Typography>
                    <TextField
                      variant="standard"
                      placeholder="Target organization name to transfer the miners"
                      inputProps={{
                        sx: {
                          width: "350px",
                          fontSize: "13.5px",
                          "&::placeholder": {
                            color: "gray",
                            opacity: 0.75,
                            fontStyle: "italic",
                            fontSize: "12px",
                          },
                        },
                      }}
                      value={targetOrgName}
                      onChange={handleTargetOrgName}
                    />
                  </Box>
                  <Box sx={{ display: 'flex', mt: 6, textAlign: 'left', alignItems: 'center' }}>
                    <Typography variant="body1" component="div" sx={{ width: '250px' }}>
                    Target Super Admin Email ID
                    </Typography>
                    <TextField
                      variant="standard"
                      placeholder="Enter email id of target organization's super admin"
                      inputProps={{
                        sx: {
                          width: "350px",
                          fontSize: "13.5px",
                          "&::placeholder": {
                            color: "gray",
                            opacity: 0.75,
                            fontStyle: "italic",
                            fontSize: "12px",
                          },
                        },
                      }}
                      value={targetEmailID}
                      onChange={handleTargetEmailID}
                    />
                  </Box>
                </TabPanel>
                <TabPanel value="0">
                  <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                    <Typography sx={{ mr: 6 }}>
                      Firmware Upgrade Option
                    </Typography>
                    <Typography variant="body2">Latest</Typography>
                    <Checkbox
                      checked={upgradeChecked}
                      onChange={handleUpgradeChecked}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ mr: 4 }}
                    />
                    <Typography variant="body2">URL</Typography>
                    <Checkbox
                      checked={URLChecked}
                      onChange={handleURLChecked}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Stack>
                  {URLChecked && (
                    <Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
                      <Typography sx={{ mr: 6 }}>Firmware URL:</Typography>
                      <TextField
                        variant="standard"
                        placeholder="Enter valid URL"
                        inputProps={{
                          sx: {
                            width: "350px",
                            fontSize: "13.5px",
                            "&::placeholder": {
                              color: "gray",
                              opacity: 0.75,
                              fontStyle: "italic",
                              fontSize: "12px",
                            },
                          },
                        }}
                        value={URLInput}
                        onChange={handleURLInput}
                      />
                    </Stack>
                  )}
                  <Stack direction="column" alignItems="left">
                    <Typography
                      variant="body1"
                      sx={{ mt: 6, fontStyle: "italic" }}
                    >
                      <strong>NOTE: </strong>Before upgrading all miners, please
                      ensure to upgrade one miner initially and check its status
                      in the last job op column of Miner tab.
                    </Typography>
                    <Typography variant="body1">
                      On success, restart miner and verify its status in the last job op column of Miner tab.
                    </Typography>
                  </Stack>
                </TabPanel>
                <TabPanel value="13">
                  <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                    <Typography sx={{ width: '250px' }}>
                      Firmware Upgrade Option
                    </Typography>
                    <Typography variant="body2">Latest</Typography>
                    <Checkbox
                      checked={rollingupgradeChecked}
                      onChange={handleRollingUpgradeChecked}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ mr: 4 }}
                    />
                    <Typography variant="body2">URL</Typography>
                    <Checkbox
                      checked={rollingURLChecked}
                      onChange={handleRollingURLChecked}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Stack>
                  {rollingURLChecked && (
                    <Stack direction="row" alignItems="center" sx={{ mt: 3 }}>
                      <Typography variant="body1" component="div" sx={{ width: '250px' }}>
                      Firmware URL
                      </Typography>
                      <TextField
                        variant="standard"
                        placeholder="Enter valid URL"
                        inputProps={{
                          sx: {
                            width: "350px",
                            fontSize: "13.5px",
                            "&::placeholder": {
                              color: "gray",
                              opacity: 0.75,
                              fontStyle: "italic",
                              fontSize: "12px",
                            },
                          },
                        }}
                        value={rollingURLInput}
                        onChange={handleRollingURLInput}
                      />
                    </Stack>
                  )}
                  <Box sx={{ display: 'flex', mt: 6, textAlign: 'left', alignItems: 'center' }}>
                    <Typography variant="body1" component="div" sx={{ width: '250px'}}>
                      Upgrade Operation Start Time
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Start Time"
                        value={startTimeValue}
                        onChange={handleStartTimeValue}
                        format="YYYY-MM-DD hh:mm a"
                        slotProps={{
                          textField: {
                            error: startTimeErrorValue,
                            helperText: startTimeError,
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </Box>
                  <Box sx={{ display: 'flex', mt: 6, textAlign: 'left', alignItems: 'center' }}>
                      <Typography variant="body1" component="div" sx={{ width: '250px' }}>
                        Upgrade Operation Window
                      </Typography>
                      <TextField
                        variant="standard"
                        placeholder="in seconds b/w 120 and 43200 (12 hrs)"
                        inputProps={{
                          sx: {
                            width: "350px",
                            fontSize: "13.5px",
                            "&::placeholder": {
                              color: "gray",
                              opacity: 0.75,
                              fontStyle: "italic",
                              fontSize: "12px",
                            },
                          },
                        }}
                        value={changeUpgradeWindowTime}
                        onChange={handleChangeUpgradeWindowTimeValue}
                        error={changeUpgradeWindowValueError.error}
                        helperText={changeUpgradeWindowValueError.helperText}
                      />
                    </Box>
                  <Stack direction="column" alignItems="left">
                    <Typography
                      variant="body1"
                      sx={{ mt: 6, fontStyle: "italic" }}
                    >
                      <strong>NOTE: </strong>This operation requires miner firmware v2.0.0 or later
                    </Typography>
                  </Stack>
                </TabPanel>
                <TabPanel value="1">
                  <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                    <Typography>Use FluxVision Tuning Config</Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={tuningChecked}
                          onChange={handleTuningChecked}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ ml: 5 }}
                        />
                      }
                      label={
                        <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                          {tuningChecked ? "On" : "Off"}
                        </Typography>
                      }
                    />
                  </Stack>
                  <br></br>
                  <Typography variant="body1" style={{ fontStyle: "italic" }}>
                    <strong>NOTE: </strong>When 'ON' is selected, the tune
                    settings of selected miner(s) will be overwritten with
                    FluxVision tune settings. This operation requires miner firmware 2024-06.06 or later
                  </Typography>
                </TabPanel>
                <TabPanel value="2">
                  <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 5 }}>
                    <Typography>
                      Put {selectionModel.length === 1 ? "miner" : "miners"} in
                      Standby Mode
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={standbyChecked}
                          onChange={handleStandbyModeChecked}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ ml: 5 }}
                        />
                      }
                      label={
                        <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                          {standbyChecked ? "On" : "Off"}
                        </Typography>
                      }
                    />
                  </Stack>
                  <Typography variant="body1" style={{ fontStyle: "italic" }}>
                    <strong>NOTE: </strong>This operation requires miner firmware 2024-06.06 or later
                  </Typography>
                </TabPanel>
                <TabPanel value="3">
                  <Stack
                    direction="row"
                    alignItems="center"
                    sx={{ mt: 2, mb: 8 }}
                  >
                    <Typography sx={{ mr: 6 }}>
                      Desired interval between alert notifications
                    </Typography>
                    <TextField
                      variant="standard"
                      helperText="Set to 0 minutes to turn off alerts"
                      inputProps={{
                        sx: {
                          width: "300px",
                          fontSize: "13.5px",
                          "&::placeholder": {
                            color: "gray",
                            opacity: 0.75,
                            fontStyle: "italic",
                            fontSize: "12px",
                          },
                        },
                      }}
                      value={desiredAlertInterval}
                      onChange={handleDesiredAlertInterval}
                      sx={{mt:4}}
                    />
                  </Stack>
                  <Stack direction="row" alignItems="top" sx={{ mt: 1, mb: 4 }}>
                    <Typography sx={{ mt: 9, mb: 1, mr: 6 }}>
                      Select registered email(s) to recieve alerts
                    </Typography>
                    <Stack direction="column" alignItems="left">
                      <Stack direction="row" alignItems="center" sx={{ m: -3.2 }}>
                        <Checkbox
                          checked={selectAllAlertMembers}
                          onChange={(event) => handleSelectAllAlertMembers(event)}
                          style={{
                            transform: "scale(0.8)",
                          }}
                        />
                        <Typography variant="body1">Select All</Typography>
                      </Stack>
                      <Autocomplete
                        sx={{ width: 500, mb: 2, mt: 1 }}
                        multiple
                        value={alertMembers || []}
                        onChange={(event, value) =>
                          handleUpdateAlertMembers(value)
                        }
                        disableCloseOnSelect
                        id="combo-box-demo3"
                        options={members}
                        renderOption={(props, option, { selected }) => (
                          <li
                            {...props}
                            style={{
                              whiteSpace: "normal",
                              fontSize: "14px",
                            }}
                          >
                            <Checkbox
                              style={{ marginRight: 8, transform: "scale(0.8)" }}
                              checked={selected}
                            />
                            {option}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            aria-label="Select mode"
                            inputProps={{
                              ...params.inputProps,
                              style: {
                                fontSize: "11px",
                              },
                            }}
                            placeholder="Select email(s) to get alerts"
                          />
                        )}
                        defaultValue={[]}
                      />
                    </Stack>
                  </Stack>
                  <Typography
                    variant="body1"
                    sx={{ mb: 6, fontStyle: "italic" }}
                  >
                    <strong>NOTE:</strong> Interval and Alert notification email
                    list is common across all the groups
                  </Typography>
                  <Typography sx={{ mt: 7 }}>
                    <strong>Select the options below to recieve alerts</strong>
                  </Typography>
                  <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                    <Checkbox
                      checked={hashboardMalfChecked}
                      onChange={handleHashboardMalfChecked}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography variant="body2">
                      Hash Board Malfunction
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Checkbox
                      checked={fanMalfChecked}
                      onChange={handleFanMalfChecked}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography variant="body2">Fan Malfunction</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Checkbox
                      checked={PSUMalfChecked}
                      onChange={handlePSUMalfChecked}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography variant="body2">PSU Malfunction</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center">
                    <Checkbox
                      checked={chipTempChecked}
                      onChange={handleChipTempChecked}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography variant="body2">Chip Temperature</Typography>
                  </Stack>
                  <Box sx={{ display: "flex", mt: 2, ml: 3 }}>
                    <Typography
                      variant="body1"
                      component="div"
                      sx={{
                        whiteSpace: "nowrap",
                        textDecoration: "underline",
                        cursor: "pointer",
                        fontSize: "12px",
                        fontStyle: "italic",
                        color: "#1976D2",
                      }}
                      onClick={handleClearSelections}
                    >
                      Clear Selections
                    </Typography>
                  </Box>
                  {chipTempChecked && (
                    <div>
                      <br />
                      <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                        <Typography variant='body2' sx={{ mr: 6 }}>
                          Chip Temperature Threshold
                        </Typography>
                        <TextField
                          variant="standard"
                          placeholder="Chip °C to get alerts"
                          inputProps={{
                            sx: {
                              width: "350px",
                              fontSize: "13.5px",
                              "&::placeholder": {
                                color: "gray",
                                opacity: 0.75,
                                fontStyle: "italic",
                                fontSize: "12px",
                              },
                            },
                          }}
                          value={thresholdValue}
                          onChange={handleThresholdValueInput}
                        />
                      </Stack>
                      <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                        <Typography variant='body2' sx={{ mr: 6 }}>
                          Number Of Chips °C Over Threshold
                        </Typography>
                        <TextField
                          variant="standard"
                          placeholder="Enter number"
                          inputProps={{
                            sx: {
                              width: "350px",
                              fontSize: "13.5px",
                              "&::placeholder": {
                                color: "gray",
                                opacity: 0.75,
                                fontStyle: "italic",
                                fontSize: "12px",
                              },
                            },
                          }}
                          value={numberOfChipsOverThreshold}
                          onChange={handleNumberOfChipsOverThresholdInput}
                        />
                      </Stack>
                      <Stack direction="column" alignItems="left" sx={{ mt: 5 }}>
                        <Typography
                          variant="body1"
                          style={{ fontStyle: "italic" }}
                        >
                          <strong>NOTE: </strong>The number of chips °C over
                          threshold value is common across all the groups. It is
                          checked against number of chips from all the hash{" "}
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ fontStyle: "italic" }}
                        >
                          boards for each selected miner.
                        </Typography>
                      </Stack>
                    </div>
                  )}
                </TabPanel>
                <TabPanel value="4">
                  <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ mr: 4 }}>
                      Destination Miner Group
                    </Typography>
                    <TextField
                      label="Select Destination Miner Group"
                      value={destinationGroup}
                      onChange={handleSelectDestinationGroup}
                      sx={{
                        ml: 1,
                        minWidth: "300px",
                        "& .MuiInputLabel-root": { fontSize: "12px" },
                      }}
                      select
                    >
                    <MenuItem value="">Select</MenuItem>
                    {minerGroups
                    .filter(group => group !== minerGroup)
                    .map((group, index) => {
                      return (
                        <MenuItem key={index} value={group}>
                          {group}
                        </MenuItem>
                      );
                    })}
                    </TextField>
                  </Stack>
                </TabPanel>
                <TabPanel value="5">
                  <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                    <Typography>
                      Enable Flash LEDs
                    </Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={flashChecked}
                          onChange={handleFlashModeChecked}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ ml: 5 }}
                        />
                      }
                      label={
                        <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                          {flashChecked ? "On" : "Off"}
                        </Typography>
                      }
                    />
                  </Stack>
                </TabPanel>
                <TabPanel value="6">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ mt: 2, width: '100%', mb: 8 }}
                  >
                    <Typography>Click on SEND to push the saved tags to the miners</Typography>
                  </Stack>
                  <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                      <strong>NOTE: </strong>This operation requires miner firmware v1.0.1,v1.1.4-beta, or later
                  </Typography>
                </TabPanel>
                <TabPanel value="7">
                    <Stack direction="row" alignItems="center" sx={{ mt: 3, mb: 5 }}>
                      <Typography sx={{ mr: 6 }}>Set the minimum time interval between automatic retunes</Typography>
                      <TextField
                        variant="standard"
                        placeholder="Enter time in minutes (30-2880)"
                        inputProps={{
                          sx: {
                            mt: -1,
                            width: "350px",
                            fontSize: "13.5px",
                            "&::placeholder": {
                              color: "gray",
                              opacity: 0.75,
                              fontStyle: "italic",
                              fontSize: "12px",
                            },
                          },
                        }}
                        error={retuneError}
                        helperText={retuneError ? "Valid retune time range 30 to 2880" : ""}
                        value={reTuneTime}
                        onChange={handleRetuneTime}
                        sx={{mt:4}}
                      />
                  </Stack>
                  <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                    <strong>NOTE: </strong>This operation requires miner firmware v2.0.0 or later
                  </Typography>
                </TabPanel>
                <TabPanel value="8">
                  <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 5 }}>
                    {/* fans in standby Input Field */}
                    <Typography
                      variant="body1" // You can change this to a different variant if needed
                      sx={{
                        border: 'none',
                        padding: '0px',
                        backgroundColor: 'transparent',
                        marginTop: '4px',
                      }}
                    >
                    Set fans to run at 20% of maximum speed when miner is in Standby mode  </Typography>
                    {/* Fans in standby Switch */}
                    <Box display="flex" alignItems="center" sx={{marginLeft: 5, marginTop: '4px'}}>
                      <FormControlLabel
                          control={
                            <Switch
                          checked={isFanOn}
                          onClick={setFanAction}
                          color="primary"
                        />
                          }
                          label={
                            <Typography variant="body2" sx={{ fontSize: "smaller", marginTop: '4px'}}>
                              {isFanOn ? "On" : "Off"}
                            </Typography>
                          }
                        />
                    </Box>
                  </Stack>
                  <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                    <strong>NOTE: </strong>This operation requires miner firmware v1.0.1,v1.1.4-beta, or later
                  </Typography>
                </TabPanel>
                <TabPanel value="9">
                  <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 5 }}>
                    {/* Coolant Input Field */}
                    <Typography
                      variant="body1" // You can change this to a different variant if needed
                      sx={{
                        border: 'none',
                        padding: '0px',
                        backgroundColor: 'transparent',
                        marginTop: '4px',
                      }}
                    >
                    Put the miner in Standby mode when fluid levels are low  </Typography>
                    {/* Coolant toggle Switch */}
                    <Box display="flex" alignItems="center" sx={{marginLeft: 5, marginTop: '4px'}}>
                      <FormControlLabel
                          control={
                            <Switch
                          checked={isCoolantOn}
                          onClick={setCoolantAction}
                          color="primary"
                        />
                          }
                          label={
                            <Typography variant="body2" sx={{ fontSize: "smaller", marginTop: '4px' }}>
                              {isCoolantOn ? "On" : "Off"}
                            </Typography>
                          }
                        />
                    </Box>
                  </Stack>
                  <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                    <strong>NOTE: </strong>This operation requires miner firmware v2.0.0 or later
                  </Typography>
                </TabPanel>
                <TabPanel value="10">
                  <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 5 }}>
                    {/* Mine w/o Input Field */}
                    <Typography
                      variant="body1" // You can change this to a different variant if needed
                      sx={{
                        border: 'none',
                        padding: '0px',
                        backgroundColor: 'transparent',
                        marginTop: '4px',
                      }}
                    >
                    Allow the miner to continue mining when there is no internet  </Typography>
                    {/* Mine w/o net Toggle Switch */}
                    <Box display="flex" alignItems="center" sx={{marginLeft: 5, marginTop: '4px'}}>
                      <FormControlLabel
                          control={
                            <Switch
                          checked={isMinerInternetOn}
                          onClick={setMinerInternetAction}
                          color="primary"
                        />
                          }
                          label={
                            <Typography variant="body2" sx={{ fontSize: "smaller", marginTop: '4px' }}>
                              {isMinerInternetOn ? "On" : "Off"}
                            </Typography>
                          }
                        />
                    </Box>
                  </Stack>
                  <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                    <strong>NOTE: </strong>This operation requires miner firmware v2.0.0 or later
                  </Typography>
                </TabPanel>
                <TabPanel value="11">
                  <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 5 }}>
                    {/* optimize eco toggle Input Field */}
                    <Typography
                      variant="body1" // You can change this to a different variant if needed
                      sx={{
                        border: 'none',
                        padding: '0px',
                        backgroundColor: 'transparent',
                        marginTop: '4px',
                      }}
                    >
                    Search for the lowest possible J/THs when miner operates in eco mode  </Typography>
                    {/* Optimize eco toggle Switch */}
                    <Box display="flex" alignItems="center" sx={{marginLeft: 5, marginTop: '4px'}}>
                      <FormControlLabel
                          control={
                            <Switch
                          checked={isEcoOn}
                          onClick={setEcoAction}
                          color="primary"
                        />
                          }
                          label={
                            <Typography variant="body2" sx={{ fontSize: "smaller", marginTop: '4px' }}>
                              {isEcoOn ? "On" : "Off"}
                            </Typography>
                          }
                        />
                    </Box>
                  </Stack>
                  <Typography variant="body1" sx={{ fontStyle: "italic" }}>
                    <strong>NOTE: </strong>This operation requires miner firmware v2.0.0 or later
                  </Typography>
                </TabPanel>
                <TabPanel value="12">
                  <Stack direction="row" alignItems="center" sx={{ mt: 2 }}>
                    <Typography sx={{ mr: 6 }}>
                      Tuning Operations
                    </Typography>
                    <Typography variant="body2">Eco</Typography>
                    <Checkbox
                      checked={ecoChecked}
                      onChange={handleEcoChecked}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ mr: 4 }}
                    />
                    <Typography variant="body2">Turbo</Typography>
                    <Checkbox
                      checked={turboChecked}
                      onChange={handleTurboChecked}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ mr: 4 }}
                    />
                    <Typography variant="body2">Custom THs</Typography>
                    <Checkbox
                      checked={thsChecked}
                      onChange={handleTHsChecked}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ mr: 4 }}
                    />
                    <Typography variant="body2">Custom Power</Typography>
                    <Checkbox
                      checked={powerChecked}
                      onChange={handlePowerChecked}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </Stack>
                  {thsChecked && (
                    <Stack direction="row" alignItems="center" sx={{ mt: 3, mb: 5 }}>
                      <Typography sx={{ mr: 6 }}>Hash Rate in THs:</Typography>
                      <TextField
                        variant="standard"
                        placeholder={`Enter THs (${constants.MINTHS} - ${maxths})`}
                        inputProps={{
                          sx: {
                            mt: -1,
                            width: "350px",
                            fontSize: "13.5px",
                            "&::placeholder": {
                              color: "gray",
                              opacity: 0.75,
                              fontStyle: "italic",
                              fontSize: "12px",
                            },
                          },
                        }}
                        error={thsError}
                        helperText={thsError ? `Value must be between ${constants.MINTHS} and ${maxths}` : ""}
                        value={thsInput}
                        onChange={handleTHsInput}
                        sx={{mt:4}}
                      />
                    </Stack>
                  )}
                  {powerChecked && (
                    <Stack direction="row" alignItems="center" sx={{ mt: 3, mb: 5 }}>
                      <Typography sx={{ mr: 6 }}>Power in Watts:</Typography>
                      <TextField
                        variant="standard"
                        placeholder={`Enter Power (${constants.MINPOWER} - ${maxpower}) in watts`}
                        inputProps={{
                          sx: {
                            mt: -1,
                            width: "350px",
                            fontSize: "13.5px",
                            "&::placeholder": {
                              color: "gray",
                              opacity: 0.75,
                              fontStyle: "italic",
                              fontSize: "12px",
                            },
                          },
                        }}
                        error={powerError}
                        helperText={powerError ? `Value must be between ${constants.MINPOWER} and ${maxpower}` : ""}
                        value={powerInput}
                        onChange={handlePowerInput}
                        sx={{mt:4}}
                      />
                    </Stack>
                  )}
                  {thsChecked && (
                  <Typography variant="body1" style={{ fontStyle: "italic" }}>
                    <strong>NOTE: </strong>Please make sure to verify the supported hashrate range for your specific miner model.

                  </Typography>
                  )}
                  {powerChecked && (
                  <Typography variant="body1" style={{ fontStyle: "italic" }}>
                    <strong>NOTE: </strong>Please make sure to verify the supported power range for your specific miner model.
                  </Typography>
                  )}
                </TabPanel>
              </TabContext>
            </DialogContent>
            <DialogActions style={{ justifyContent: "right" }}>
              {actionvalue === "0" && (
                <>
                  <Button onClick={handleClearUpgrade} sx={{ m: 2 }}>
                    Clear
                  </Button>
                  <Button onClick={handleUpgradeUpgrade} sx={{ m: 2 }}>
                    Upgrade
                  </Button>
                  <Button onClick={handleRestartMinerUpgrade} sx={{ m: 2 }}>
                    Restart Miner
                  </Button>
                </>
              )}
              {actionvalue === "14" && (
                <>
                  <Button onClick={handleClearTransferMiners} sx={{ m: 2 }}>
                    Clear
                  </Button>
                  <Button onClick={handleTransferMiners} disabled={targetOrgName.trim() === "" || targetEmailID.trim() === ""}>
                    Transfer
                  </Button>
                </>
              )}
              {actionvalue === "13" && (
                <>
                  <Button onClick={handleClearRollingUpgrade} sx={{ m: 2 }}>
                    Clear
                  </Button>
                  <Button onClick={handleRollingUpgradeAddOperation} sx={{ m: 2 }}>
                    Rolling Upgrade
                  </Button>
                  <Button onClick={handleRollingUpgradeDeleteOperation} sx={{ m: 2 }}>
                    Delete
                  </Button>
                </>
              )}
              {actionvalue === "1" && (
                <>
                  <Button onClick={handleClearTuningConfig} sx={{ m: 2 }}>
                    Clear
                  </Button>
                  <Button onClick={handleSendTuningConfig} sx={{ m: 2 }}>
                    Send
                  </Button>
                </>
              )}
              {actionvalue === "2" && (
                <>
                  <Button onClick={handleClearStandbyMode} sx={{ m: 2 }}>
                    Clear
                  </Button>
                  <Button onClick={handleSendStandbyMode} sx={{ m: 2 }}>
                    Send
                  </Button>
                </>
              )}
              {actionvalue === "3" && (
                <>
                  <Button onClick={handleClearAlertConfig} sx={{ m: 2 }}>
                    Clear
                  </Button>
                  <Button onClick={handleSaveAlertConfig} sx={{ m: 2 }}>
                    Save
                  </Button>
                </>
              )}
              {actionvalue === "4" && (
                <>
                  <Button onClick={handleClearMigrateMiners} sx={{ m: 2 }}>
                    Clear
                  </Button>
                  <Button onClick={handleMigrateMigrateMiners} sx={{ m: 2 }}>
                    Migrate
                  </Button>
                </>
              )}
              {actionvalue === "5" && (
                <>
                  <Button onClick={handleClearFlashMode} sx={{ m: 2 }}>
                    Clear
                  </Button>
                  <Button onClick={handleSendFlashMode} sx={{ m: 2 }}>
                    Send
                  </Button>
                </>
              )}
              {actionvalue === "6" && (
                <>
                  <Button onClick={handleSendTags} sx={{ m: 2 }}>
                    Send
                  </Button>
                </>
              )}
              {actionvalue === "7" && (!retuneError && reTuneTime.trim() !== "") && (
                <>
                  <Button onClick={handleSendRetune} sx={{ m: 2 }}>
                    Send
                  </Button>
                </>
              )}
              {actionvalue === "8" && (
                <>
                  <Button onClick={handleSendFanInfo} sx={{ m: 2 }}>
                    Send
                  </Button>
                </>
              )}
              {actionvalue === "9" && (
                <>
                  <Button onClick={handleSendCoolantInfo} sx={{ m: 2 }}>
                    Send
                  </Button>
                </>
              )}
              {actionvalue === "10" && (
                <>
                  <Button onClick={handleSendMinerInternetInfo} sx={{ m: 2 }}>
                    Send
                  </Button>
                </>
              )}
              {actionvalue === "11" && (
                <>
                  <Button onClick={handleSendEcoInfo} sx={{ m: 2 }}>
                    Send
                  </Button>
                </>
              )}
              {
                (actionvalue === "12" &&
                (ecoChecked || turboChecked || ((!thsError && thsInput.trim() !== "")) || (!powerError && powerInput.trim() !== ""))) && (
                <>
                  <Button onClick={handleSendMinerTuningInfo} sx={{ m: 2 }}>
                    Send
                  </Button>
                </>
              )}
              <Button onClick={handleCloseActions} sx={{ m: 2 }}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openMinerRemoveOperationConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Miner Removal Operation Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {minerRemoveOperationConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMinerRemoveOperationConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openTransferMinersConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Transfer Operation Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {transferMinersConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseTransferMinersConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openUpgradeConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Upgrade Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {upgradeConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseUpgradeConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={statusUpgrade}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
                  Upgrade Status
                </Typography>
              </DialogTitle>
              {getFWfail === false && Object.keys(fwjobMessage).length > 0 && (
                <>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Serial No:</b> {fwjobMessage.SerialNo}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Status:</b> {fwjobMessage.Status}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Message:</b> {fwjobMessage.Msg}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Updated At:</b> {displayTS(fwjobMessage.updatedAt).timeStr}
                  </Typography>
                </>
              )}
              {getFWfail === false &&
                fwMultipleJobMessage.length > 0 &&
                fwMultipleJobMessage.map((item, index) => (
                  <div key={index}>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {item.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {item.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {item.Msg}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "smaller", mb: 3.5 }}
                    >
                      <b>Updated At:</b> {displayTS(item.updatedAt).timeStr}
                    </Typography>
                  </div>
                ))}
              {getFWfail === true && (
                <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                  {upgradeStatusMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStatusUpgrade}>OK</Button>
            </DialogActions>
          </Dialog>
          {/* Do the Rolling upgrade here */}
          <Dialog open={openRollingUpgradeConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Rolling Upgrade Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {rollingupgradeConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseRollingUpgradeConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={statusRollingUpgrade}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
                  Rolling Upgrade Status
                </Typography>
              </DialogTitle>
              {getFWfail === false && Object.keys(fwjobMessage).length > 0 && (
                <>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Serial No:</b> {fwjobMessage.SerialNo}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Status:</b> {fwjobMessage.Status}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Message:</b> {fwjobMessage.Msg}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Updated At:</b> {displayTS(fwjobMessage.updatedAt).timeStr}
                  </Typography>
                </>
              )}
              {getFWfail === false &&
                fwMultipleJobMessage.length > 0 &&
                fwMultipleJobMessage.map((item, index) => (
                  <div key={index}>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {item.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {item.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {item.Msg}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "smaller", mb: 3.5 }}
                    >
                      <b>Updated At:</b> {displayTS(item.updatedAt).timeStr}
                    </Typography>
                  </div>
                ))}
              {getFWfail === true && (
                <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                  {rollingupgradeStatusMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStatusRollingUpgrade}>OK</Button>
            </DialogActions>
          </Dialog>
          {/* End of rolling upgrade */}
          <Dialog open={openTuningConfigConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Tuning Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {tuningConfigConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseTuningConfigConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={statusTuning}>
            <DialogTitle>
              <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
                Tuning Config Status
              </Typography>
            </DialogTitle>
            <DialogContent>
              {getTunefail === false && Object.keys(tuneJobMessage).length > 0 && (
                <>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Serial No:</b> {tuneJobMessage.SerialNo}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Status:</b> {tuneJobMessage.Status}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Message:</b> {tuneJobMessage.Msg}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Updated At:</b>{" "}
                    {displayTS(tuneJobMessage.updatedAt).timeStr}
                  </Typography>
                </>
              )}
              {getTunefail === false &&
                tuneMultipleJobMessage.length > 0 &&
                tuneMultipleJobMessage.map((item, index) => (
                  <div key={index}>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {item.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {item.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {item.Msg}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "smaller", mb: 3.5 }}
                    >
                      <b>Updated At:</b> {displayTS(item.updatedAt).timeStr}
                    </Typography>
                  </div>
                ))}
              {getTunefail === true && (
                <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                  {tuningConfigStatusMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStatusTuning}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openStandbyConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Mode Change Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {standbyConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStandbyConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={statusStandby}>
            <DialogTitle>
              <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
                Mode Status
              </Typography>
            </DialogTitle>
            <DialogContent>
              {getSleepfail === false &&
                Object.keys(standbyJobMessage).length > 0 && (
                  <>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {standbyJobMessage.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {standbyJobMessage.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {standbyJobMessage.Msg}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Updated At:</b>{" "}
                      {displayTS(standbyJobMessage.updatedAt).timeStr}
                    </Typography>
                  </>
                )}
              {getSleepfail === false &&
                standbyMultipleJobMessage.length > 0 &&
                standbyMultipleJobMessage.map((item, index) => (
                  <div key={index}>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {item.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {item.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {item.Msg}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "smaller", mb: 3.5 }}
                    >
                      <b>Updated At:</b> {displayTS(item.updatedAt).timeStr}
                    </Typography>
                  </div>
                ))}
              {getSleepfail === true && (
                <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                  {standbyStatusMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStatusStandby}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openFlashConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">LED Change Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {flashConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFlashConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={statusFlash}>
            <DialogTitle>
              <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
                Flash LED Status
              </Typography>
            </DialogTitle>
            <DialogContent>
              {getFlashfail === false &&
                Object.keys(flashJobMessage).length > 0 && (
                  <>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {flashJobMessage.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {flashJobMessage.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {flashJobMessage.Msg}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Updated At:</b>{" "}
                      {displayTS(flashJobMessage.updatedAt).timeStr}
                    </Typography>
                  </>
                )}
              {getFlashfail === false &&
                flashMultipleJobMessage.length > 0 &&
                flashMultipleJobMessage.map((item, index) => (
                  <div key={index}>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {item.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {item.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {item.Msg}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "smaller", mb: 3.5 }}
                    >
                      <b>Updated At:</b> {displayTS(item.updatedAt).timeStr}
                    </Typography>
                  </div>
                ))}
              {getFlashfail === true && (
                <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                  {flashStatusMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStatusFlash}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openTagConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Push Tags Operation Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {tagConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseTagConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={statusTag}>
            <DialogTitle>
              <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
                Push Tags Operation Status
              </Typography>
            </DialogTitle>
            <DialogContent>
              {getTagfail === false &&
                Object.keys(tagJobMessage).length > 0 && (
                  <>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {tagJobMessage.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {tagJobMessage.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {tagJobMessage.Msg}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Updated At:</b>{" "}
                      {displayTS(tagJobMessage.updatedAt).timeStr}
                    </Typography>
                  </>
                )}
              {getTagfail === false &&
                tagMultipleJobMessage.length > 0 &&
                tagMultipleJobMessage.map((item, index) => (
                  <div key={index}>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {item.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {item.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {item.Msg}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "smaller", mb: 3.5 }}
                    >
                      <b>Updated At:</b> {displayTS(item.updatedAt).timeStr}
                    </Typography>
                  </div>
                ))}
              {getTagfail === true && (
                <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                  {tagStatusMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStatusTag}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openRetuneConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Retune Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {retuneConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseRetuneConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={statusRetune}>
            <DialogTitle>
              <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
                Retune Status
              </Typography>
            </DialogTitle>
            <DialogContent>
              {getRetunefail === false && Object.keys(retuneJobMessage).length > 0 && (
                <>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Serial No:</b> {retuneJobMessage.SerialNo}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Status:</b> {retuneJobMessage.Status}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Message:</b> {retuneJobMessage.Msg}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Updated At:</b>{" "}
                    {displayTS(retuneJobMessage.updatedAt).timeStr}
                  </Typography>
                </>
              )}
              {getRetunefail === false &&
                retuneMultipleJobMessage.length > 0 &&
                retuneMultipleJobMessage.map((item, index) => (
                  <div key={index}>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {item.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {item.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {item.Msg}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "smaller", mb: 3.5 }}
                    >
                      <b>Updated At:</b> {displayTS(item.updatedAt).timeStr}
                    </Typography>
                  </div>
                ))}
              {getRetunefail === true && (
                <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                  {retuneStatusMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStatusRetune}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openFanConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Fan Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {fanConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseFanConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={statusFan}>
            <DialogTitle>
              <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
                Fan Status
              </Typography>
            </DialogTitle>
            <DialogContent>
              {getFanfail === false && Object.keys(fanJobMessage).length > 0 && (
                <>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Serial No:</b> {fanJobMessage.SerialNo}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Status:</b> {fanJobMessage.Status}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Message:</b> {fanJobMessage.Msg}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Updated At:</b>{" "}
                    {displayTS(fanJobMessage.updatedAt).timeStr}
                  </Typography>
                </>
              )}
              {getFanfail === false &&
                fanMultipleJobMessage.length > 0 &&
                fanMultipleJobMessage.map((item, index) => (
                  <div key={index}>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {item.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {item.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {item.Msg}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "smaller", mb: 3.5 }}
                    >
                      <b>Updated At:</b> {displayTS(item.updatedAt).timeStr}
                    </Typography>
                  </div>
                ))}
              {getFanfail === true && (
                <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                  {fanStatusMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStatusFan}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openCoolantConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Coolant Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {coolantConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCoolantConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={statusCoolant}>
            <DialogTitle>
              <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
                Coolant Status
              </Typography>
            </DialogTitle>
            <DialogContent>
              {getCoolantfail === false && Object.keys(coolantJobMessage).length > 0 && (
                <>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Serial No:</b> {coolantJobMessage.SerialNo}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Status:</b> {coolantJobMessage.Status}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Message:</b> {coolantJobMessage.Msg}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Updated At:</b>{" "}
                    {displayTS(coolantJobMessage.updatedAt).timeStr}
                  </Typography>
                </>
              )}
              {getCoolantfail === false &&
                coolantMultipleJobMessage.length > 0 &&
                coolantMultipleJobMessage.map((item, index) => (
                  <div key={index}>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {item.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {item.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {item.Msg}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "smaller", mb: 3.5 }}
                    >
                      <b>Updated At:</b> {displayTS(item.updatedAt).timeStr}
                    </Typography>
                  </div>
                ))}
              {getCoolantfail === true && (
                <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                  {coolantStatusMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStatusCoolant}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openMinerInternetConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Mining without Internet Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {minerInternetConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMinerInternetConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={statusMinerInternet}>
            <DialogTitle>
              <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
                Mining Without Internet Status
              </Typography>
            </DialogTitle>
            <DialogContent>
              {getMinerInternetfail === false && Object.keys(minerInternetJobMessage).length > 0 && (
                <>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Serial No:</b> {minerInternetJobMessage.SerialNo}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Status:</b> {minerInternetJobMessage.Status}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Message:</b> {minerInternetJobMessage.Msg}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Updated At:</b>{" "}
                    {displayTS(minerInternetJobMessage.updatedAt).timeStr}
                  </Typography>
                </>
              )}
              {getMinerInternetfail === false &&
                minerInternetMultipleJobMessage.length > 0 &&
                minerInternetMultipleJobMessage.map((item, index) => (
                  <div key={index}>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {item.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {item.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {item.Msg}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "smaller", mb: 3.5 }}
                    >
                      <b>Updated At:</b> {displayTS(item.updatedAt).timeStr}
                    </Typography>
                  </div>
                ))}
              {getMinerInternetfail === true && (
                <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                  {minerInternetStatusMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStatusMinerInternet}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openEcoConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Eco Enhancement Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {ecoConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseEcoConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={statusEco}>
            <DialogTitle>
              <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
                Eco Enhancement Status
              </Typography>
            </DialogTitle>
            <DialogContent>
              {getEcofail === false && Object.keys(ecoJobMessage).length > 0 && (
                <>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Serial No:</b> {ecoJobMessage.SerialNo}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Status:</b> {ecoJobMessage.Status}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Message:</b> {ecoJobMessage.Msg}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Updated At:</b>{" "}
                    {displayTS(ecoJobMessage.updatedAt).timeStr}
                  </Typography>
                </>
              )}
              {getEcofail === false &&
                ecoMultipleJobMessage.length > 0 &&
                ecoMultipleJobMessage.map((item, index) => (
                  <div key={index}>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {item.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {item.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {item.Msg}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "smaller", mb: 3.5 }}
                    >
                      <b>Updated At:</b> {displayTS(item.updatedAt).timeStr}
                    </Typography>
                  </div>
                ))}
              {getEcofail === true && (
                <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                  {ecoStatusMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStatusEco}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openAlertConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Alert Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {alertConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAlertConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openMigrateMinersConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Migrate Miners Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {migrateMinersConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMigrateMinersConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={manage}>
            <DialogTitle>
              <Typography variant="h5" sx={{ mt: 3 }}>
                Manage Miner Groups
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TabContext value={managevalue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleValueChange1} centered>
                    <Tab
                      label="Create Group"
                      value="0"
                      style={{ fontSize: "13px" }}
                    />
                    <Tab
                      label="Delete Group"
                      value="1"
                      style={{ fontSize: "13px" }}
                    />
                  </TabList>
                </Box>

                <TabPanel value="0">
                  <Typography sx={{ mr: 6, mt: 4, mb: 4 }}>
                    New Miner Group
                  </Typography>
                  <TextField
                    variant="standard"
                    placeholder="Enter group name"
                    inputProps={{
                      sx: {
                        width: "400px",
                        fontSize: "13.5px",
                        "&::placeholder": {
                          color: "gray",
                          opacity: 0.75,
                          fontStyle: "italic",
                          fontSize: "12px",
                        },
                      },
                    }}
                    value={newGroup}
                    onChange={handleNewGroupInput}
                  />
                </TabPanel>
                <TabPanel value="1">
                  <Typography sx={{ mr: 6, mt: 4, mb: 4 }}>
                    Pick a Miner Group to Delete
                  </Typography>
                  <Autocomplete
                    multiple
                    id="checkboxes-tags-demo"
                    options={minerGroups}
                    value={deleteGroups || []}
                    onChange={(event, value) => {
                      handleSelectDeleteMiners(value);
                    }}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option}
                    getOptionDisabled={(option) => option }
                    renderGroup={(props, option, { selected }) => (
                      <li
                        {...props}
                        style={{
                          whiteSpace: "normal",
                          fontSize: "14px",
                        }}
                      >
                        <Checkbox
                          style={{ marginRight: 8, transform: "scale(0.8)" }}
                          checked={selected}
                        />
                        {option}
                      </li>
                    )}
                    style={{ width: 400 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        aria-label="Select mode"
                        inputProps={{
                          ...params.inputProps,
                          style: {
                            fontSize: "11px",
                            fontStyle: "italic",
                          },
                        }}
                        placeholder="Select a group from the list"
                      />
                    )}
                  />
                </TabPanel>
              </TabContext>
            </DialogContent>
            <DialogActions>
              {managevalue === "0" && (
                <>
                  <Button onClick={handleClearCreate} sx={{ m: 2 }}>
                    Clear
                  </Button>
                  <Button onClick={handleCreateCreate} sx={{ m: 2 }}>
                    Create
                  </Button>
                </>
              )}
              {managevalue === "1" && (
                <>
                  <Button onClick={handleClearDelete} sx={{ m: 2 }}>
                    Clear
                  </Button>
                  <Button onClick={handleDeleteDelete} sx={{ m: 2 }}>
                    Delete
                  </Button>
                </>
              )}
              <Button onClick={handleCloseManage} sx={{ m: 2 }}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openCreateConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Create Group Result</Typography>
              </DialogTitle>
              <Typography
                variant="body2"
                sx={{ fontSize: "smaller", textAlign: "center" }}
              >
                {createConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCreateConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openDeleteConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">
                  Delete {deleteGroups > 1 ? "Groups" : "Group"} Result
                </Typography>
              </DialogTitle>
              <Typography
                variant="body2"
                sx={{ fontSize: "smaller", textAlign: "center" }}
              >
                {deleteConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDeleteConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={openMinerTuningConfirmation}>
            <DialogContent>
              <DialogTitle>
                <Typography variant="h6">Miner Tuning Result</Typography>
              </DialogTitle>
              <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                {minertuningConfirmationMessage}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseMinerTuningConfirmation}>OK</Button>
            </DialogActions>
          </Dialog>
          <Dialog open={statusMinerTuning}>
            <DialogTitle>
              <Typography variant="h6" sx={{ mt: 1, mb: 2 }}>
                Miner Tuning Status
              </Typography>
            </DialogTitle>
            <DialogContent>
              {getMinerTuningfail === false && Object.keys(minertuningJobMessage).length > 0 && (
                <>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Serial No:</b> {minertuningJobMessage.SerialNo}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Status:</b> {minertuningJobMessage.Status}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Message:</b> {minertuningJobMessage.Msg}
                  </Typography>
                  <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                    <b>Updated At:</b>{" "}
                    {displayTS(minertuningJobMessage.updatedAt).timeStr}
                  </Typography>
                </>
              )}
              {getMinerTuningfail === false &&
                minertuningMultipleJobMessage.length > 0 &&
                minertuningMultipleJobMessage.map((item, index) => (
                  <div key={index}>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Serial No:</b> {item.SerialNo}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Status:</b> {item.Status}
                    </Typography>
                    <Typography variant="body1" sx={{ fontSize: "smaller" }}>
                      <b>Message:</b> {item.Msg}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{ fontSize: "smaller", mb: 3.5 }}
                    >
                      <b>Updated At:</b> {displayTS(item.updatedAt).timeStr}
                    </Typography>
                  </div>
                ))}
              {getMinerTuningfail === true && (
                <Typography variant="body2" sx={{ fontSize: "smaller" }}>
                  {minertuningStatusMessage}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseStatusMinerTuning}>OK</Button>
            </DialogActions>
          </Dialog>
        </Paper>
        <br />
        {techSupport === true && displaytable !== null && (
          <DataGrid
            autoHeight
            getRowId={(row) => row.serial}
            rows={rows}
            columns={TechSupportcolumns}
            getRowHeight={() => "auto"}
            getEstimatedRowHeight={() => 60}
            pagination
            pageSizeOptions={[25, 50]}
            rowCount={count}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
              if (!loading) {
                setPaginationModel(newModel);
              }
            }}
            paginationMode="server"
            onRowSelectionModelChange={(newSelectionModel) => {
              setStopRefresh(true);
              const selectedIds = new Set(newSelectionModel);
              const notSelectedRows = rows.filter(row => !selectedIds.has(row.serial));
              const localNotSelectedRows = notSelectedRows.map((row) => row.serial);
              globalSelectionModel = globalSelectionModel.filter(id => id !== undefined && id !== null);
              globalNotSelectionModel = [...new Set([...globalNotSelectionModel, ...localNotSelectedRows])];
              globalSelectionModel = [...new Set([...globalSelectionModel, ...newSelectionModel])];
              globalSelectionModel = globalSelectionModel.filter(id => !notSelectedRows.some(row => row.serial === id));
              setSelectionModel(newSelectionModel);
              setSelectAllMiners(false);
            }}
            rowSelectionModel={selectionModel}
            loading={loading}
            disableRowSelectionOnClick
            checkboxSelection={isminerGroupSelected}
            keepNonExistentRowsSelected
            sx={{
              mr: 5,
              ml: 2,
              mt: 2,
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "6px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "10px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },'& .MuiDataGrid-cellCheckbox': {
                position: 'relative',
                zIndex:9999,
                overflow: 'visible',
              },
              '& .MuiDataGrid-cellCheckbox .MuiCheckbox-root': {
                position: 'absolute',
                top: '21px',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                margin: 0,
              },

              '& .MuiDataGrid-footerCheckbox .MuiCheckbox-root': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                margin: 0,
              },
            }}
            onColumnWidthChange={handleColumnWidthChangeCS}
            onColumnResize={(params) => handleColumnWidthChangeCS(params)}
            columnVisibilityModel={colVisibilityModelCS}
            onColumnVisibilityModelChange={(newModel) =>
              handleColumnVisibilityChangeCS(newModel)
            }
            sortModel={sortModelCS}
            onSortModelChange={handleSortModelChangeCS}
            filterModel={filterModelCS}
            onFilterModelChange={handleFilterModelChangeCS}
            key={TechSupportcolumns.map((col) => col.field).join("-")}
          />
        )}
        {techSupport === false && displaytable !== null && (
          <DataGrid
            autoHeight
            getRowId={(row) => row.serial}
            rows={rows}
            columns={columns}
            getRowHeight={() => "auto"}
            getEstimatedRowHeight={() => 60}
            pagination
            pageSizeOptions={[25, 50]}
            rowCount={count}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
              if (!loading) {
                setPaginationModel(newModel);
              } else {
                console.log("Loading")
              }
            }}
            paginationMode="server"
            onRowSelectionModelChange={(newSelectionModel) => {
              setStopRefresh(true);
              const selectedIds = new Set(newSelectionModel);
              const notSelectedRows = rows.filter(row => !selectedIds.has(row.serial));
              const localNotSelectedRows = notSelectedRows.map((row) => row.serial);
              globalSelectionModel = globalSelectionModel.filter(id => id !== undefined && id !== null);
              globalNotSelectionModel = [...new Set([...globalNotSelectionModel, ...localNotSelectedRows])];
              globalSelectionModel = [...new Set([...globalSelectionModel, ...newSelectionModel])];
              globalSelectionModel = globalSelectionModel.filter(id => !notSelectedRows.some(row => row.serial === id));
              setSelectionModel(newSelectionModel);
              setSelectAllMiners(false);
            }}
            rowSelectionModel={selectionModel}
            loading={loading}
            disableRowSelectionOnClick
            checkboxSelection={isminerGroupSelected}
            keepNonExistentRowsSelected
            sx={{
              mr: 5,
              ml: 2,
              mt: 2,
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "6px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "10px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "22px",
              },
              '& .MuiDataGrid-cellCheckbox': {
                position: 'relative',
                zIndex:9999,
                overflow: 'visible',
              },
              '& .MuiDataGrid-cellCheckbox .MuiCheckbox-root': {
                position: 'absolute',
                top: '21px',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                margin: 0,
              },

              '& .MuiDataGrid-footerCheckbox .MuiCheckbox-root': {
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                margin: 0,
              },
            }}
            onColumnWidthChange={handleColumnWidthChange}
            onColumnResize={(params) => handleColumnWidthChange(params)}
            columnVisibilityModel={colVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              handleColumnVisibilityChange(newModel)
            }
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            filterModel={filterModel}
            onFilterModelChange={handleFilterModelChange}
            key={columns.map((col) => col.field).join("-")}
          />
        )}
        <Footer />
      </Box>
      </PageContainer>
    );
  } else if (validUser !== -1 && validUser !== 0) {
    return (
      <PageContainer title="Miner" description="this is the miner operations/status page">
      <div>
        <SuccessModal
          isOpen={successModalOpen}
          onClose={handleCloseSuccessModal}
          Status={submitMsg}
        />
      </div>
      </PageContainer>
    );
  } else {
    return <PageContainer title="Miner" description="this is the miner operations/status page"><PageLoading /></PageContainer>;
  }
}