import React from "react";
import { withAuthenticationRequired, useAuth0 } from "@auth0/auth0-react";
import Loading from "../components/loading";
import Container from "@mui/material/Container";
import { Typography, IconButton, Tooltip } from "@mui/material";
import PrivacyDialog from "./privacy-dialog";
import BottomNavigation from "@mui/material/BottomNavigation";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const Footer = ({ sub, bgColor }) => {
  // console.log("Footer.js: sub: ", bgColor);
  async function downloadPdf(pdfName) {
    try {
      let response = {};
      if (sub === true) {
        response = await fetch(`../../${pdfName}`);
      } else {
        response = await fetch(`./${pdfName}`);
      }
      const blob = await response.blob();

      // Create a link element
      const link = document.createElement("a");

      // Set the link's href to the Blob URL
      link.href = window.URL.createObjectURL(blob);

      // Set the download attribute with the desired file name
      link.download = pdfName;

      // Append the link to the document
      document.body.appendChild(link);

      // Trigger a click on the link to start the download
      link.click();

      // Remove the link from the document
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  }

  const handleDownloadClick = () => {
    // Replace these URLs with the actual URLs of your PDFs

    const pdfNames = [
      "Teraflux API Reference.pdf",
      "Teraflux Miner Hardware Reference.pdf",
      "Teraflux Miner Quick Start & Web Interface Reference.pdf",
    ];

    // Download each PDF
    pdfNames.forEach((name) => {
      downloadPdf(name);
    });
  };

  return (
    <Container
      sx={{
        marginY: 5,
        minHeight: "100%",
      }}
    >
      <BottomNavigation
        sx={{
          width: 1000,
          ml: -1,
          // backgroundColor: "#f9f9f9",
          backgroundColor: bgColor || "white",
        }}
      >
        <Typography variant="body2" paddingY={2} sx={{ flexGrow: 1 }}>
          © 2024 Auradine Inc. All Rights Reserved.
        </Typography>
        {sub === true ? (
          <PrivacyDialog
            name="privacy-2022-08"
            buttonTitle="Privacy Policy"
            title="Privacy Policy"
            page="../../privacy.html"
          />
        ) : (
          <PrivacyDialog
            name="privacy-2022-08"
            buttonTitle="Privacy Policy"
            title="Privacy Policy"
            page="./privacy.html"
          />
        )}
        <div style={{ width: 10 }}></div>
        {sub === true ? (
          <PrivacyDialog
            name="terms-2022-08"
            buttonTitle="Terms of Service"
            title="Terms of Service"
            page="../../terms.html"
          />
        ) : (
          <PrivacyDialog
            name="terms-2022-08"
            buttonTitle="Terms of Service"
            title="Terms of Service"
            page="./terms.html"
          />
        )}
        <div style={{ width: 10 }}></div>
        <Tooltip
          title={`Download Miner API, Hardware, and Web Interface Reference Guides by Clicking Here`}
          arrow
        >
          <IconButton
            onClick={handleDownloadClick}
            variant="text"
            sx={{
              mt: "-15px",
              ml: "5px",
              fontFamily: "Roboto",
              fontSize: "0.8125rem",
              fontWeight: 500,
              color: "#1976d2",
            }}
          >
            Documentation
            <FileDownloadOutlinedIcon />
          </IconButton>
        </Tooltip>
      </BottomNavigation>
    </Container>
  );
};

export default withAuthenticationRequired(Footer, {
  onRedirecting: () => <Loading />,
});
